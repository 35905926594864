import React, { useState } from 'react';
import styles from './styles';
import "date-fns";
// Components
import Modal from '../generics/Modal';
// Material ui
import { makeStyles } from '@material-ui/core/styles';
import Button from '../generics/Button';
import {
	DatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import "moment/locale/es";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles(styles);


const DateModal = props => {
	const classes = useStyles();
	const { handleClose, handleSelect } = props;
	const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format("MM/DD/YYYY"));
	const [startDateOpen, setStartDateOpen] = useState(false);
	const [endDateOpen, setEndDateOpen] = useState(false);
	const [endDate, setEndDate] = useState(moment().format("MM/DD/YYYY"));

	const handleChangeStartDatePicker = value => {
		setStartDate(value.format("MM/DD/YYYY"));
		setStartDateOpen(false);
	}

	const handleChangeEndDatePicker = value => {
		setEndDate(value.add(1, 'days').format("MM/DD/YYYY"));
		setEndDateOpen(false);
	}

	return (
		<Modal
			title={"Elegir fechas"}
			isOpen={true}
			handleClose={handleClose}
			mainAction={() => {
				handleSelect('personalized', startDate, endDate);
				handleClose();
			}}
		>
			<div className={classes.modalContainer}>
				<MuiPickersUtilsProvider locale="es" libInstance={moment} utils={MomentUtils}>
					<DatePicker
						className={classes.startDatePicker}
						disableToolbar
						variant="inline"
						format="DD/MM/YYYY"
						margin="normal"
						id="date-picker-inline"
						open={startDateOpen}
						onOpen={() => setStartDateOpen(true)}
						label="Desde"
						value={startDate}
						onChange={handleChangeStartDatePicker}
						onClose={() => setStartDateOpen(false)}
						maxDate={endDate}
					/>
					<div>
					<DatePicker
						className={classes.startDatePicker}
						disableToolbar
						variant="inline"
						format="DD/MM/YYYY"
						margin="normal"
						id="date-picker-inline"
						open={endDateOpen}
						onOpen={() => setEndDateOpen(true)}
						label="Hasta"
						value={endDate}
						onChange={handleChangeEndDatePicker}
						onClose={() => setEndDateOpen(false)}
						minDate={startDate}
						// maxDate={new Date()}
					/>
					</div>
				</MuiPickersUtilsProvider>
				<div className={classes.modalActions}>
					<Button
						style={{ float: 'right' }}
						onClick={() => {
							handleSelect('personalized', startDate, endDate);
							handleClose();
						}}
					>
						Aplicar
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default DateModal;