import { useEffect } from 'react';
import { getCookie } from '../commons/CookiesHelper';
import { useHistory } from 'react-router-dom';


const usePaywall = (props) => {
    const { userInfo, location } = props;
    const history = useHistory();

    useEffect(() => {
        const whitelist = ['/paywall', '/members', '/plans', '/billing'];
        const isPaywallPage = whitelist.includes(location);
        const currentOrg = userInfo && userInfo.organizations?.find(org => org.id.toString() === getCookie('org'));
        const billStatus = currentOrg && currentOrg.billStatus;

        if((billStatus && !billStatus?.upToDate) && !isPaywallPage) {
            history.push('/paywall');
        }

    }, [userInfo]);

    return [];
};

export default usePaywall;
