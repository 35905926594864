import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { setCookie } from '../../commons/CookiesHelper';
import styles from './styles';
import { faTh, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const useStyles = makeStyles(styles);


const LayoutToggleButton = props => {
    const {
        setSelectedLayout,
        selectedLayout,
        setTooltip,
        setIsOpen
    } = props;

    const classes = useStyles();

    const handleToggle = layout => {
        setTooltip(null);
        setSelectedLayout(layout);
        setCookie('expensesLayout', layout, 30 * 24);
        setTimeout(() => setIsOpen(false));
    }

    return (
        <div className='layoutTooltip'>
            <label>Ver como:</label>
            <div className={selectedLayout === 'list' ? 'layoutTooltipSelected' : null} onClick={() => handleToggle('list')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2a2651" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="9" y1="6" x2="20" y2="6" />
                    <line x1="9" y1="12" x2="20" y2="12" />
                    <line x1="9" y1="18" x2="20" y2="18" />
                    <line x1="5" y1="6" x2="5" y2="6.01" />
                    <line x1="5" y1="12" x2="5" y2="12.01" />
                    <line x1="5" y1="18" x2="5" y2="18.01" />
                </svg>
                <p>Lista</p>
            </div>
            <div className={selectedLayout === 'grid' ? 'layoutTooltipSelected' : null}  onClick={() => handleToggle('grid')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2a2651" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="4" y="4" width="16" height="16" rx="2" />
                    <line x1="12" y1="4" x2="12" y2="20" />
                </svg>
                <p>Tarjetas</p>
            </div>
        </div>
    );
}

export default LayoutToggleButton;