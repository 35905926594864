import React, { useState, useRef, useEffect } from 'react';
import styles from '../expensesList/styles';
//Material ui
import { makeStyles } from '@material-ui/core/styles';
//Icons
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const useStyles = makeStyles(styles)


const Filters = props => {
    const {
        isSearching,
        setIsSearching,
        search,
        setSearch,
        children
    } = props;
    const classes = useStyles();
    const inputRef = useRef(null);

    const handleInputChange = value => {
        setSearch(value);
    }

    useEffect(() => {
        if(isSearching) {
            inputRef.current.focus();
        }
    }, [isSearching]);

    return (
        <div className={classes.filtersDiv}>
            {
                isSearching ?
                    <div className={classes.searchBar}>
                        <FontAwesomeIcon icon={faSearch} className={classes.searchIcon} />
                        <input
                            className={classes.searchInput}
                            value={search}
                            onChange={e => handleInputChange(e.currentTarget.value)}
                            placeholder="Buscar por nombre...."
                            ref={inputRef}
                        />
                        <span className={classes.closeIcon} onClick={() => { setIsSearching(false); }}>X</span>
                    </div> :
                    <>
                        <div className={classes.searchButton} onClick={() => { setIsSearching(true); }}>
                            <FontAwesomeIcon icon={faSearch} className={classes.searchIcon} />
                            Buscar grupo...
                        </div>
                        <div className={classes.actionsDiv}>
                            {children}
                        </div>
                    </>
            }
        </div>
    );
}

export default Filters;
