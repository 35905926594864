import React, { useRef, useEffect } from 'react';

// Styles
import styles from './createExpense.module.scss';

// Utils
import { formatInputCurrency, maskMoney } from "../../commons/Utils";


const TextInput = (props) => {
    const inputRef = useRef(null);
    const {
        value,
        visible,
        onChange,
        isCurrency,
        placeholder
    } = props;

    useEffect(() => {
        if(visible) inputRef.current.focus();
    }, [placeholder, visible]);

    const getValue = () => {
        if (isCurrency)
            return formatInputCurrency(value);
        return value;
    }

    const handleAmountChange = amount => {
        const value = amount?.target?.value;
        if (!isCurrency)
            return onChange(value);
        onChange(maskMoney(value));
    }

    return <>
        {
            visible ?
                <>
                    <input
                        placeholder={placeholder}
                        className={visible && !isCurrency ? styles.textInput : styles.hiddenTextInput}
                        value={value}
                        onChange={handleAmountChange}
                        ref={inputRef}
                    />
                    <p
                        className={isCurrency ? styles.numberInput : styles.hiddenNumberInput}
                        onClick={() => inputRef.current.focus()}
                    >
                        {getValue(value)}
                    </p>
                </>
                : null
        }
    </>
}

export default TextInput;