import React, { useState } from 'react';

//Material UI
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// Utils
import { createOrganization } from "../../commons/Services";
import { useHistory } from "react-router-dom";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


const useStyles = makeStyles({
    app: {
        height: '90vh',
        width: '100vw',
        overflow: 'hidden',
        margin: 0
    },
    root: {
        width: 400,
        margin: 'auto',
        maxWidth: '90%',
    },
    headline: {
        color: '#252793',
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 500,
        marginTop: '15vh',
        '@media (max-width: 800px)': {
            marginTop: 50,
            marginBottom: 50
        }
    },
    options: {
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.02), 0 5px 42px -8px rgba(0, 0, 0, 0.15)',
        border: 'none',
        borderRadius: 8,
        padding: 20,
        paddingTop: 20,
        marginTop: '6vh',
        animationName: '$fadein',
        animationDuration: '.5s'
    },
    '@keyframes fadein': {
        from: {
            opacity: 0,
            transform: 'translateX(-50px)'
        },
        to: {
            opacity: 1,
            transform: 'translateX(0px)'
        }
    },
    arrowBack: {
        position: 'absolute',
        top: '14vh',
        color: 'lightgray',
        cursor: 'pointer',
        padding: 12
    }
});


const CreateOrganization = (props) => {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { handleBack } = props;

    const createOrganizationRequest = async name => {
        if (name.length < 3) {
            return;
        }
        setLoading(true);
        const res = await createOrganization(name);
        history.push('/dashboard');
    }

    return (
        <div className={classes.app}>
            <div className="onboardingContainer">
                <div className={classes.root}>
                    <p className={classes.headline}>Crear una organización</p>
                    <FontAwesomeIcon
                        onClick={handleBack}
                        className={classes.arrowBack}
                        icon={faArrowLeft}
                    />
                    <div className={classes.options}>
                        <h3 id="loginHeadline">Ingresa el nombre</h3>
                        <TextField
                            className="loginInput"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            label="Nombre"
                            variant="outlined"
                            type="text"
                        />
                        {
                            loading ?
                                <Button
                                    className="disabledLoginButton"
                                    variant="contained"
                                    color="primary"
                                >
                                    Unos segundos...
                                </Button> :
                                <Button
                                    className="loginButton"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => createOrganizationRequest(name, history)}
                                >
                                    Crear organización
                                </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateOrganization;