import React, { useState, useEffect, useRef } from 'react';
import styles from './selectorModal.module.scss';


const SelectorModal = props => {
    const [search, setSearch] = useState("");
    const [selectedOption, setSelectedOption] = useState({ name: "General" });
    const [isUnmounted, setIsUnmounted] = useState(false);
    const ref = useRef(null);
    const inputRef = useRef(null);
    const {
        placeholder,
        canCreate = false,
        createCallback,
        handleSelect,
        handleClose,
        isLoading,
        options
    } = props;

    useEffect(() => {
        inputRef.current.focus();
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keyup', handleKeyUp);
        return () => document.removeEventListener('keyup', handleKeyUp);
    }, [selectedOption]);

    useEffect(() => {
        if(canCreate && search !== "") {
            setSelectedOption({ name: search });
            return;
        }
        setSelectedOption(options.filter(compareSearch)[0]);
    }, [options, search]);

    const selectOption = () => {
        handleSelect(selectedOption);
        closeModal();
    }

    const selectAndCreateOption = () => {
        if(canCreate && createCallback) {
            createCallback(selectedOption);
        }
        handleSelect(selectedOption);
        closeModal();
    }

    const handleKeyUp = (e) => {
        if(e.key === 'Enter') {
            if(!selectedOption) return;
            selectOption();
        }
    }

    const handleClickOutside = (event) => {
        if(ref.current && !ref.current.contains(event.target)) {
            setTimeout(() => {
                closeModal();
            })
        }
    };

    const closeModal = () => {
        setIsUnmounted(true);
        setTimeout(() => {
            handleClose();
        }, 300);
    }

    const compareSearch = elem => {
        const re = new RegExp(search.toLowerCase(), 'g');
        if(elem.name.toLowerCase().match(re)) {
            return true;
        }
    }

    const doesNotExist = () => {
        return !options.find(elem => elem.name === search);
    }

    const setSearchClean = str => {
        setSearch(str.replace(/\\/g, ""));
    }

    const filteredOptions =
        search !== "" ?
            options.filter(compareSearch) :
            options;

    return (
        <div className={isUnmounted ? styles.unmountedBackdrop : styles.backdrop}>
            <div
                className={isUnmounted ? styles.unmountedModalContainer : styles.modalContainer}
                ref={ref}
            >
                <input
                    className={styles.searchInput}
                    placeholder={placeholder}
                    ref={inputRef}
                    value={search}
                    onChange={(e) => setSearchClean(e.target.value)}
                />
                {
                    isLoading &&
                    <>
                        <div key={0} className={styles.contentPlaceholder}></div>
                        <div key={1} className={styles.contentPlaceholder}></div>
                        <div key={2} className={styles.contentPlaceholder}></div>
                    </>
                }
                {
                    !isLoading &&
                    <div className={styles.optionsList}>
                        {
                            canCreate && search !== "" && doesNotExist(search) &&
                            <div
                                key={-1}
                                onClick={selectAndCreateOption}
                                className={
                                    search === selectedOption?.name ?
                                        styles.selectedOption :
                                        styles.option
                                }
                                onMouseEnter={() => setSelectedOption({ name: search })}
                            >
                                Crear: '{search}"
                            </div>
                        }
                        {
                            filteredOptions.map((elem, idx) => {
                                return <div
                                    key={idx}
                                    onClick={selectOption}
                                    className={
                                        elem.name === selectedOption?.name ?
                                            styles.selectedOption :
                                            styles.option
                                    }
                                    onMouseEnter={() => setSelectedOption(elem)}
                                >
                                    {elem.name}
                                </div>;
                            })
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default SelectorModal;