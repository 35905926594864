import React, { useState } from 'react';

//Components
import Modal from '../generics/Dialog';
import Button from '../generics/Button';


// Services
import { sendFeedback } from '../../commons/Services';
import FileSelector from './FileSelector';

const FeedbackModal = ({ open, setOpen, showSnackbar }) => {
    const defaultTitle = 'Ayuda y feedback';
    const [title, setTitle] = useState(defaultTitle);
    const [text, setText] = useState('');
    const [image, setImage] = useState(null);
    const guidesUrl = 'https://pacific-engine-ddc.notion.site/Expendr-guias-04ccfc2adaf24dc295a1dee35736a8c5';

    const sendFeedbackAndClose = () => {
        const type = title === 'Contactarnos' ? 'help' : 'feedback';
        sendFeedback(title, text, image, type);
        closeModal();
        showSnackbar();
    };

    const closeModal = () => {
        setOpen(false);
        setTitle(defaultTitle);
        setText('');
        setImage(null);
    };

    return (
        <Modal
            isOpen={open}
            handleClose={closeModal}
            title={title}
        >
            <div className="feedbackDialogContent">
                {
                    title === defaultTitle &&
                    <>
                        <div className="feedbackOption" onClick={() => setTitle('Reportar un error')}>
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-circle" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx="12" cy="12" r="9" />
                                <line x1="12" y1="8" x2="12" y2="12" />
                                <line x1="12" y1="16" x2="12.01" y2="16" />
                            </svg>
                            Reportar un error
                        </div>
                        <div className="feedbackOption" onClick={() => window.open(guidesUrl, '_blank')}>
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lifebuoy" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                                <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                                <line x1="3" y1="6" x2="3" y2="19" />
                                <line x1="12" y1="6" x2="12" y2="19" />
                                <line x1="21" y1="6" x2="21" y2="19" />
                            </svg>
                            Guías de uso
                        </div>
                        <div className="feedbackOption" onClick={() => setTitle('Contactarnos')}>
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lifebuoy" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx="12" cy="12" r="4" />
                                <circle cx="12" cy="12" r="9" />
                                <line x1="15" y1="15" x2="18.35" y2="18.35" />
                                <line x1="9" y1="15" x2="5.65" y2="18.35" />
                                <line x1="5.65" y1="5.65" x2="9" y2="9" />
                                <line x1="18.35" y1="5.65" x2="15" y2="9" />
                            </svg>
                            Contactarnos
                        </div>
                    </>
                }
                {
                    title != defaultTitle &&
                    <>
                        <textarea
                            value={text}
                            onChange={e => setText(e.target.value)}
                            placeholder="Mi problema es..."
                            rows="6"
                            cols="40"
                        >
                        </textarea>
                        <FileSelector
                            image={image}
                            setImage={setImage}
                        />
                        <div className="feedbackDialogButton">
                            <Button disabled={text === ''} onClick={sendFeedbackAndClose}>
                                Enviar
                            </Button>
                        </div>
                    </>
                }
            </div>

        </Modal>
    );
};

export default FeedbackModal;