import React, { useState, useContext, useRef, useEffect } from 'react';
import styles from './activity.module.scss';

// Components
import Avatar from "../_shared/Avatar";
import Comment from './Comment';

// Utils
import { formatCurrency } from '../../commons/Utils';
import Editor from "./Editor";

// State
import { UserContext } from '../../commons/UserContext';

// Utils
import { getStringFromDate } from '../../commons/Utils';

const getActivityText = (activity) => {
    if (activity.type === 'create') return " creó el gasto.";
    if (activity.type === 'edit') {
        if (activity.body.field === 'state') {
            if (activity.body.newValue === 'approved') return " aprobó el gasto";
            if (activity.body.newValue === 'disapproved') return " desaprobó el gasto";
            if (activity.body.newValue === 'pending') return " volvió el estado a";
            if (activity.body.newValue === 'reimbursed') return " reintegró el gasto";
        }
        if (activity.body.field === 'amountState') {
            if (activity.body.newValue === 'reimbursed') return " reintegró el gasto";
            if (activity.body.newValue === 'pending') return " canceló el reintegro";
        }
        if (activity.body.field === 'category')
            return " cambió la categoría a ";
        if (activity.body.field === 'date')
            return " editó la fecha";
        if (activity.body.field === 'description')
            return " editó la descripción";
        if (activity.body.field === 'amount')
            return " cambió el monto a ";
        if (activity.body.field === 'attatchments') return;
        return " editó el gasto";
    }
}

const getActivityNewValue = (activity) => {
    const optionals = ['category'];
    if (activity.type === 'edit') {
        if (activity.body.field === 'state') {
            if (activity.body.newValue === 'pending') return " pendiente.";
        }
        if (optionals.includes(activity.body.field)) {
            return activity.body.newValue;
        }
        if (activity.body.field === 'amount') {
            return formatCurrency(activity.body.newValue);
        }
    }
}


const Activity = (props) => {
    const { activities, expenseId } = props;
    const { userInfo } = useContext(UserContext);
    const [updatedActivities, setActivities] = useState(activities);

    useEffect(() => {
        setActivities(activities);
    }, [activities]);

    const addComment = (comment) => {
        const temp = [...updatedActivities];
        const newActivity = {
            user: userInfo,
            type: 'comment',
            date: new Date(),
            body: {
                commentText: comment
            }
        }
        temp.push(newActivity);
        setActivities(temp);
    }

    return <div className={styles.activityContainer}>
        <h2 className={styles.activityTitle}>Actividad</h2>
        <div className={styles.actions}>
            {
                updatedActivities.map((activity, idx) => {
                    const txt = activity?.body?.commentText
                    return (
                        <div key={idx} className={styles.actionContainer}>
                            <div
                                key={idx}
                                className={styles.avatarContainer}
                            >
                                <div className={styles.avatar}>
                                    <Avatar
                                        userInfo={activity.user}
                                        customClass={styles.commentAvatarActivity}
                                    />
                                </div>
                                <span className={styles.line} />
                            </div>
                            <b>{activity.user.fullName}</b>
                            {getActivityText(activity)}
                            <b>{getActivityNewValue(activity)}</b>
                            <p className={styles.activityDate}>
                                {getStringFromDate(activity.date)}
                            </p>
                            {
                                activity.type === 'comment' &&
                                <Comment comment={activity} />
                            }
                        </div>
                    )
                })
            }
            <div className={styles.commentContainer}>
                <div className={styles.avatarContainer}>
                    <div className={styles.commentAvatar}>
                        <Avatar
                            userInfo={userInfo}
                            customClass={styles.commentAvatarContent}
                        />
                    </div>
                </div>
                <div className={styles.comment}>
                    <Editor expenseId={expenseId} addComment={addComment} />
                </div>
            </div>
        </div>
    </div>
}

export default Activity;