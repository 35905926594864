import React, { useContext, useEffect, useState } from 'react';

// Components
import Layout from '../_shared/Layout';
import TextInput from './TextInput';
import ButtonsInput from './ButtonsInput';
import KeyboardHelper from './KeyboadHelper';
import DateModal from './DateModal';
import CategoryModal from './CategoryModal';
import ImageViwer from '../expenseDetail/ImageViewer';
import DataItems from './DataItems';
import Snackbar from '../generics/Snackbar';
import CustomFieldModal from '../expenseDetail/CustomFieldModal';
import SelectorModal from '../generics/SelectorModal';

// State
import { UserContext } from '../../commons/UserContext';

// Utils
import useModalState from './customHooks/useModalState';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const mapTypes = {
	number: 'customNumber',
	select: 'customSelect',
	currency: 'customCurrency',
	text: 'customText'
};

const CreateExpense = () => {
	const [modals, toggleModal, toggleCFModal] = useModalState();
	const [isImageOpen, setIsImageOpen] = useState(false);
	const { expenseState, expenseReducer } = useContext(UserContext);
	const {
		step,
		value,
		values,
		isLoading,
		snackbar,
		placeholder,
		breadcrumbs
	} = expenseState;
	const { changeState, setInputValue, setCFValue, fetchFields } = expenseReducer;
	const { image, date } = values;
	const { isDateModalOpen, isCategoryModalOpen, customFieldModal } = modals;

	const saveCustomFieldValue = (_name, value, _customFieldId, id = null) => {
		setCFValue(id, value);
		toggleCFModal(null);
	};

	useEffect(() => {
		fetchFields();
	}, []);

	return (
		<Layout title="Crear nuevo gasto" breadcrumbs={breadcrumbs} isLoading={isLoading} >
			<TextInput
				values={values}
				isCurrency={step === 'amount'}
				value={value}
				visible={step === 'description' || step === 'amount'}
				placeholder={placeholder}
				onChange={e => changeState('value', e)}
			/>
			<ButtonsInput
				visible={step === 'isOwn'}
				value={value}
				setValue={val => changeState('value', val)}
			/>
			<DataItems
				values={values}
				setIsImageOpen={setIsImageOpen}
				toggleModal={toggleModal}
				toggleCFModal={toggleCFModal}
			/>
			<KeyboardHelper
				value={value}
				step={step}
			/>
			<DateModal
				handleClose={() => toggleModal('date')}
				handleSelect={val => setInputValue('date', val)}
				dateValue={date}
				open={isDateModalOpen}
			/>
			<CategoryModal
				handleClose={() => toggleModal('category')}
				handleSelect={val => setInputValue('category', val)}
				open={isCategoryModalOpen}
			/>
			<ImageViwer
				imageUrl={image}
				setIsImageOpen={setIsImageOpen}
				open={isImageOpen}
			/>
			<Snackbar
				visible={snackbar.visible}
				text={snackbar.text}
				icon={snackbar.success ? faCheckCircle : faTimesCircle}
				iconColor={snackbar.success ? 'rgba(0,250,200,0.9)' : 'red'}
			/>
			<CustomFieldModal
				handleClose={() => toggleCFModal(null)}
				changeAndSave={saveCustomFieldValue}
				open={customFieldModal && customFieldModal?.type !== 'select'}
				type={mapTypes[customFieldModal?.type]}
				title={customFieldModal?.title}
				customField={customFieldModal}
			/>
			{
				customFieldModal && customFieldModal?.type === 'select' &&
				<SelectorModal
					placeholder="Buscar opciones..."
					handleClose={() => toggleCFModal(null)}
					handleSelect={elem => saveCustomFieldValue('select', elem.value, null, customFieldModal.id)}
					isCustom={true}
					options={customFieldModal.selectValues?.map(elem => {
						return { name: elem.value, value: elem.id };
					})}
					isLoading={false}
				/>
			}
		</Layout>
	);
};

export default CreateExpense;