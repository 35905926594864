import React, { useState } from 'react';
import styles from './styles';
// Material ui
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Radio, FormControlLabel } from '@material-ui/core';


const useStyles = makeStyles(styles);

const PurpleRadio = withStyles({
    root: {
        color: '#5043dd',
        '&$checked': {
            color: '#5043dd',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);


const RoleOption = props => {
    const classes = useStyles();
    const { title, subtitle, value } = props;

    return (
        <div className={classes.roleOptionContainer}>
            <FormControlLabel 
                value={value} 
                className={classes.roleOption} 
                control={<PurpleRadio/>} 
            />
            <p className={classes.roleOptionTitle}>{title}</p>
            <p className={classes.roleOptionSubtitle}>{subtitle}</p>
        </div>
    );
}

export default RoleOption;