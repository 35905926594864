import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';

const BootstrapInput = withStyles((theme) => ({
    root: {
        marginRight: 0,
        width: 328,
        marginTop: 2,
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        border: 'none',
        fontSize: 14,
        padding: '12px 26px 10px 12px',
        fontFamily: 'Montserrat',
        '&:focus': {
            borderRadius: 4,
            outline: 'none',
            backgroundColor: 'white'
        },
    },
}))(InputBase);

const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontFamily: 'Montserrat',
        fontSize: 14,
        padding: 10
    },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        borderColor: 'white'
    },
    select: {
        borderColor: 'white',
        color: '#2a2651',
        fontWeight: '500',
        fontSize: '14px',
    },
    layoutButton: {
        height: 44,
        width: 328,
        borderRadius: 6,
        position: 'relative',
        fontSize: '14px',
        border: '1px solid rgba(0,0,0,0.1)',
        background: 'white',
        display: 'block',
        verticalAlign: 'middle',
        // marginRight: 20,
        whiteSpace: 'nowrap'
    },
}));

const VisibilityInput = props => {
    const [value, setValue] = useState('public');
    const classes = useStyles();
    const { setAccessType, accessType, setRoles, roles } = props;

    const handleChange = (event) => {
        if(event.target.value === 'public') {
            setAccessType(event.target.value);
            setRoles([]);
            return;
        }
        if(event.target.value === 'group') {
            setAccessType(event.target.value);
            setRoles([]);
            return;
        }
        setAccessType('role');
        setRoles([event.target.value]);
    };

    useEffect(() => {
        if(accessType === 'public' || accessType === 'group') {
            setValue(accessType);
        } else {
            setValue(roles ? roles[0] : 'public');
        }
    }, [accessType, roles, value]);

    return (
        <div className={classes.layoutButton}>
            <FormControl variant="outlined" className={classes.formControl}>
                <Select
                    value={value}
                    onChange={handleChange}
                    className={classes.select}
                    input={<BootstrapInput />}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                >
                    <StyledMenuItem value={'public'}>Todos los usuarios</StyledMenuItem>
                    <StyledMenuItem value={'admin'}>Sólo administradores</StyledMenuItem>
                    <StyledMenuItem value={'employee'}>Sólo usuarios comunes</StyledMenuItem>
                    <StyledMenuItem value={'group'}>Sólo a miembros de un grupo</StyledMenuItem>
                </Select>
            </FormControl>
        </div>
    );
};

export default VisibilityInput;
