import React, { useContext } from 'react';
import styles from './plans.module.scss';
import { UserContext } from '../../commons/UserContext';
import { getCookie } from '../../commons/CookiesHelper';
import { formatCurrency, getFullStringFromDate } from '../../commons/Utils';
import { useHistory } from 'react-router-dom';


//Components
import Layout from '../_shared/Layout';


const Billing = () => {
    const history = useHistory();
    const { userInfo, plans, billingInfo } = useContext(UserContext);

    const currentOrg = userInfo && userInfo.organizations?.find(org => org.id.toString() === getCookie('org'));
    const billStatus = currentOrg && currentOrg.billStatus;
    const plan = plans?.find(plan => plan.id === billStatus.planId);

    return (
        <Layout title={"Suscripción"}>
            <span className={styles.billingLabel}>Plan actual</span>
            <div className={styles.suscription}>
                <span className={styles.planName}>{plan?.name}</span>
                <span className={styles.planUsers}>{plan?.maxUsers + ' Usuarios'}</span>
                <span className={styles.planPrice}>{formatCurrency(plan?.price)}</span>
                <span className={styles.planFrecuency}>por mes</span>
                <span className={styles.planAction} onClick={() => history.push('/plans')}>{'Ver planes'}</span>
            </div>

            <span className={styles.billingLabel}>Método de pago</span>
            <div className={styles.suscription}>
                <span className={styles.creditCard}>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-credit-card" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="3" y="5" width="18" height="14" rx="3" />
                        <line x1="3" y1="10" x2="21" y2="10" />
                        <line x1="7" y1="15" x2="7.01" y2="15" />
                        <line x1="11" y1="15" x2="13" y2="15" />
                    </svg>
                </span>
                <span className={styles.cardNumber}>**** **** **** {billingInfo?.lastFourDigits}</span>
            </div>

            <span className={styles.billingLabel}>Próxima fecha de cobro</span>
            <span className={styles.nextBillingInfo}>
                {billingInfo?.nextBillingDate ? getFullStringFromDate(billingInfo?.nextBillingDate) : ''}
            </span>
        </Layout>
    );
};

export default Billing;