import React, { useState, useContext } from 'react';
import styles from './plans.module.scss';
import Button from '../generics/Button';

//Components
import PlansSelector from './PlansSelector';

//Utils
import { formatCurrency } from '../../commons/Utils';
import { getCookie } from '../../commons/CookiesHelper';
import { UserContext } from '../../commons/UserContext';


const Plans = (props) => {
    const { currentPlan, plans, index, createCheckout, mockedPlan } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [plan, setPlan] = useState({ ...currentPlan, index });
    const planJson = { ...mockedPlan, ...plan };
    const { userInfo } = useContext(UserContext);

    const currentOrg = userInfo && userInfo.organizations?.find(org => org.id.toString() === getCookie('org'));
    const billStatus = currentOrg && currentOrg.billStatus;
    const isUpToDate = billStatus?.upToDate && billStatus.planId > 1;

    const handleButtonClick = () => {
        setIsLoading(true);
        createCheckout(planJson.id);
    };

    return (
        <div className={index === 1 ? styles.standardPlan : styles.plan}>
            <h2 className={styles.name}>{planJson.name}</h2>
            <p className={styles.price}>
                {
                    planJson.price || planJson.price === 0 ? formatCurrency(planJson.price) : ''
                }
                <span>por mes</span>
            </p>
            <div className={styles.separator}></div>
            {
                index === 2 && plans.length > 3 ?
                    <PlansSelector
                        plan={plan}
                        plans={plans}
                        index={planJson?.index}
                        setPlan={setPlan}
                    /> :
                    <p className={styles.infoText}>{planJson.maxUsers} Usuarios</p>
            }
            <p className={styles.infoText}>{planJson.expenses}</p>
            <p className={styles.infoText}>{planJson.storage}</p>
            <p className={styles.infoText}>{planJson.support}</p>
            {
                !isUpToDate &&
                <div className={styles.button}>
                    {
                        !planJson.isCurrent ?
                            <Button disabled={isLoading} onClick={handleButtonClick}>Mejorar plan</Button> :
                            <div className={styles.currentPlan}>Plan actual</div>
                    }
                </div>
            }
        </div>
    );
};

export default Plans;