export default {
	card: {
		background: 'white',
		borderRadius: 5,
		height: 25,
		display: 'inline-block',
		padding: 20,
		paddingTop: 12,
		paddingBottom: 12,
	},
	linkUser: {
		color: 'gray',
		fontSize: 18,
		fontWeight: 600,
		cursor: 'pointer',
		'&:hover': {
			cursor: 'ponter !important',
			color: '#910091ff',
			textDecoration: 'underline'
		}
	},
	modalLabel: {
		paddingLeft: 28,
		fontSize: 14,
		margin: '20px 0px',
		color: 'gray'
	},
	roleOption: {
		padding: 18,
		margin: 0,
		cursor: 'pointer',
		width: 'calc(100% - 36px)',
		'&:hover': {
			background: 'rgba(0,0,0,0.03)'
		}
	},
	roleOptionContainer: {
		position: 'relative'
	},
	roleOptionTitle: {
		position: 'absolute',
		background: 'transparent',
		pointerEvents: 'none',
		fontWeight: 600,
		fontSize: 13,
		left: 70,
		top: 8
	},
	roleOptionSubtitle: {
		position: 'absolute',
		background: 'transparent',
		pointerEvents: 'none',
		fontSize: 13,
		left: 70,
		top: 28
	},
	mainButton: {
		float: 'right !important',
		marginLeft: 100
	},	
	modalActions: {
		position: 'absolute',
		bottom: 0,
		right: 8,
		padding: 28
	},
	loadingText: {
		marginTop: 160,
		color: 'gray',
		width: '100%',
		textAlign: 'center'
	},
	readyText: {
		marginTop: 30,
		color: 'gray',
		fontSize: 13,
		width: '100%',
		textAlign: 'center'
	},
	invitationCode: {
		marginTop: 80,
		color: '#5043dd',
		margin: 'auto',
		borderRadius: 4,
		width: 220,
		padding: 20,
		textAlign: 'center',
		fontSize: 40,
		fontWeight: 200,
		letterSpacing: 6,
		background: 'rgba(0,0,0,0.03)',
		textTransform: 'Uppercase',
		userSelect: 'none'
	},
	copiedCodeFeedback: {
		textAlign: 'center',
		marginTop: 40,
		color: 'green'
	},
	copyButton: {
		margin: 'auto !important',
		maxWidth: 156,
		padding: 20
	},
	noResults: {
		color: 'gray',
		fontSize: 13,
		margin: 16
	},
	modalContainer: {
		width: '90vw',
		maxWidth: 700
	},
	membersTable: {
		boxShadow: "0px 1px 8px 0px rgba(0, 0, 50, 0.06)",
		border: '1px solid rgba(0,0,0,0.1)',
		overflow: 'hidden',
		borderRadius: 10,
		marginTop: 16
	}
};