import React, { useState, useEffect } from 'react';
import styles from './createCustomField.module.scss';

// Material
import { Checkbox } from '@material-ui/core';
import { getUserGroups } from '../../commons/Services';

const capitalize = (s) => {
    if(typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const SelectGroup = (props) => {
    const { setSelectedGroups, selectedGroups } = props;
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const toggleGroup = (groupId) => {
        const newGroups = [...selectedGroups];
        const index = newGroups.findIndex(selectedGroupId => selectedGroupId === groupId);
        if(index === -1) {
            newGroups.push(groupId);
        } else {
            newGroups.splice(index, 1);
        }
        setSelectedGroups(newGroups);
    };

    const fetchGroups = async () => {
        const response = await getUserGroups();
        setGroups(response.data.data);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchGroups();
    }, []);


    return (
        <div style={{ marginTop: 60, minHeight: 400, paddingBottom: 60 }}>
            <hr className={styles.topSeparator} />
            <label className={styles.label}>Seleccionar grupos</label>
            <div className={styles.options}>
                {
                    isLoading &&
                    <div className={styles.loading}>
                        <div className={styles.loadingIcon}>
                            <i className="fas fa-spinner fa-spin"></i>
                            Cargando grupos
                        </div>
                    </div>

                }
                {
                    !isLoading &&
                    groups.length > 0 && groups.map((group, index) => {
                        const checked = !!selectedGroups.find(selectedGroupId => selectedGroupId === group.id);
                        return (
                            <div onClick={() => toggleGroup(group.id)} className={styles.option} key={index}>
                                <Checkbox
                                    checked={checked}
                                    style={{
                                        color: checked ? "#5043dd" : "lightgray",
                                        pointerEvents: 'none',
                                        transform: 'scale(0.9)',
                                        marginRight: 12,
                                        // marginRight: 10,
                                        padding: 0,
                                    }}
                                />
                                <p>{capitalize(group?.name)}</p>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default SelectGroup;