export default {
	backdrop: {
		position: 'fixed',
		height: '100vh',
		width: '100vw',
		background: 'rgba(0,0,0,0)',
		top: 0,
		left: 0,
		zIndex: 106,
		// backdropFilter: 'blur(3px)',
		animationName: '$backdropAnimation',
		animationDuration: '.4s',
		WebkitBackfaceVisibility: "hidden",
		pointerEvents: 'none'
	},
	modal: {
		background: 'white',
		borderRadius: 8,
		height: '80vh',
		// boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 13px 0px',
		width: '90% !important',
		maxHeight: 700,
		minWidth: '300px',
		// maxWidth: 1000,
		margin: 'auto',
		marginTop: '10vh',
		position: 'relative',
		overflow: 'hidden',
		animationName: '$zoomIn',
		animationDuration: '.1s',
		pointerEvents: 'auto',
		zIndex: 100,
		'@media (max-width: 8000px)': {
			height: '100vh',
			width: 'calc(100vw - 240px) !important',
			maxHeight: '2000px !important',
			borderRadius: 0,
			marginTop: '0vh',
			marginLeft: 240
		},
		'@media (max-width: 1000px)': {
			height: '100vh',
			width: '100vw !important',
			maxHeight: '2000px !important',
			borderRadius: 0,
			marginTop: '0vh',
			marginLeft: 0,
		},
	},
	unmountedModal: {
		background: 'white',
		borderRadius: 4,
		height: '80vh',
		width: '90% !important',
		maxHeight: 700,
		minWidth: '300px',
		// maxWidth: 960,
		margin: 'auto',
		marginTop: '10vh',
		position: 'relative',
		overflow: 'hidden',
		transition: 'all .2s linear',
		animationDuration: '.2s',
		WebkitBackfaceVisibility: "hidden",
		WebkitPerspective: "1000",
		WebkitTransform: ["translate3d(0,0,0)", "translateZ(0)"],
		backfaceVisibility: "hidden",
		perspective: "1000",
		transform: ["translate3d(0,0,0)", "translateZ(0)"],
		'@media (max-width: 8000px)': {
			height: '100vh',
			width: 'calc(100vw - 240px) !important',
			maxHeight: '2000px !important',
			borderRadius: 0,
			marginTop: '0vh',
			marginLeft: 380,
			opacity: 1
		},
		'@media (max-width: 1000px)': {
			height: '100vh',
			width: '100vw !important',
			maxHeight: '2000px !important',
			borderRadius: 0,
			marginLeft: 0
		},
	},
	modalHeader: {
		height: 24,
		width: 'calc(100% - 56px)',
		borderBottom: '1px solid rgba(0,0,0,0.06)',
		padding: '20px 28px'
	},
	modalTitle: {
		fontSize: 18,
		fontWeight: 400,
		color: '#5043dd', 
		margin: 1
	},
	cerrarBtn: {
		position: 'absolute',
		margin: '0px !important',
		fontWeight: 500,
		top: 16,
		right: 16,
		cursor: 'pointer',
		height: 30,
		width: 30,
		borderRadius: 20,
		transition: '.4s',
		zIndex: 5,
		'&:hover': {
			background: 'rgba(0,0,0,0.07)'
		}
	},
	rightPanelBtn: {
		position: 'absolute',
		margin: '0px !important',
		fontWeight: 500,
		top: 16,
		right: 54,
		cursor: 'pointer',
		height: 30,
		width: 30,
		borderRadius: 4,
		transition: '.4s',
		zIndex: 5,
		'&:hover': {
			background: 'rgba(0,0,0,0.07)'
		},
		'@media (min-width: 800px)': {
			display: 'none'
		},
	},
	rightPanelIcon: {
		transform: 'scaleY(1.1)',
		fontSize: 17,
		color: 'rgba(0,0,0,0.6)',
		fontFamily: 'nunito',
		margin: 0,
		marginTop: 7,
		textAlign: 'center',
		height: 17,
		width: 18,
		stroke: 'rgba(0,0,0,0.6)',
		strokeWidth: 2,
		marginLeft: 6
	},
	actionsTooltipIcon: {
		transform: 'scaleY(1.1)',
		fontSize: 17,
		color: 'rgba(0,0,0,0.6)',
		fontFamily: 'nunito',
		margin: 0,
		marginTop: 6,
		width: '99%',
		textAlign: 'center',
		height: 17,
		width: 18,
		stroke: 'rgba(0,0,0,0.6)',
		strokeWidth: 2,
		marginLeft: 6
	},
	actionsTooltipBtn: {
		position: 'absolute',
		margin: '0px !important',
		fontWeight: 500,
		top: 16,
		right: 16,
		cursor: 'pointer',
		height: 30,
		width: 30,
		borderRadius: 4,
		transition: '.4s',
		zIndex: 5,
		'&:hover': {
			background: 'rgba(0,0,0,0.07)'
		}
	},
	closeIcon: {
		transform: 'scaleX(1.25)',
		fontSize: 17,
		color: 'rgba(0,0,0,0.6)',
		fontFamily: 'nunito',
		margin: 0,
		marginTop: 4,
		width: '99%',
		textAlign: 'center'
	},
	card: {
		background: 'white',
		borderRadius: 5,
		height: 25,
		display: 'inline-block',
		padding: 20,
		paddingTop: 12,
		paddingBottom: 12
	},
	linkUser: {
		color: 'gray',
		fontSize: 18,
		fontWeight: 600,
		cursor: 'pointer',
		'&:hover': {
			cursor: 'ponter !important',
			color: '#910091ff',
			textDecoration: 'underline'
		}
	},
	arrowLeft: {
		fontWeight: 100,
		marginRight: 8,
		opacity: 0.8,
		fontSize: 12
	},
	columnContainer: {
		height: 500,
		marginBottom: 100
	},
	label: {
		color: 'rgba(0,0,0,0.3)',
		fontSize: 12,
		fontWeight: 500,
		marginTop: 26,
		display: 'block',
		paddingLeft: 8
	},
	uploadFileIcon: {
		fontSize: 50,
		display: 'block',
		marginBottom: 16,
		color: 'rgba(0,0,0,0.1)',
		margin: 'auto'
	},
	notImageContainer: {
		width: '160px',
		height: '120px',
		background: 'white',
		borderRadius: 5,
		overflow: 'hidden',
		position: 'relative',
		transition: '0.3s',
		border: '1px solid rgba(0,0,0,0.1)',
		marginTop: 36,
		color: 'rgba(0,0,0,0.3)',
		textAlign: 'center',
		paddingTop: 40,
		fontSize: 13
	},
	img: {
		width: '100%',
		// height: '100%',
		objectFit: 'cover',
		position: 'absolute',
		transform: 'scale(1.1)',
		top: '50%',
		'-ms-transform': 'translateY(-50%)',
		transform: 'translateY(-50%)',
		imageOrientation: 'from-image !important'
	},
	leftContainer: {
		// overflowY: 'scroll',
		padding: 40,
		paddingTop: 0,
		// height: 'calc(100% - 126px)',
		background: 'white',
		width: 'calc(68% - 80px)',
		display: 'inline-block',
		verticalAlign: 'top',
		minHeight: '100%',
		'@media (max-width: 800px)': {
			width: 'calc(100% - 80px)'
		},
		'@media (max-width: 400px)': {
			width: 'calc(100% - 52px)',
			padding: 26,
			paddingTop: 0
		}
	},
	rightContainer: {
		padding: 30,
		transition: '.3s',
		overflow: 'hidden',
		paddingTop: 24,
		height: 'calc(100%)',
		width: 'calc(32% - 61px)',
		display: 'inline-block',
		'@media (max-width: 800px)': {
			paddingTop: 44,
			width: 130,
			padding: 20,
			position: 'absolute',
			right: 0,
			top: 0,
			zIndex: 2,
			opacity: 0,
			pointerEvents: 'none'
		},
		background: 'rgb(247, 248, 250)',
		// borderLeft: '1px solid rgba(0,0,0,0.06)',
		position: 'relative'
	},
	rightContainerOpen: {
		padding: 30,
		transition: '.3s',
		overflow: 'hidden',
		paddingTop: 24,
		height: '100%',
		width: 'calc(32% - 61px)',
		display: 'inline-block',
		'@media (max-width: 800px)': {
			paddingTop: 44,
			width: 210,
			position: 'absolute',
			right: 0,
			top: 0,
			zIndex: 2
		},
		background: 'rgb(247, 248, 250)',
		// borderLeft: '1px solid rgba(0,0,0,0.06)',
		position: 'relative'
	},
	infoContent: {
		position: 'fixed',
		width: 250,
		height: '96%',
		overflowY: 'auto',
		paddingRight: 40,
		// left: 0,
		// top: 0
		'::-webkit-scrollbar': {
			width: '2px !important',
		}
	},
	modalContent: {
		height: '100%',
		overflowX: 'hidden',
		background: '#f9f9f9',
		'@media (max-width: 800px)': {
			background: 'white'
		},
		// overflowY: 'scroll'
	},
	arrowBack: {
		height: 20,
		width: 20,
		stroke: '#2a2651',
		display: 'inline-block',
		verticalAlign: 'middle',
		marginTop: 22,
		marginRight: 12,
		cursor: 'pointer',
		'&:hover': {
			strokeWidth: 2,
			stroke: 'rgba(0,0,50,0.4)'
		},
		position: 'absolute',
		marginLeft: -30
	},
	description: {
		color: '#2a2651',
		padding: '6px 8px',
		marginLeft: -8,
		marginTop: '12px',
		borderRadius: '4px',
		fontSize: 20,
		fontWeight: 500,
		display: 'inline-block',
		marginBottom: 0,
		verticalAlign: 'middle',
		animationName: '$slideFromLeft',
		animationDuration: '.2s',
		width: 'calc(100% - 48px)',
		cursor: 'pointer',
		border: '1px solid rgba(0,0,0,0)',
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.01)',
			border: '1px solid rgba(0,0,0,0.06)',
		}
	},
	backText: {
		color: 'rgba(0,0,50,0.4)',
		padding: '8px 8px',
		marginLeft: -8,
		marginTop: '15px',
		fontSize: 16,
		fontWeight: 500,
		display: 'inline-block',
		marginBottom: 0,
		verticalAlign: 'middle',
		animationName: '$slideFromRight',
		animationDuration: '.2s',
	},
	textContent: {
		color: 'rgba(0,0,0,0.8)',
		fontSize: 18,
		fontWeight: 500,
		display: 'block',
		marginTop: 6
	},
	date: {
		marginTop: 6,
		color: 'gray',
		fontSize: 12
	},
	imageViwer: {
		position: 'fixed',
		width: '100vw',
		height: '100vw',
		background: 'rgba(255,255,255,0.9)',
		top: 0,
		left: 0,
		zIndex: 999999999,
		pointerEvents: 'auto'
	},
	fileViwer: {
		position: 'fixed',
		width: '100vw',
		height: '100vw',
		background: 'white',
		top: 0,
		left: 0,
		zIndex: 999999999
	},
	closeImageViewer: {
		position: 'absolute',
		margin: '0px !important',
		fontWeight: 500,
		top: 17,
		right: 31,
		transform: 'scaleX(1.25)',
		fontSize: 20,
		color: 'rgba(0,0,0,0.8)',
		cursor: 'pointer',
		fontFamily: 'nunito'
	},
	downloadImage: {
		position: 'absolute',
		margin: '0px !important',
		top: '17px !important',
		right: '86px !important',
		fontSize: '20px !important',
		transform: 'scale(0.67) !important',
		color: 'rgba(0,0,0,0.75) !important',
		cursor: 'pointer !important',
		paddingBottom: 2,
		padding: 1,
		borderBottom: '3px solid rgba(0,0,0,0.8) !important'
	},
	'@keyframes slideRight': {
		from: {
			maxWidth: '60vw',
			maxHeight: '100vh',
			top: '50%',
			left: '40%',
			transform: 'translateY(-50%) translateX(-50%) scale(0.6)',
			position: 'fixed',
			opacity: '0'
		},
		to: { opacity: 1 }
	},
	imgBig: {
		maxWidth: '60vw',
		maxHeight: '100vh',
		margin: 'auto',
		top: '50%',
		left: '50%',
		'-ms-transform': 'translateY(-50%) translateX(-50%)',
		transform: 'translateY(-50%) translateX(-50%)',
		position: 'fixed',
		animationName: '$slideRight',
		animationDuration: '.2s',
		animationTimingFunction: 'linear',
		cursor: 'zoom-in',
		transition: '.2s'
	},
	imgBigZoomedIn: {
		maxWidth: '60vw',
		maxHeight: '100vh',
		margin: 'auto',
		top: '50%',
		left: '50%',
		'-ms-transform': 'translateY(-50%) translateX(-50%) scale(1.6)',
		transform: 'translateY(-50%) translateX(-50%) scale(1.6)',
		position: 'fixed',
		animationName: '$slideRight',
		animationDuration: '.2s',
		animationTimingFunction: 'linear',
		cursor: 'zoom-out',
		transition: '.2s'
	},
	stateIndicator: {
		height: 10,
		width: 10,
		borderRadius: 20,
		background: 'rgba(255,205,0,0.6)',
		display: 'inline-block',
		marginRight: 6,
		marginTop: -2,
		verticalAlign: 'middle'
	},
	state: {
		padding: '4px 12px',
		borderRadius: 16,
		verticalAlign: 'middle',
		marginTop: 4,
		paddingLeft: 0,
		fontSize: 14,
		fontWeight: 400,
		display: 'inline-block',
		color: 'rgba(0,0,0,1)'
	},
	amount: {
		padding: '4px 12px',
		borderRadius: 16,
		verticalAlign: 'middle',
		marginTop: 10,
		paddingLeft: 0,
		fontSize: 16,
		fontWeight: 500,
		display: 'inline-block',
		color: '#5043dd'
	},
	avatar: {
		height: 40,
		width: 40,
		borderRadius: 30,
		marginTop: -17,
		marginBottom: 0,
		marginLeft: 0,
		display: 'inline-block',
		verticalAlign: 'middle'
	},
	userName: {
		display: 'inline-block',
		verticalAlign: 'middle',
		marginTop: -21,
		fontWeight: 600,
		fontSize: 15,
		marginLeft: 18,
		color: 'rgba(0,0,0,0.8)',
		textTransform: 'Capitalize'
	},
	userEmail: {
		position: 'absolute',
		fontWeight: 500,
		fontSize: 11,
		color: 'rgba(0,0,0,0.5)',
		top: 25,
		left: 58
	},
	info: {
		display: 'block',
		position: 'relative',
		marginTop: 60,
		marginBottom: 50,
		borderTop: '1px solid rgba(0,0,0,0.06)',
		paddingTop: 30,
		width: '100%'
	},
	actionBtns: {
		marginTop: 40
	},
	actionBtn: {
		display: 'block',
		marginTop: 12
	},
	itemSkeleton: {
		height: 76,
		width: '50%',
		marginLeft: -40,
		position: 'relative',
		marginTop: 35
	},
	avatarSkeleton: {
		height: 40,
		width: 40,
		borderRadius: 20,
		position: 'absolute',
		top: 18,
		left: 20,
		background: 'rgba(0,0,0,0.07)'
	},
	textSkeleton: {
		position: 'absolute',
		height: 12,
		width: '60%',
		left: 18,
		top: 20,
		background: 'linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%)',
		borderRadius: 10,
		animation: '$pulse 1.2s ease-in-out infinite'
	},
	textSkeletonSmall: {
		position: 'absolute',
		height: 12,
		width: '30%',
		left: 18,
		top: 45,
		background: 'linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%)',
		borderRadius: 10,
		animation: '$pulse 1.2s ease-in-out infinite',
		'$from': '#f5f5f5',
		'$to': 'scale-color($from, $lightness: -10%)'
	},
	'@keyframes pulse': {
		from: {
			backgroundPosition: '0% 0%'
		},
		to: {
			backgroundPosition: '-135% 0%'
		}
	},
	'@keyframes zoomIn': {
		from: {
			transform: 'scale(0.9)'
		},
		to: {
			transform: 'scale(1)'
		}
	},
	'@keyframes backdropAnimation': {
		from: {
			opacity: 0
		},
		to: {
			opacity: 1
		}
	},
	'@keyframes slideFromLeft': {
		from: {
			transform: 'translateX(-10px)'
		},
		to: {
			transform: 'scale(1)'
		}
	},
	'@keyframes slideFromRight': {
		from: {
			transform: 'translateX(10px)'
		},
		to: {
			transform: 'scale(1)'
		}
	},
	unmountedBackdrop: {
		position: 'fixed',
		height: '100vh',
		width: '100vw',
		background: 'rgba(0,0,0,0.15)',
		top: 0,
		left: 0,
		zIndex: 90,
		backdropFilter: 'blur(3px)',
		opacity: 0,
		transition: 'all .1s linear'
	},
	pdfPreview: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		objectFit: 'cover',
		background: 'white',
		zIndex: 1,
		top: 0,
		left: 0,
	},
	pdf: {
		position: "absolute",
		top: "62px",
		left: "46px",
		fontSize: "12px",
		fontWeight: 800,
		backgroundColor: "#5043dd",
		padding: "2px 4px",
		color: "white",
		opacity: 0.7
	},
	pdfPreviewSvg: {
		position: "absolute",
		display: "block",
		margin: "auto",
		top: "54px !important",
		left: "50%",
		transform: "translateX(-22px)"
	},
	filePlaceholder: {
		transition: "0.2s",
		height: "170px",
		width: "170px",
		borderRadius: "6px",
		marginTop: "36px",
		marginBottom: "20px",
		marginRight: '20px',
		cursor: "pointer",
		color: "rgba(0, 0, 0, 0.1)",
		fontSize: "56px",
		border: "1px solid rgba(0, 0, 0, 0.1)",
		"&:hover": {
			opacity: 0.6
		},
		position: "relative",
		overflow: "hidden",
		zIndex: 0,
		display: 'inline-block'
	},
	stickyHeader: {
		position: 'sticky',
		top: -20,
		zIndex: 1,
		background: 'rgba(255,255,255,0.8)',
		backdropFilter: 'blur(3px)',
		paddingBottom: 20,
		paddingTop: 26,
		paddingLeft: 30,
		'@media (max-width: 500px)': {
			paddingTop: 46,
		}
	},
	pdfLoader: {	
		height: 1,
		zIndex: 10
	},
	hiddenLoader: {
		display: 'none'
	},
	fileFullPreview: {
		height: 'calc(100vh - 60px)',
		width: '100%',
		// maxWidth: '1000px',
		top: '60px',
		left: '50%',
		'-ms-transform': 'translateX(-50%)',
		transform: 'translateX(-50%)',
		position: 'absolute',
		background: 'transparent'
	}
};