import React, { useState, useEffect } from 'react';

// Components
import Layout from '../_shared/Layout';
import SingleStat from './SingleStat.js';
import LineChart from './LineChart.js';
import DashboardSkeleton from './DasboardSkeleton';

// Utils
import { formatCurrency } from '../../commons/Utils.js';
import { getCookie } from '../../commons/CookiesHelper';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

// Icons
import { faDollarSign, faReceipt, faUser } from '@fortawesome/free-solid-svg-icons';

// Services
import { getDashboardData } from '../../commons/Services';





const Dashboard = () => {
	const history = useHistory();
	const [hasData, setHasData] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState({
		contacts: 0,
		totalAmount: '',
		totalExpenses: 0,
		details: []
	});

	const fetchData = async (startDate, endDate) => {
		const res = await getDashboardData(startDate, endDate);
		if (res.status == 200) {
			setData(res.data);
			if (res.data.totalExpenses == 0) {
				setHasData(false);
			}
		}
		setIsLoading(false);
	}

	useEffect(() => {
		const startDate = moment().subtract(60, 'days').toDate();
		const endDate = moment().toDate();
		fetchData(startDate, endDate);
	}, []);

	useEffect(() => {
		if(getCookie('permissions') === 'basic')
			history.push('/expenses');
	}, []);

	return (
		<Layout title="Resumen de informes">
			{
				isLoading ?
					<DashboardSkeleton /> :
					<>
						<div className="dashboardContainer height120">
							<div className="dashboardCard card4">
								<SingleStat icon={'dollar'} color="#704eff" label="Total de gastos" value={formatCurrency(data.totalAmount)} />
							</div>
							<div className="dashboardCard card4">
								<SingleStat icon={'expense'} color="#704eff" label="Gastos subidos" value={data.totalExpenses} />
							</div>
							<div className="dashboardCard card4">
								<SingleStat icon={'user'} color="#704eff" label="Miembros" value={data.contacts} />
							</div>
						</div>
						<div className="dashboardContainer height200">
							<div className="dashboardCard card8">
								{
									data.details.length > 0 &&
									<LineChart data={data.details} />
								}
							</div>
						</div>
					</>
			}

		</Layout>
	);
}

export default Dashboard;