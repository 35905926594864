import React, { useEffect, useState } from 'react';
import styles from './mailing.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Button from '../generics/Button'

// Services
import { sendEmailVerification, confirmEmail } from '../../commons/Services';


const EmailVerification = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [verificationStatus, setVerificationStatus] = useState('pending');
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const history = useHistory();

    const verifyEmail = async () => {
        const res = await confirmEmail(token);
        if (res.status === 200) {
            setVerificationStatus('verified');
        } else {
            setVerificationStatus('error');
        }
        setIsLoading(false);
    }

    const handleResend = async () => {
        setIsLoading(true);
        setVerificationStatus('sending');
        const res = await sendEmailVerification();
        if (res.status === 200) {
            history.push('./emailVerificationNeeded');
        } else {
            setVerificationStatus('error');
        }
    }

    useEffect(() => {
        verifyEmail();
    }, []);

    const text = {
        pending: 'Verificando email',
        verified: 'Ya verificamos tu email',
        error: 'Email expirado o incorrecto',
        sending: 'Enviando email',
        sent: 'Email enviado'
    }

    return (
        <>
            <div className={styles.loaderContainer} >
                {isLoading && <div className={styles.ldsRipple}><div></div><div></div></div>}
                <img
                    className={verificationStatus !== 'verified' ? styles.hiddenMemeImage : styles.memeImage}
                    src="https://i.ibb.co/rcGxqqz/verified.png"
                />
            </div>
            <h1 className={styles.title}>{text[verificationStatus]}</h1>
            {
                verificationStatus === 'error' &&
                <div className={styles.verifiedButton}>
                    <Button disabled={isLoading} onClick={handleResend}>
                        Reenviar email
                </Button>
                </div>
            }
            {
                !isLoading &&
                <div className={styles.verifiedButton}>
                    <Link to="/login">
                        Volver a expendr
                    </Link>
                </div>
            }
        </>
    );
}

export default EmailVerification;