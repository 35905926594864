import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';


const MenuButton = props => {
    const { toggleMenu } = props;

    return (
        <FontAwesomeIcon
            className="menuButton"
            onClick={toggleMenu}
            icon={faBars}
        />
    );
}

export default MenuButton;