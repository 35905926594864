import axios from 'axios';
import { getCookie } from './CookiesHelper';
import { getFileStringFromDate } from './Utils';

const baseUrl = process.env.REACT_APP_API_URL;

export const changeBoardStatus = async (expenseId, state, newIndex = undefined) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/expense/change-state`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: {
            expenseId,
            state: state,
            newIndex
        }
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response;
    });
};


export const getBoardStatus = async (userIds, startDate, endDate) => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/expense/board`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        params: {
            userIds,
            startDate,
            endDate
        }
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response;
    });
};


export const getExpenses = async (limit, page, sortDirection, state, search, userIds, startDate, endDate) => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/expense/organization`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        params: {
            limit,
            page,
            sortDirection,
            state,
            search,
            userIds,
            startDate,
            endDate
        }
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response;
    });
};


export const getExpensesFromUser = async (limit, page, sortDirection, state, search, userIds, startDate, endDate) => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/expense`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        params: {
            limit,
            page,
            sortDirection,
            state,
            search,
            userIds,
            startDate,
            endDate
        }
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response;
    });
};


export const getExpenseById = id => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/expense/${id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response;
    });
};


export const createExpense = async (expense) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/expense`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: expense
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response;
    });
};


export const editExpense = async (payload, expenseId) => {
    return axios({
        method: 'PATCH',
        url: `${baseUrl}/expense/${expenseId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: payload
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response;
    });
};


export const editCustomFieldData = async (customFieldId, value, selectValue) => {
    return axios({
        method: 'PATCH',
        url: `${baseUrl}/expense/custom-field-data/${customFieldId}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: {
            selectedValue: value,
            selectValueId: selectValue
        }
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response;
    });
};


export const deleteExpense = id => {
    return axios({
        method: 'DELETE',
        url: `${baseUrl}/expense/${id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r.status;
    }).catch(e => {
        return e.response.status;
    });
};


export const restoreExpense = id => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/expense/${id}/restore`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r.status;
    }).catch(e => {
        return e.response.status;
    });
};


export const getMembers = async () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/organization/user`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r.data;
    }).catch(e => {
        return e.response;
    });
};


export const getUserInfo = async () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/user/me`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const registerUser = async userInfo => {
    if(!userInfo) return false;
    return axios({
        method: 'POST',
        url: `${baseUrl}/user`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            email: userInfo.email,
            password: userInfo.password,
            name: userInfo.fname,
            lastName: userInfo.lname,
            phoneNumber: userInfo.phoneNumber,
            fromWeb: true
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const login = async (email, password) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/auth/login`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            email: email,
            password: password
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const requestPasswordChange = async (email, password) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/user/change-password/request`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            email: email
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const changePassword = async (newPassword, token) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/user/change-password/process?token=${token}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            newPassword
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const createOrganization = async name => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/organization`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken')
        },
        data: {
            name,
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const getDashboardData = async (startDate, endDate) => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/dashboard/summary/`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        params: {
            startDate,
            endDate
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const generateInvitationCode = async role => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/organization/invitation-code`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: {
            organizationRole: role,
            oneUse: false
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const joinOrganization = async invitationCode => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/user/invitation`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: {
            invitationCode
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const getOrganizationSettings = async () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/organization/setting`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const setOrganizationSettings = async data => {
    return axios({
        method: 'PATCH',
        url: `${baseUrl}/organization/setting`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const getNotificationsSettings = async () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/notification/setting`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const setNotificationsSettings = async data => {
    return axios({
        method: 'PATCH',
        url: `${baseUrl}/notification/setting`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const getCategories = async () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/category`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const addCategory = async name => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/category`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: {
            name
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const deleteCategory = async id => {
    return axios({
        method: 'DELETE',
        url: `${baseUrl}/category/${id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const addCustomField = async cf => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/custom-field`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: cf
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const editCustomField = async cf => {
    return axios({
        method: 'PATCH',
        url: `${baseUrl}/custom-field/${cf.id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: cf
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const toggleCustomFieldIsActive = async cf => {
    return axios({
        method: 'PATCH',
        url: `${baseUrl}/custom-field/${cf.id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: { ...cf, active: !cf.active }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const getFields = async () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/field`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const getCustomFields = async () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/custom-field`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const deleteCustomField = async id => {
    return axios({
        method: 'DELETE',
        url: `${baseUrl}/custom-field/${id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const getUserGroups = async () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/user/group`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const addUserGroup = async group => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/user/group`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: group
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const updateGroup = async group => {
    return axios({
        method: 'PATCH',
        url: `${baseUrl}/user/group/${group.id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: group
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const deleteGroup = async id => {
    return axios({
        method: 'DELETE',
        url: `${baseUrl}/user/group/${id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const getExpensesToBeReimbursed = async () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/expense/reimbursed`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const refundAListOfExpenses = async expensesList => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/expense/reimbursed`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: {
            expensesIds: expensesList
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const getRefundsHistory = (search) => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/expense/reimbursed/history`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org'),
        },
        params: {
            search,
            limit: 40
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const getNotifications = () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/notification/user`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org'),
        },
        params: {
            limit: 12
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const markNotificationAsRead = (id) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/notification/${id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org'),
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const uploadFile = (image) => {
    const file = new FormData();
    file.append('file', image);
    return axios({
        method: 'POST',
        url: `${baseUrl}/expense/upload-file`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org'),
        },
        data: file
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const sendEmailVerification = () => {
    const email = getCookie('userEmail');

    return axios({
        method: 'POST',
        url: `${baseUrl}/user/email/resend`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org'),
        },
        data: {
            email
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const confirmEmail = (token) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/user/email/confirm`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org'),
        },
        data: {
            token
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const comment = (comment, expenseId) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/expense/${expenseId}/comment`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org'),
        },
        data: {
            text: comment
        }
    }).then(r => {
        return r;
    }).catch(e => {
        alert('Error al publicar comentario');
        return e.response;
    });
};


export const sendFeedback = (title, text, image, type) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/ticket`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org'),
        },
        data: {
            text,
            title,
            type,
            attachments: image ? [{ url: image }] : undefined
        }
    }).then(r => {
        return r;
    }).catch(e => {
        alert('Error al enviar comentario');
        return e.response;
    });
};


export const removeUserFromOrganization = (userId) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/user/organization/expel`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org'),
        },
        data: {
            userId
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const changeUserRole = (userId, role) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/user/organization/change-role`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org'),
        },
        data: {
            userId,
            role
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};


export const downloadFile = async (limit, page, sortDirection, state, search, userIds, startDate, endDate, excel, zip) => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/expense/organization`,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        params: {
            limit,
            page,
            sortDirection,
            state,
            search,
            userIds,
            startDate,
            endDate,
            excel,
            zip
        }
    }).then(blobby => {
        const time = getFileStringFromDate(new Date());
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let objectUrl = window.URL.createObjectURL(
            blobby.data,
            {
                type: excel ?
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," :
                    "application/zip"
            }
        );
        anchor.href = objectUrl;
        anchor.download =
            blobby.headers.filename ||
            (excel ? `Listado-${time}.xlsx` : `Archivos-Adjuntos-${time}.zip`);
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
    }).catch(e => {
        return e.response;
    });
};

export const getPlans = async () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/plan`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const generateCheckoutSession = async (planId) => {
    return axios({
        method: 'POST',
        url: `${baseUrl}/checkout`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        },
        data: {
            planId,
            successUrl: window.location.origin + "/plans",
            cancelUrl: window.location.origin + "/plans",
            backUrl: window.location.origin + "/plans"
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};

export const getBillingInfo = async () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/bill/info`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getCookie('accessToken'),
            'Organization': getCookie('org')
        }
    }).then(r => {
        return r;
    }).catch(e => {
        return e.response;
    });
};
