import React from 'react';
import styles from './createCustomField.module.scss';

// Components
import TypeInput from './TypeInput.js';
import VisibilityInput from './VisibilityInput.js';
import SwitchMU from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import ShowWhenInput from './ShowWhenInput';


const Switch = withStyles({
    switchBase: {
        color: 'white',
        '&$checked': {
            color: 'white',
        },
        '&$checked + $track': {
            backgroundColor: '#704eff',
            opacity: 0.8
        },
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
        '&:hover + $track': {
            opacity: 0.6
        }
    },
    checked: {
    },
    track: {
        backgroundColor: 'rgba(0,0,0,0.3)',
        transformOrigin: 'middle center',
        height: 20,
        borderRadius: 20,
        transform: 'scale(1.2) scaleX(1.1)',
        '&:hover': {
            backgroundColor: '#704eff',
            opacity: 0.7
        }
    },
    thumb: {
        marginTop: 3,
        transform: 'scale(1)'
    }
})(SwitchMU);


const InputField = (props) => {
    const { 
        label, 
        value, 
        onChange, 
        type, 
        setRoles, 
        roles, 
        checked, 
        showInUserPayment, 
        showInOrganizationPayment,
        onClick
    } = props;

    return (
        <>
            <label onClick={onClick && onClick} className={type === 'link' ? styles.linkLabel : styles.label}>{label}</label>
            {
                type === 'text' &&
                <input
                    className={styles.textInput}
                    onChange={onChange}
                    value={value}
                    placeholder="Ingresa un título..."
                />
            }
            {
                type === 'select' &&
                <TypeInput
                    setType={onChange}
                    type={value}
                />
            }
            {
                type === 'visibility' &&
                <VisibilityInput
                    setAccessType={onChange}
                    accessType={value}
                    setRoles={setRoles}
                    roles={roles}
                />
            }
            {
                type === 'switch' &&
                <div style={{ marginLeft: -6, marginTop: -12, marginBottom: 6 }}>
                    <Switch
                        checked={checked}
                        onChange={onChange}
                    />
                </div>
            }
            {
                type === 'showWhen' &&
                <ShowWhenInput
                    showInOrganizationPayment={showInOrganizationPayment}
                    showInUserPayment={showInUserPayment}
                    setValues={onChange}
                />
            }
        </>
    );
};

export default InputField;