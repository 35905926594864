import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const useStyles = makeStyles(styles);

const getClassNameByType = (classes, type) => {
    if(type === 'approve') return classes.approve;
    if(type === 'disapprove') return classes.disapprove;
    return classes.root;
}

const Button = props => {
    const classes = useStyles();
    const { onClick, children, icon, disabled, type, id } = props;

    const handleClick = () => {
        if(!disabled && onClick) {
            onClick();
        }
    }

    return (
        <div>
            <div id={id} className={disabled ? classes.disabledRoot : getClassNameByType(classes, type)} onClick={handleClick}>
                {
                    icon &&
                    <FontAwesomeIcon icon={icon} className={classes.buttonIcon} />
                }
                {children}
            </div>
        </div>
    );
}

export default Button;