import React, { useState } from 'react';

// Styles
import styles from './fileSelector.module.scss';

// Components
import Dropzone from 'react-dropzone';
import ExpendrIcon from './ExpendrIcon';

// Store
import { uploadFile } from '../../commons/Services';


const handleChooseImage = async (image, setImage, setImageReady) => {
    setImageReady(false);
    const res = await uploadFile(image[0]);
    if(res?.status == 200) {
        setImage(res.data.url);
    }
    setImageReady(true);
};


const FileSelector = (props) => {
    const [imageReady, setImageReady] = useState(true);
    const {
        image,
        setImage
    } = props;

    return <div className={styles.fileSelectorContainer}>
        <div className={styles.filePlaceholder}>
            <Dropzone
                onDrop={chosenImage => handleChooseImage(chosenImage, setImage, setImageReady)}
                accept=".jpg,.jpeg,.png,.pdf,.mp4"
                multiple={false}
            >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div
                            {...getRootProps()}
                            style={{
                                outline: 'none'
                            }}
                        >
                            {
                                !image &&
                                <input
                                    {...getInputProps()}
                                />
                            }
                            <div>
                                {!imageReady ? (
                                    <div className={styles.loader}></div>
                                ) : (
                                    <>
                                        {
                                            image ?
                                                <p className={styles.selectedFile}>1 archivo adjunto</p> :
                                                <div className="feedbackClip">
                                                    <ExpendrIcon icon='clip' stroke="#777" strokeWidth='2' />
                                                </div>
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>
            {
                image &&
                <div onClick={() => setImage(null)} className={styles.removeFile}>x</div>
            }
        </div>
    </div>;
};

export default FileSelector;