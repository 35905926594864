import { useState, useContext, useEffect } from 'react';

// State
import { UserContext } from '../../../commons/UserContext';

const useExpenseState = () => {
    const [isDateModalOpen, setIsDateModalOpen] = useState(false);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [customFieldModal, setCustomFieldModal] = useState("");
    const { expenseReducer } = useContext(UserContext);
    const { setAllowKeys } = expenseReducer;

    useEffect(() => {
        setAllowKeys(!isDateModalOpen && !isCategoryModalOpen);
    }, [isDateModalOpen, isCategoryModalOpen]);

    const toggleModal = (name) => {
        switch (name) {
            case 'date':
                setIsDateModalOpen(!isDateModalOpen);
                break;

            case 'category':
                setIsCategoryModalOpen(!isCategoryModalOpen);
                break;

            default:
                break;
        }
    }

    return [
        {
            isDateModalOpen,
            isCategoryModalOpen,
            customFieldModal
        },
        toggleModal,
        setCustomFieldModal
    ]
}

export default useExpenseState;