import React, { useState } from 'react';

// Components
import LeftMenu from './LeftMenu';
import MenuButton from './MenuButton';
import DownloadSnackbar from '../generics/DownloadSnackbar';

// Utils
import { isMobile } from '../../commons/Utils';

const Layout = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(!isMobile());
    const { title, breadcrumbs, children, isLoading=false } = props;

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    return (
        <div id="app" className="App">
            <LeftMenu open={isMenuOpen} />
            <div className={isMenuOpen ? "pageContainer" : "pageContainerFullWidth"}>
                <MenuButton toggleMenu={toggleMenu} />
                <div className="pageContent">
                    <h1>{title}<small>{breadcrumbs}</small></h1>
                    <div style={isLoading ? { opacity: 0.4 } : {}}>
                        {children}
                    </div>
                </div>
            </div>
            <DownloadSnackbar visible={true} text="Descarga en progreso" />
        </div>
    );
}

export default Layout;