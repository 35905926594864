import React, { useContext } from 'react';
import styles from './downloadSnackbar.module.scss';
import { UserContext } from '../../commons/UserContext';


const Snackbar = () => {
    const { downloadState } = useContext(UserContext);
    const { visible, fileName, completed = true } = downloadState;

    return (
        <>
            {
                visible &&
                <div className={styles.container}>
                    <label className={styles.label}>
                        {
                            completed ? 'Archivo listo' : 'Descarga en progreso'
                        }
                    </label>
                    <div className={styles.content}>
                        <svg className={!completed ? styles.svg : styles.completedSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1" stroke="#aaa" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                        </svg>
                        <p className={styles.fileName}>{fileName}</p>
                        {
                            completed != true &&
                            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default Snackbar;