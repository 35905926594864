import React, { useEffect, useState } from 'react';
import styles from './styles';

//Components
import ImageViwer from './ImageViewer';
import ModalSkeleton from './ModalSkeleton';
import FilesContainer from './FilesContainer';
import Activity from '../activity/Activity';
import DetailActionsTooltip from './DetailActionsTooltip';
import Snackbar from '../generics/Snackbar';
import RightPanel from './RightPanel';
import CustomFieldModal from './CustomFieldModal';
import FileSelector from '../createExpense/FileSelector';

// Material ui
import { makeStyles } from '@material-ui/core/styles';

// Utils
import { formatCurrency } from '../../commons/Utils.js';

// Services
import {
	changeBoardStatus,
	getExpenseById,
	deleteExpense,
	restoreExpense,
	getCategories
} from '../../commons/Services';

// Hooks
import useEditExpenseState from './customHooks/useEditExpenseState';

const useStyles = makeStyles(styles);

const isPdf = url => {
	const fileExtension = url?.substring(url.length - 3);
	return fileExtension === 'pdf';
};


const ExpenseDetail = props => {
	const classes = useStyles();
	const [isImageOpen, setIsImageOpen] = useState(false);
	const [isLoadingState, setIsLoadingState] = useState(false);
	const [isLoadingExpense, setIsLoadingExpense] = useState(true);
	const [expense, setExpense] = useState(null);
	const [hoverArrowBack, setHoverArrowBack] = useState(false);
	const [isRightContainerOpen, setIsRightContainerOpen] = useState(false);
	const [tooltip, setTooltip] = useState(null);
	const [categories, setCategories] = useState([]);
	const [uploadingImage, setUploadingImage] = useState(false);
	const [snackbar, setSnackbar] = useState({
		value: '',
		visible: false,
	});

	const fetchExpense = async () => {
		const res = await getExpenseById(props.expense);
		setExpense(res.expense);
		setIsLoadingExpense(false);
	};

	const fetchCategories = async () => {
		const res = await getCategories();
		if(res.data) {
			setCategories(res.data.categories);
		}
	};

	const changeExpenseState = async (expenseState) => {
		setIsLoadingState(true);
		await changeBoardStatus(expense.id, expenseState);
		await fetchExpense();
		fetchExpenses();
		setIsLoadingState(false);
	};

	const [
		{ openedModal, editingFields, tooltipPosition },
		{ openModal, closeModal: closeEditModal, changeAndSave, setEditingFields }
	] = useEditExpenseState(changeExpenseState, fetchExpense);

	useEffect(() => {
		fetchExpense();
		fetchCategories();
	}, []);

	const closeModal = () => {
		handleGoBack();
	};

	const { setSelectedExpense, getStateDetails, fetchExpenses } = props;

	const handleGoBack = () => {
		setSelectedExpense(null);
	};

	const toggleRightPanel = () => {
		setIsRightContainerOpen(!isRightContainerOpen);
	};

	const handleDeleteExpense = async () => {
		if(snackbar.value === 'Gasto eliminado' && snackbar.visible === true) {
			return;
		}
		// Set Loading
		const res = await deleteExpense(expense.id);
		if(res === 200) {
			fetchExpenses();
			setSnackbar({ visible: true, value: "Gasto eliminado" });
			return;
		}
		if(res === 400) {
			setSnackbar({ visible: true, value: "Solo se puede eliminar gastos en estado pendiente" });
			setTimeout(() => { setSnackbar({ ...snackbar, visible: false }); }, 2000);
			return;
		}
		setSnackbar({ visible: true, value: "Ocurrió un error al eliminar el gasto" });
		setTimeout(() => { setSnackbar({ ...snackbar, visible: false }); }, 2000);
	};

	const handleRestoreExpense = async () => {
		// Set Loading
		setSnackbar({ value: "Gasto eliminado", visible: false });
		const res = await restoreExpense(expense.id);
		if(res === 200) {
			fetchExpenses();
			return;
		}
		setSnackbar({ visible: true, value: "Ocurrió un error al restaurar el gasto" });
		setTimeout(() => { setSnackbar({ ...snackbar, visible: false }); }, 2000);
	};

	const getStateTextAndColor = (newValue) => {
		if(newValue) {
			return expense?.state ? getStateDetails(newValue, expense.amountState) : {};
		}
		return expense?.state ? getStateDetails(expense.state, expense.amountState) : {};
	};

	const openEditModal = () => {
		if(snackbar.value === 'Gasto eliminado') return;
		openModal('description');
	};

	const setImage = (img, callback) => {
		changeAndSave('newAttachments', [{ url: img }]);
		setTimeout(() => {
			callback();
		}, 500);
	};


	return (
		<div className={classes.backdrop}>
			<div className={classes.columnContainer}>
				<div className={classes.modal}>
					<div className={classes.actionsTooltipBtn} onClick={() => setTooltip('actions')}>
						<svg xmlns="http://www.w3.org/2000/svg" className={classes.actionsTooltipIcon} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<circle cx="5" cy="12" r="1" />
							<circle cx="12" cy="12" r="1" />
							<circle cx="19" cy="12" r="1" />
						</svg>
					</div>
					<div className={classes.rightPanelBtn} onClick={toggleRightPanel}>
						<svg xmlns="http://www.w3.org/2000/svg" className={classes.rightPanelIcon} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<rect x="4" y="4" width="16" height="16" rx="2" />
							<line x1="15" y1="4" x2="15" y2="20" />
						</svg>
					</div>
					{
						isLoadingExpense &&
						<ModalSkeleton />
					}
					{
						expense &&
						<div className={classes.modalContent} style={(snackbar.value === 'Gasto eliminado' && snackbar.visible === true) ? { pointerEvents: 'none' } : {}}>
							<div className={classes.leftContainer}>
								<div className={classes.expenseData}>
									<div className={classes.stickyHeader}>
										<div>
											<svg
												className={classes.arrowBack}
												width="24" height="24"
												viewBox="0 0 24 24"
												strokeWidth="1.5"
												stroke="#2a2651"
												fill="none"
												strokeLinecap="round"
												strokeLinejoin="round"
												onMouseEnter={() => setHoverArrowBack(true)}
												onMouseLeave={() => setHoverArrowBack(false)}
												onClick={handleGoBack}
												style={{ pointerEvents: 'auto' }}
											>
												<path stroke="none" d="M0 0h24v24H0z" fill="none" />
												<line x1="5" y1="12" x2="19" y2="12" />
												<line x1="5" y1="12" x2="11" y2="18" />
												<line x1="5" y1="12" x2="11" y2="6" />
											</svg>
											{
												hoverArrowBack ?
													<p className={classes.backText}>
														Volver al listado
													</p> :
													<p className={classes.description} onClick={openEditModal}>
														{editingFields['description'] || expense.description.charAt(0).toUpperCase() + expense.description.slice(1)}
														{(snackbar.value === 'Gasto eliminado' && snackbar.visible === true) && ' (eliminado)'}
													</p>
											}

										</div>
										<div className={classes.amount}>
											{
												editingFields['amountTotal'] ?
													formatCurrency(editingFields['amountTotal']) :
													formatCurrency(parseFloat(expense.amountCompany) + parseFloat(expense.amountOwn))
											}
										</div>
									</div>
									<FilesContainer
										classes={classes}
										attachments={expense?.attachments}
										image={expense?.attachments[0]?.url}
										images={expense?.attachments}
										setIsImageOpen={setIsImageOpen}
										isImageOpen={isImageOpen}
										setImage={setImage}
									/>
									<FileSelector
										classes={classes}
										attachments={expense.attachments}
										image={null}
										setIsImageOpen={setIsImageOpen}
										isImageOpen={isImageOpen}
										setImage={setImage}
										keepLoading={true}
										expense={expense}
										smallLayout={expense?.attachments?.length > 0}
									/>
									<Activity activities={expense.activities} expenseId={expense.id} />
								</div>
							</div>
							<RightPanel
								expense={expense}
								isRightContainerOpen={isRightContainerOpen}
								isLoadingState={isLoadingState}
								getStateTextAndColor={getStateTextAndColor}
								changeExpenseState={changeExpenseState}
								fetchExpense={fetchExpense}
								categories={categories}
								parentChangeValue={changeAndSave}
							/>
						</div>
					}
					<CustomFieldModal
						handleClose={() => closeEditModal(null)}
						changeAndSave={changeAndSave}
						open={openedModal === 'description'}
						type={openedModal}
						title={"Descripción"}
						customField={{ value: expense?.description?.charAt(0).toUpperCase() + expense?.description?.slice(1) }}
					/>
				</div>
			</div>
			{
				isImageOpen || isImageOpen === 0 ?
					<ImageViwer
						description={expense.description}
						imageUrl={expense.attachments[isImageOpen]?.url}
						setIsImageOpen={setIsImageOpen}
					/> :
					null
			}
			<DetailActionsTooltip
				open={tooltip === 'actions'}
				setIsOpen={setTooltip}
				handleDeleteExpense={handleDeleteExpense}
			/>
			<Snackbar
				visible={snackbar.visible}
				text={snackbar.value}
				action={handleRestoreExpense}
				actionText={snackbar.value === 'Gasto eliminado' && 'Deshacer'}
			/>
		</div>
	);
};

export default ExpenseDetail;