import { useState, useEffect } from 'react';


const useGlobalSnackbar = () => {
    const [lock, setLock] = useState(false);
    const [globalSnackbar, setGlobalSnackbar] = useState({
        open: false,
        message: "",
        variant: "",
        action: null,
        actionText: null,
        duration: 4000
    });

    const clearSnackbar = () => {
        setGlobalSnackbar(prevState => ({
            message: prevState.message,
            actionText: prevState.actionText,
            action: prevState.action,
            open: false
        }));
        setTimeout(() => {
            setGlobalSnackbar({
                open: false,
                message: "",
                variant: "",
                action: null,
                actionText: null,
                duration: 4000
            });
            setLock(false);
        }, 500);
    };

    const showSnackbar = (message, extraProps) => {
        if(lock) return;
        setLock(true);
        setGlobalSnackbar({
            open: true,
            message,
            ...extraProps
        });
    };

    useEffect(() => {
        if(globalSnackbar.open) {
            setTimeout(() => {
                clearSnackbar();
            }, globalSnackbar.duration || 4000);
        }
    }, [globalSnackbar.open]);

    return [globalSnackbar, showSnackbar];
};

export default useGlobalSnackbar;