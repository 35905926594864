export default {
	root: {
		height: 'calc(100vh - 186px)',
		maxWidth: '105%',
		overflowY: 'auto',
		overflowX: 'auto',
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 0fr)',
		marginTop: 20,
		marginRight: 'calc(-4% - 2px)'
	},
	loadingRoot: {
		height: 'calc(100vh - 186px)',
		maxWidth: '100%',
		overflowY: 'auto',
		overflowX: 'auto',
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 0fr)',
		opacity: 0.8,
		marginTop: 20
	},
	board: {
		display: "inline-block",
		flexDirection: "column",
		position: 'relative',
		overflow: 'visible',
		minWidth: 320,
		width: '100%',
		marginRight: 18,
	},
	list: {
		background: "white",
		paddingTop: 50,
		minWidth: 320,
		minHeight: 'calc(100% - 97px)',
		borderRadius: 6,
		marginRight: 12,
		position: 'relative',
		marginBottom: 40,
		marginTop: 0,
		overflow: 'hidden',
	},
	listName: {
		fontSize: 14,
		fontWeight: 500,
		color: 'rgba(0,0,0,0.6)',
		display: 'block !important',
		textAlign: 'left',
		paddingTop: 14,
		paddingBottom: 12,
		zIndex: 1,
		marginTop: -60,
		backgroundColor: 'white',
		'-webkitUserSelect': 'none',
		'-mozUserSelect': 'none',
		'-msUserSelect': 'none',
		userSelect: 'none',
		borderBottom: '1px solid rgba(0,0,0,0.07)'
	},
	listHeader: {
		position: 'sticky',
		width: 325,
		marginTop: 0,
		top: 0,
		background: 'white',
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		zIndex: 1,
	},
	listNumber: {
		position: 'absolute',
		fontSize: 13,
		color: 'rgba(0,0,0,0.4)',
		top: 2,
		left: 82
	},
	card: {
		userSelect: "none",
		padding: 14,
		paddingTop: 10,
		paddingLeft: 4,
		margin: "0 0 6px 0",
		minHeight: "50px",
		backgroundColor: "#F5F6F7",
		color: "rgba(0,0,0,0.7)",
		borderRadius: 6,
		cursor: "pointer !important",
		paddingLeft: 54,
		position: 'relative',
		// transition: '.4s',
		'&:hover' : {
			background: '#F1F2F3'
		},
		border: '1px solid white',
	},
	grayScaleCard: {
		userSelect: "none",
		padding: 14,
		paddingTop: 10,
		paddingLeft: 6,
		margin: "0 0 6px 0",
		minHeight: "50px",
		backgroundColor: "#F5F6F7",
		color: "rgba(0,0,0,0.7)",
		borderRadius: 6,
		cursor: "pointer !important",
		paddingLeft: 54,
		position: 'relative',
		filter: 'grayscale()',
		'&:hover' : {
			background: '#F1F2F3'
		},
		opacity: 0.8,
		border: '1px solid white'
	},
	description: {
		fontWeight: 600,
		fontSize: 13,
		margin: 0,
		marginTop: 3,
		display: 'block'
	},
	avatar: {
		width: '32px !important',
		height: '32px !important',
		fontSize: '12px !important',
		padding: '10px',
		display: 'inline-block',
		verticalAlign: 'top',
		position: 'absolute',
		left: '4px !important',
		top: 4
	},
	avatarElem: {
		width: '80%',
		height: 'calc(40% + 9px)',
		padding: '10%',
		paddingTop: 'calc(50% - 9px)',
		background: 'lightgray',
		borderadius: '50%',
		color: 'white',
		fontWeight: 600,
		fontSize: '13px',
		textAlign: 'center',
		borderRadius: 20,
		marginLeft: 0
	},
	text: {
		display: 'inline-block',
		verticalAlign: 'middle',
		maxWidth: 200,
		marginLeft: 4
	},
	date: {
		color: 'gray',
		fontSize: 11,
		marginTop: 3
	},
	amount: {
		color: '#5043dd',
		fontSize: 14,
		fontWeight: 500,
		margin: 0,
		marginBottom: 2
	},
	reimbursedBadge: {
		color: 'green',
		width: 76,
		borderRadius: 4,
		fontWeight: 600,
		opacity: 0.7,
		fontSize: 12,
		margin: 0,
		marginBottom: 12,
		padding: '2px 6px',
		paddingBottom: 3,
		border: '1px solid green'
	}
};