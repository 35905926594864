import React, { useState } from 'react';

// Styles
import styles from './createExpense.module.scss';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFile
} from '@fortawesome/free-solid-svg-icons';

// Components


const FilePreview = (props) => {
    const {
        file,
        setIsImageOpen
    } = props;

    return <>
        {
            file.substring(file.length - 4) === ".pdf" ?
                <div className={styles.pdfPreview} onClick={() => window.open(file)}>
                    <FontAwesomeIcon icon={faFile} />
                    <p className={styles.pdf}>PDF</p>
                </div> :
                <img
                    onClick={() => setIsImageOpen(true)}
                    className={styles.imagePreview}
                    src={file}
                    alt="Carga una imagen"
                />
        }
    </>
}

export default FilePreview;