import React from 'react';

// Styles
import styles from './createExpense.module.scss';

const triggerKeyEvent = (event) => {
    document.dispatchEvent(new KeyboardEvent('keyup', event));
};


const KeyboardHelper = (props) => {
    const {
        step,
        value
    } = props;

    return <>
        <div className={styles.bottomShadow}></div>
        {
            !(value === "" && step === "description") &&
            <div className={styles.helper}>
                {
                    value === '' && step === 'amount' &&
                    <p>
                        Presiona <i>Escape</i>
                        para
                        <b onClick={() => { triggerKeyEvent({ key: 'Escape' }); }} >
                            volver atrás
                        </b>
                    </p>
                }
                {
                    value !== '' && step === 'amount' &&
                    <p>
                        <i>Enter</i>
                        para
                        <b onClick={() => { triggerKeyEvent({ key: 'Enter' }); }}>
                            continuar
                        </b>
                    </p>
                }
                {
                    value && step === 'description' &&
                    <p>
                        Presiona <i>Enter</i>
                        para
                        <b onClick={() => { triggerKeyEvent({ key: 'Enter' }); }}>
                            continuar
                        </b>
                    </p>
                }
                {
                    step === 'isOwn' &&
                    <>
                        <p><i>←</i> <i>→</i> para elegir una opción</p>
                        <p>
                            <i>Enter</i>
                            para
                            <b onClick={() => { triggerKeyEvent({ key: 'Enter' }); }}>
                                continuar
                            </b>
                        </p>
                    </>
                }
                {
                    step === 'final' &&
                    <>
                        <p>
                            Presiona <i>Shift + s</i>
                            para
                            <b onClick={() => { triggerKeyEvent({ keyCode: 83, shiftKey: true }); }} >
                                guardar
                            </b>
                        </p>
                        <p>
                            Presiona <i>Shift + x</i>
                            para
                            <b onClick={() => { triggerKeyEvent({ keyCode: 88, shiftKey: true }); }} >
                                descartar cambios
                            </b>
                        </p>
                    </>
                }
            </div>
        }

    </>;
};

export default KeyboardHelper;