import React, { useState } from "react";
import Login from "./components/login/Login";
import Landing from "./components/landing/Landing";
import Dashboard from "./components/dashboard/Dashboard";
import Expenses from "./components/expensesList/Expenses";
import Members from "./components/members/Members";
import Refunds from "./components/refunds/Refunds";
import SignUp from "./components/signup/SignUp";
import Settings from "./components/expenseSettings/Settings";
import CreateCustomField from "./components/createCustomField/CreateCustomField";
import CreateExpense from './components/createExpense/CreateExpense';
import JoinOrganization from "./components/onboarding/Onboarding";
import EmailVerification from "./components/mailing/EmailVerification";
import VerifyEmail from "./components/mailing/VerifyEmail";
import Privacy from "./components/landing/Privacy";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UserContext } from "./commons/UserContext";
import useExpenseState from "./customHooks/useExpenseState";
import useDownloadState from "./customHooks/useDownloadState";
import Groups from "./components/groups/Groups";
import CreateGroup from "./components/groups/CreateGroup";
import Snackbar from "./components/generics/Snackbar";
import useGlobalSnackbar from "./customHooks/useGlobalSnackbar";
import PasswordRecovery from "./components/passwordRecovery/PasswordRecovery";
import Plans from "./components/billing/Plans";
import useGetPlans from "./components/billing/hooks/useGetPlans";
import useGetBillingInfo from "./components/billing/hooks/useGetBillingInfo";
import Billing from "./components/billing/Billing";
import Paywall from "./components/paywall/Paywall";


const App = () => {
	const [userInfo, setUserInfo] = useState({});
	const [expenseState, expenseReducer] = useExpenseState();
	const [downloadState, setDownloadState] = useDownloadState();
	const [globalSnackbar, showSnackbar] = useGlobalSnackbar();
	const [plans] = useGetPlans();
	const [billingInfo] = useGetBillingInfo();

	return (
		<main>
			<Router>
				<Switch>
					<UserContext.Provider
						value={{
							plans,
							userInfo,
							setUserInfo,
							globalSnackbar,
							showSnackbar,
							expenseState,
							expenseReducer,
							downloadState,
							setDownloadState,
							billingInfo
						}}
					>
						<Route path="/" component={Landing} exact />
						<Route path="/emailVerification" component={EmailVerification} exact />
						<Route path="/emailVerificationNeeded" component={VerifyEmail} exact />
						<Route path="/privacy" component={Privacy} exact />
						<Route path="/expenses/:id" component={Expenses} exact />
						<Route path="/expenses" component={Expenses} exact />
						<Route path="/expense" component={CreateExpense} exact />
						<Route path="/members" component={Members} exact />
						<Route path="/refunds" component={Refunds} exact />
						<Route path="/dashboard" component={Dashboard} exact />
						<Route path="/settings/:name" component={Settings} exact />
						<Route path="/createCustomField/:id" component={CreateCustomField} exact />
						<Route path="/login" component={Login} exact />
						<Route path="/signUp" component={SignUp} exact />
						<Route path="/onboarding" component={JoinOrganization} exact />
						<Route path="/groups" component={Groups} exact />
						<Route path="/createGroup/:id" component={CreateGroup} exact />
						<Route path="/passwordRecovery" component={PasswordRecovery} exact />
						<Route path="/plans" component={Plans} exact />
						<Route path="/billing" component={Billing} exact />
						<Route path="/paywall" component={Paywall} exact />
					</UserContext.Provider>
				</Switch>
			</Router>
			<Snackbar
				visible={globalSnackbar.open}
				text={globalSnackbar.message}
				variant={globalSnackbar.variant}
				actionText={globalSnackbar.actionText}
				action={globalSnackbar.action}
			/>
		</main >
	);
};

export default App;