import React, { useContext } from 'react';
import styles from './createExpense.module.scss';

// Components
import FileSelector from './FileSelector';
import ExpenseDataItem from './ExpenseDataItem';

// State
import { UserContext } from '../../commons/UserContext';

// Utils
import { formatCurrency } from "../../commons/Utils";
import { getCookie } from '../../commons/CookiesHelper';
import moment from 'moment';

// Hooks
import useOrganizationSettings from './customHooks/useOrganizationSettings';
const selectedOrganization = getCookie('orgName');




const DataItems = (props) => {
	const { expenseState, expenseReducer, userInfo } = useContext(UserContext);
	const organizationSettings = useOrganizationSettings();
	const { setIsImageOpen, toggleModal, toggleCFModal } = props;
	const { step, values, customFieldsValues, fields } = expenseState;
	const { setInputValue } = expenseReducer;

	return (
		<div className={styles.itemsContent}>
			<div className={styles.requiredContainer}>
				{
					step == 'final' && <div className={styles.animatedSpace} />
				}
				<ExpenseDataItem
					label="Descripción"
					value={values.description}
					visible={values.description}
				/>
				<ExpenseDataItem
					label="Monto"
					value={formatCurrency(values.amount)}
					visible={values.amount}
				/>
				<ExpenseDataItem
					label="Pagado por"
					value={values.isOwn ? userInfo.fullName : selectedOrganization}
					visible={step == 'final'}
				/>
				<ExpenseDataItem
					label="Fecha"
					value={moment(values.date).format('DD/MM/YYYY')}
					editable={true}
					onClick={() => toggleModal('date')}
					visible={step == 'final'}
				/>
			</div>
			{
				step == 'final' &&
				<div className={styles.optionalsContainer}>
					<ExpenseDataItem
						label="Recibo"
					>
						<FileSelector
							image={values.image}
							setImage={value => setInputValue('image', value)}
							setIsImageOpen={setIsImageOpen}
						/>
					</ExpenseDataItem>
					{
						fields?.fields?.map(field => (
							<ExpenseDataItem
								key={field.id}
								label={field.title}
								value={
									field.type !== 'select' ?
										customFieldsValues[field.id] :
										field.selectValues
											?.find(elem => elem.id === customFieldsValues[field.id])?.value
								}
								editable={true}
								onClick={() => toggleCFModal(field)}
								visible={field.visible}
							/>
						))
					}
				</div>
			}
		</div>
	);
};

export default DataItems;