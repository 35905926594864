import React, { useState, useEffect } from 'react';

// Components
import Layout from '../_shared/Layout.js';
import Categories from './Categories';
import CustomFields from './CustomFields';
import ExpenseSettings from './ExpenseSettings';
import MailSettings from './MailSettings';
import NotificationSettings from './NotificationSettings';

// Services
import {
	getOrganizationSettings,
	setOrganizationSettings,
	getNotificationsSettings,
	setNotificationsSettings
} from '../../commons/Services';

// Router
import { useParams } from "react-router-dom";

const getPageTitle = (view) => {
	switch(view) {
		case 'notification':
			return 'Configuración de notificaciones';

		case 'mail':
			return 'Configuración de mails';

		default:
			return 'Configuración de gastos';
	}
}

const Settings = () => {
	const { name } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [view, setView] = useState(name);
	const [notifications, setNotifications] = useState({
		enableDeletedExpenseNotification: false,
		enableNewExpenseNotification: false,
		enableSendEmails: false,
		subscribeToExpenseOnActivity: false
	});
	const [settings, setSettings] = useState({
		usesCategories: false,
		usesInvoiceType: false,
		hasCompletedCategories: false
	});

	useEffect(() => {
		fetchExpenseSettings();
		fetchNotificationsSettings();
	}, [name]);

	const fetchExpenseSettings = async () => {
		const settings = await getOrganizationSettings();
		if(settings.data) {
			setSettings(settings.data?.organizationSetting);
			setIsLoading(false);
		}
		setView(name);
	}

	const fetchNotificationsSettings = async () => {
		const notificationsSettings = await getNotificationsSettings();
		if(notificationsSettings.data) {
			setNotifications(notificationsSettings.data);
			setIsLoading(false);
		}
		setView(name);
	}

	const editSettings = async (key, value) => {
		setIsLoading(true);
		const newObj = {
			...settings,
			[key]: value
		};
		setSettings(newObj);
		await setOrganizationSettings(newObj);
		setIsLoading(false);
	}

	const editNotifications = async (key, value) => {
		setIsLoading(true);
		const newObj = {
			...notifications,
			[key]: value
		};
		setNotifications(newObj);
		await setNotificationsSettings(newObj);
		setIsLoading(false);
	}

	return (
		<Layout title={getPageTitle(view)}>
			{
				(view === 'expense') &&
				<ExpenseSettings
					editSettings={editSettings}
					settings={settings}
					isLoading={isLoading}
					setView={setView}
				/>
			}
			{
				view === 'mail' &&
				<MailSettings
					editSettings={editNotifications}
					settings={notifications}
					isLoading={isLoading}
					setView={setView}
				/>
			}
			{
				view === 'notification' &&
				<NotificationSettings
					editSettings={editNotifications}
					settings={notifications}
					isLoading={isLoading}
					setView={setView}
				/>
			}
			{
				view == 'categories' &&
				<Categories
					editSettings={editSettings}
					settings={settings}
					goBack={() => setView('expense')}
				/>
			}
			{
				view == 'customFields' &&
				<CustomFields
					editSettings={editSettings}
					settings={settings}
					goBack={() => setView('expense')}
				/>
			}
		</Layout>
	);
}

export default Settings;