import React from 'react';
import styles from './styles';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(styles);


const ExpensesSkeleton = () => {
    const classes = useStyles();

    return (
        <div className={classes.expensesSkeleton}>
            {
                [...Array(4)].map(
                    (e, i) => 
                    <div className={classes.itemSkeleton} key={i}>
                        <div className="avatarSkeleton pulse"></div>
                        <div className="textSkeleton pulse"></div>
                        <div className="textSkeletonSmall pulse"></div>
                    </div>
                )
            }
        </div>
    );
}

export default ExpensesSkeleton;