import React, { useEffect, useRef } from 'react';
import styles from './tooltip.module.scss';


const Tooltip = props => {
    const { setIsOpen, open, children, style } = props;
    const ref = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const handleClickOutside = (event) => {
        if(ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    return (
        <>
            {
                open ?
                    <div
                        className={styles.container}
                        style={style}
                        ref={ref}
                    >
                        {children}
                    </div> : null
            }
        </>
    );
};

export default Tooltip;