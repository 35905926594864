import React, { useEffect, useState } from 'react';
import styles from './createExpense.module.scss';
import "date-fns";
// Components
import Modal from '../generics/Modal';
// Material ui
import Button from '../generics/Button';
import {
	DatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import "moment/locale/es";
import MomentUtils from "@date-io/moment";


const DateModal = props => {
	const { handleClose, handleSelect, dateValue, open } = props;
	const [date, setDate] = useState(dateValue);
	const [isOpen, setIsOpen] = useState(false);

	const handleChange = (value) => {
		setDate(value);
		setIsOpen(false);
	};

	return (
		<Modal
			title={"Fecha"}
			isOpen={open}
			handleClose={handleClose}
			mainAction={() => {
				handleSelect(date);
				handleClose();
			}}
		>
			<div className={styles.dateModalContainer}>
				<MuiPickersUtilsProvider locale="es" libInstance={moment} utils={MomentUtils}>
					<DatePicker
						className={styles.datePicker}
						disableToolbar
						variant="inline"
						format="DD/MM/YYYY"
						margin="normal"
						id="date-picker-inline"
						open={isOpen}
						error={false}
						invalidDateMessage={false}
						onOpen={() => setIsOpen(true)}
						label=""
						value={date}
						onChange={handleChange}
						maxDate={moment()}
					/>
				</MuiPickersUtilsProvider>
				<div className={styles.modalActions}>
					<Button
						style={{ float: 'right' }}
						onClick={() => {
							handleSelect(date);
							handleClose();
						}}
					>
						Aplicar
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default DateModal;