import React, { useState, useEffect, useContext } from "react";
import styles from './styles';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Card from './Card';
import { UserContext } from "../../commons/UserContext";

// Material ui
import { makeStyles } from '@material-ui/core/styles';

// Services
import { changeBoardStatus } from '../../commons/Services';

const useStyles = makeStyles(styles);


const columnsFromBackend = items => {
    return {
        pending: {
            name: "Pendiente",
            items: items.pending?.map(expense => {
                return {
                    ...expense,
                    id: expense.id + ''
                };
            })
        },
        approved: {
            name: "Aprobado",
            items: items.approved?.map(expense => {
                return {
                    ...expense,
                    id: expense.id + ''
                };
            })
        },
        disapproved: {
            name: "Desaprobado",
            items: items.disapproved?.map(expense => {
                return {
                    ...expense,
                    id: expense.id + ''
                };
            })
        }
    };
};


const onDragEnd = async (result, columns, setColumns, setIsDragging, fetchExpenses, setIsLoading, isLanding, handleResponse) => {
    if(!result.destination) return;
    const { source, destination } = result;
    setIsDragging(false);
    if(source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        setColumns({
            ...columns,
            [source.droppableId]: {
                ...sourceColumn,
                items: sourceItems
            },
            [destination.droppableId]: {
                ...destColumn,
                items: destItems
            }
        });
        if(isLanding) return;
        setIsLoading(true);
        const response = await changeBoardStatus(
            result.draggableId,
            result.destination.droppableId,
            destination.index
        );
        handleResponse(response);
        await fetchExpenses();
        setIsLoading(false);
    } else {
        const column = columns[source.droppableId];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        setColumns({
            ...columns,
            [source.droppableId]: {
                ...column,
                items: copiedItems
            }
        });
        if(isLanding) return;
        setIsLoading(true);
        const response = await changeBoardStatus(
            result.draggableId,
            result.destination.droppableId,
            destination.index
        );
        handleResponse(response);
        await fetchExpenses();
        setIsLoading(false);
    }

};


const Board = props => {
    const [columns, setColumns] = useState([]);
    const { showSnackbar } = useContext(UserContext);
    const classes = useStyles();

    const { expenses, setSelectedExpense, setIsDragging, fetchExpenses, isFetching, setIsFetching, isLanding = false } = props;

    useEffect(() => {
        if(expenses) setColumns(columnsFromBackend(expenses));
    }, [expenses]);

    const handleResponse = (response) => {
        const message = response?.data?.message;
        if(message?.includes('reimbursed')) {
            showSnackbar("El gasto ya ha sido reintegrado");
        }
    };

    return (
        <div className="fadeInCard">
            <div className={isFetching ? classes.loadingRoot : classes.root}>
                <DragDropContext
                    onDragEnd={result => onDragEnd(result, columns, setColumns, setIsDragging, fetchExpenses, setIsFetching, isLanding, handleResponse)}
                    onDragStart={() => setIsDragging(true)}
                    style={{ marginRight: '0px !important' }}
                >
                    {Object.entries(columns).map(([columnId, column], index) => {
                        return (
                            <div
                                className={classes.board}
                                key={columnId}
                            >
                                <div className={classes.listHeader}>
                                    <h4 className={classes.listName}>{column.name}</h4>
                                    <p style={column.name === 'Desaprobado' ? { paddingLeft: 22 } : {}} className={classes.listNumber}>{column.items && column.items.length}</p>
                                </div>
                                <Droppable droppableId={columnId} key={columnId}>
                                    {(provided, snapshot) => {
                                        return (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className={classes.list}
                                            >
                                                {column.items && column.items.map((item, index) => {
                                                    return (
                                                        <Draggable
                                                            key={item.id}
                                                            draggableId={item.id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <Card
                                                                        setSelectedExpense={setSelectedExpense}
                                                                        cardClass={
                                                                            column.name == "Desaprobado" ?
                                                                                classes.grayScaleCard :
                                                                                classes.card
                                                                        }
                                                                        provided={provided}
                                                                        item={item}
                                                                    />
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        );
                                    }}
                                </Droppable>
                            </div>
                        );
                    })}
                </DragDropContext>
            </div>
        </div>
    );
};

export default Board;
