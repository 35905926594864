const styles = () => ({
    gasto: {
        paddingTop: 8,
        height: 68,
        width: '100%',
        borderBottom: '1px solid rgba(0,0,0,0.06)',
        overflow: 'hidden',
        cursor: 'pointer',
        transition: '.3s',
        '&:hover': {
            // boxShadow: '0px 0px 8px 1px rgba(230,230,230,1);',
            background: 'rgba(0,0,0,0.02)'
        }
    },
    avatar: {
        height: 40,
        width: 40,
        borderRadius: 30,
        marginTop: -17,
        marginBottom: 0,
        marginLeft: 20,
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    user: {
        display: 'block',
        verticalAlign: 'middle',
        marginTop: 13,
        fontWeight: 600,
        fontSize: 13,
        marginLeft: 16,
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        color: 'rgba(0,0,0,0.8)'
    },
    amount: {
        color: '#5043dd',
        float: 'right',
        marginRight: 20,
        fontWeight: 600,
        fontSize: 15,
        marginTop: 20,
        '@media (max-width: 600px)': {
            marginRight: 4
        }
    },
    description: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginTop: 22,
        fontSize: 14,
        fontWeight: 400,
        marginLeft: '-2%',
        color: 'rgba(0,0,0,0.4)'
    },
    date: {
        display: 'block',
        verticalAlign: 'middle',
        marginTop: -10,
        fontSize: 12,
        marginLeft: 16,
        color: 'gray'
    },
    column: {
        height: '100%',
        width: 'calc(70% - 10px)',
        maxWidth: 500,
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    smallColumn: {
        float: 'right',
        height: '100%',
        width: 'calc(30% + 10px)',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    info: {
        display: 'inline-block',
        width: 'calc(55% +  30px)'
    },
    menuIcon: {
        marginTop: 14,
        borderRadius: 22,
        padding: '9px 16px',
        marginRight: 16,
        fontSize: 16,
        transform: 'scaleY(1.1)',
        color: 'rgba(0,0,0,0.3)',
        float: 'right',
        '&:hover': {
            background: 'rgba(0,0,0,0.03)',
            color: 'gray'
        },
        '@media (max-width: 600px)': {
            marginRight: 6
        }
    },
    stateIndicator: {
        height: 5,
        width: 5,
        borderRadius: 20,
        // background: 'rgba(255,205,0,0.6)',
        display: 'inline-block',
        marginRight: 6,
        marginTop: -2,
        verticalAlign: 'middle'
    },
    state: {
        padding: '4px 12px',
        borderRadius: 16,
        verticalAlign: 'middle',
        marginLeft: 0,
        marginTop: 18,
        paddingLeft: 2,
        float: 'right',
        fontSize: 12,
        display: 'inline-block',
        width: 96,
    }
});

export default styles;