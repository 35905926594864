import React, { useState } from 'react';
import styles from './styles';
// Material ui
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';


const useStyles = makeStyles(styles);


const hoverEffect = (e, isZoomed) => {
    const element = document.getElementById("figureImage");
    const top = element.offsetTop;
    const height = element.offsetHeight / 2;
    const y = (((e.clientY - height) - top) / 6);

    if (isZoomed) {
        element.style.transform = 'translateY(-' + (y + 96) + '%) translateX(-50%) scale(1.6)';
        element.style.trasition = '0s';
    } else {
        element.style.transform = 'translateY(-50%) translateX(-50%)';
        element.style.trasition = '.2s';
    }
}


const downloadImage = (name, image) => {
    const link = document.createElement('a');
    link.href = image.replace("/upload", "/upload/fl_attachment:" + name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


const ImageViewer = props => {
    const classes = useStyles();
    const [isZoomed, setIsZoomed] = useState(false);
    const { description, imageUrl, setIsImageOpen, open = true } = props;

    return (
        <>
            {
                open === true ?
                    <div className={classes.imageViwer}>
                        <img
                            id="figureImage"
                            onClick={e => {
                                setIsZoomed(!isZoomed)
                                hoverEffect(e, !isZoomed)
                            }}
                            src={imageUrl}
                            className={isZoomed ? classes.imgBigZoomedIn : classes.imgBig}
                            onMouseMove={e => hoverEffect(e, isZoomed)}
                        />
                        {
                            !isZoomed &&
                            <>
                                <p
                                    onClick={() => setIsImageOpen(false)}
                                    className={classes.closeImageViewer}
                                >X</p>
                                <FontAwesomeIcon
                                    onClick={() => downloadImage('Xpender-' + description.replace(/[^A-Z0-9]/ig, "_"), imageUrl)}
                                    className={classes.downloadImage}
                                    icon={faArrowDown}
                                />
                            </>
                        }

                    </div>
                    : null
            }
        </>
    );
}

export default ImageViewer;