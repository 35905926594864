import React, { useContext, useEffect, useState } from 'react';
import { getCookie } from '../../commons/CookiesHelper';
import '../../App.scss';

//Material ui
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCircle } from '@fortawesome/free-solid-svg-icons';

//Components
import Navbar from './Navbar/Navbar';
import SelectOrganization from './SelectOrganization';
import FeedbackModal from './FeedbackModal';
import Snackbar from '../generics/Snackbar';

//Utils
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { setCookie } from '../../commons/CookiesHelper';
import { UserContext } from '../../commons/UserContext';
import { getUserInfo } from '../../commons/Services';
import { isMobile } from '../../commons/Utils';
import usePaywall from '../../customHooks/usePaywall';

// Icons
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
const mobile = isMobile();


const logOut = (history, setUserInfo) => {
	setCookie('accessToken', '', 0);
	setCookie('orgName', '', 0);
	setCookie('org', '', 0);
	setUserInfo({});
	history.push('/login');
};

const LeftMenu = (props) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [selectedOrgName, setSelectedOrgName] = useState(getCookie('orgName'));
	const [permissions, setPermissions] = useState(
		getCookie('permissions') || 'admin',
	);
	const [snackbar, setSnackbar] = useState(false);
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const { userInfo, setUserInfo, expenseState, plans } = useContext(UserContext);
	const location = useLocation().pathname;
	const { open } = props;
	const history = useHistory();
	usePaywall({ userInfo, location });

	useEffect(() => {
		; (async () => {
			const userObj = await getUserInfo();
			//No esta loggeado
			if(userObj.status === 401) {
				logOut(history, setUserInfo);
				return;
			}
			if(userObj.status === 403) {
				history.push('/emailVerificationNeeded');
				return;
			}
			//Esta loggeado
			if(userObj.data) {
				setUserInfo(userObj.data);
				userObj.data && checkOrganization(userObj.data);
			}
		})();
	}, []);

	const checkOrganization = (user) => {
		if(user.organizations.length === 0) {
			// No tiene org
			history.push('/onboarding');
			return;
		}
		if(!selectedOrgName) {
			// No tiene org seleccionada
			setCookie('org', user.organizations[0].id, 5);
			setCookie('orgName', user.organizations[0].name, 5);
			setSelectedOrgName(user.organizations[0].name);
			document.location.reload();
		}
		const org = user.organizations.find(
			(elem) => elem.id.toString() === getCookie('org'),
		);
		if(org.role === 'employee') {
			setCookie('expensesLayout', 'list');
			setCookie('permissions', 'basic');
			setCookie('isOwner', false);
			setCookie('role', 'employee');
			setPermissions('basic');
		} else if(org.role === 'owner') {
			setCookie('permissions', 'admin');
			setCookie('isOwner', true);
			setPermissions('admin');
			setCookie('role', 'owner');
		} else {
			setCookie('isOwner', false);
			setCookie('permissions', 'admin');
			setPermissions('admin');
			setCookie('role', 'admin');
		}
	};

	const showSnackbar = () => {
		setSnackbar(true);
		setTimeout(() => {
			setSnackbar(false);
		}, 2500);
	};

	const currentOrg = userInfo && userInfo.organizations?.find(org => org.id.toString() === getCookie('org'));
	const billStatus = currentOrg && currentOrg.billStatus;
	const isUpToDate = billStatus?.upToDate && billStatus.planId > 1;

	return (
		<div>
			<Navbar />
			<div className={open ? 'leftMenu' : 'leftMenuClosed'}>
				{userInfo && (
					<>
						<div
							className="organizationSelector"
							onClick={() => setIsDropdownOpen(true)}
						>
							<div className="organizationAvatar">
								{selectedOrgName.substring(0, 1)}
							</div>
							<p>{selectedOrgName}</p>
						</div>
						{isDropdownOpen && (
							<SelectOrganization setIsDropdownOpen={setIsDropdownOpen} />
						)}
						<ul>
							{permissions === 'admin' && (
								<NavLink to="/dashboard" activeClassName="selectedMenuItem">
									<svg
										style={{ marginTop: -1 }}
										xmlns="http://www.w3.org/2000/svg"
										width="44"
										height="44"
										viewBox="0 0 24 24"
										strokeWidth="1.6"
										stroke="#2a2651"
										fill="none"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<path stroke="none" d="M0 0h24v24H0z" fill="none" />
										<polyline points="13 3 13 10 19 10 11 21 11 14 5 14 13 3" />
									</svg>
									<li>Resumen </li>
								</NavLink>
							)}
							<NavLink to="/expenses" activeClassName="selectedMenuItem">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="44"
									height="44"
									viewBox="0 0 24 24"
									strokeWidth="1.6"
									stroke="#2a2651"
									fill="none"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<path stroke="none" d="M0 0h24v24H0z" fill="none" />
									<line x1="9" y1="6" x2="20" y2="6" />
									<line x1="9" y1="12" x2="20" y2="12" />
									<line x1="9" y1="18" x2="20" y2="18" />
									<line x1="5" y1="6" x2="5" y2="6.01" />
									<line x1="5" y1="12" x2="5" y2="12.01" />
									<line x1="5" y1="18" x2="5" y2="18.01" />
								</svg>
								<li>Lista de gastos </li>
							</NavLink>
							<NavLink to="/expense" activeClassName="selectedMenuItem">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="44"
									height="44"
									viewBox="0 0 24 24"
									strokeWidth="1.6"
									stroke="#2a2651"
									fill="none"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<path stroke="none" d="M0 0h24v24H0z" fill="none" />
									<line x1="12" y1="5" x2="12" y2="19" />
									<line x1="5" y1="12" x2="19" y2="12" />
								</svg>
								<li>Nuevo gasto </li>
								{expenseState?.values?.description &&
									location !== '/expense' && (
										<FontAwesomeIcon
											className="unsavedChanges"
											icon={faCircle}
										/>
									)}
							</NavLink>
							{permissions === 'admin' && (
								<>
									{!mobile && (
										<NavLink to="/refunds" activeClassName="selectedMenuItem">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="44"
												height="44"
												viewBox="0 0 24 24"
												strokeWidth="1.6"
												stroke="#2a2651"
												strokeLinecap="round"
												strokeLinejoin="round"
											>
												<path stroke="none" d="M0 0h24v24H0z" fill="none" />
												<polyline points="15 4 19 4 19 8" />
												<line x1="14.75" y1="9.25" x2="19" y2="4" />
												<line x1="5" y1="19" x2="9" y2="15" />
												<polyline points="15 19 19 19 19 15" />
												<line x1="5" y1="5" x2="19" y2="19" />
											</svg>
											<li>Reintegros </li>
										</NavLink>
									)}
									<NavLink to="/members" activeClassName="selectedMenuItem">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="44"
											height="44"
											viewBox="0 0 24 24"
											strokeWidth="1.6"
											stroke="#2a2651"
											fill="none"
											strokeLinecap="round"
											strokeLinejoin="round"
										>
											<path stroke="none" d="M0 0h24v24H0z" fill="none" />
											<circle cx="12" cy="7" r="4" />
											<path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
										</svg>
										<li>Miembros </li>
									</NavLink>
								</>
							)}
						</ul>
						{
							getCookie('isOwner') === 'true' &&
							<div className="plansButton" onClick={() => history.push(isUpToDate ? '/billing' : '/plans')}>
								<div to="/members" className="helpButton">
									<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
										<path stroke="none" d="M0 0h24v24H0z" fill="none" />
										<circle cx="12" cy="12" r="9" />
										<line x1="12" y1="8" x2="8" y2="12" />
										<line x1="12" y1="8" x2="12" y2="16" />
										<line x1="16" y1="12" x2="12" y2="8" />
									</svg>
									{
										isUpToDate ?
											'Ver suscripción' :
											'Ver planes'
									}
								</div>
							</div>
						}

						<div className="feedbackButton" onClick={() => setShowFeedbackModal(true)}>
							<div className="helpButton">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="44"
									height="64"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="#2c3e50"
									fill="none"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<path stroke="none" d="M0 0h24v24H0z" fill="none" />
									<path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
									<line x1="8" y1="9" x2="16" y2="9" />
									<line x1="8" y1="13" x2="14" y2="13" />
								</svg>
								Ayuda y comentarios
							</div>
						</div>
					</>
				)}
			</div>
			<FeedbackModal
				open={showFeedbackModal}
				showSnackbar={showSnackbar}
				setOpen={setShowFeedbackModal}
			/>
			<Snackbar
				visible={snackbar}
				text={"Enviado"}
				icon={faCheckCircle}
				iconColor={'rgba(0,250,200,0.9)'}
			/>
		</div>
	);
};

export default LeftMenu;
