import React, { useContext, useEffect, useRef } from 'react';

// Utils
import { UserContext } from '../../commons/UserContext';
import { setCookie } from '../../commons/CookiesHelper';
import { useHistory } from 'react-router-dom';


const SelectOrganization = props => {
    const { userInfo } = useContext(UserContext);
    const { show, setIsDropdownOpen } = props;
    const history = useHistory();
    const ref = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, []);

    const goToOnboarding = () => {
        history.push('/onboarding');
    }

    const handleClickOutside = (event) => {
        if(ref.current && !ref.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    const selectOrganization = org => {
        setCookie('org', org.id, 30 * 24);
        setCookie('orgName', org.name, 30 * 24);
        document.location.reload();
    }


    return (
        <>
            <div className="organizationSelect" ref={ref} >
                <p className="selectTitle">Cambiar a ...</p>
                {
                    userInfo.organizations && userInfo.organizations.map((elem, key) => {
                        return (
                            <p
                                key={key}
                                className="selectItem"
                                onClick={() => selectOrganization(elem)}
                            >
                                {elem.name}
                            </p>
                        );
                    })
                }
                <p className="selectButton" onClick={goToOnboarding}>Agregar organización</p>
            </div>
        </>
    );
}

export default SelectOrganization;