import React, { useState, useContext, useEffect } from 'react';

//Components
import Avatar from "../Avatar";
import SettingsPanel from './SettingsPanel';
import UserPanel from './UserPanel';
import NotificationsPanel from './NotificationsPanel';

// Utils
import { UserContext } from '../../../commons/UserContext';
import { getCookie, setCookie } from '../../../commons/CookiesHelper';

// Services
import { getNotifications } from '../../../commons/Services';


const Navbar = props => {
    const [isSettingsPanelOpen, setSettingsPanelOpen] = useState(false);
    const [isUserPanelOpen, setUserPanelOpen] = useState(false);
    const [isNotificationsPanelOpen, setNotificationsPanelOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const { userInfo } = useContext(UserContext);
    const permissions = getCookie('permissions') || 'basic';

    useEffect(() => {
        fetchNotifications();
    }, []);
    
    const fetchNotifications = async () => {
        const res = await getNotifications();
        setNotifications(res?.data?.data);
        const hasUnread = 
            res?.data?.data?.filter(n => !n.read).length > 0;
        setCookie('hasUnread', hasUnread);
    }

    const showSettingsPanel = () => {
        if(!isSettingsPanelOpen) {
            setSettingsPanelOpen(true);
        }
    }

    const showNotificationsPanel = () => {
        if(!isNotificationsPanelOpen) {
            setNotificationsPanelOpen(true);
        }
    }

    const showUserPanel = () => {
        if(!isUserPanelOpen) {
            setUserPanelOpen(true);
        }
    }

    return (
        <div id="navbar">
            <div className="navbarContainer">
                {
                    permissions === 'admin' &&
                    <svg onClick={showSettingsPanel} className="settingsButton" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2a2651" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                        <circle cx="12" cy="12" r="3" />
                    </svg>
                }
                <svg onClick={showNotificationsPanel} xmlns="http://www.w3.org/2000/svg" className="notificationsButton" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                    <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                </svg>
                {
                    getCookie('hasUnread') === 'true' &&
                    <div className="notificationsBadge"></div>
                }
                
                <div className="userButton" onClick={showUserPanel}>
                    <Avatar userInfo={userInfo} />
                </div>
                {
                    isSettingsPanelOpen &&
                    <SettingsPanel setIsOpen={setSettingsPanelOpen} />
                }
                {
                    isUserPanelOpen &&
                    <UserPanel setIsOpen={setUserPanelOpen} userInfo={userInfo} />
                }
                {
                    isNotificationsPanelOpen &&
                    <NotificationsPanel
                        setIsOpen={setNotificationsPanelOpen} 
                        notifications={notifications}
                        fetchNotifications={fetchNotifications}
                    />
                }
            </div>
        </div>
    );
}

export default Navbar;