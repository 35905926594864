import React, { useContext, useState } from 'react';
import styles from './mailing.module.scss';
import Button from '../generics/Button'
import { logOut } from '../../commons/Authentication';
import { getCookie } from '../../commons/CookiesHelper';

// State
import { UserContext } from '../../commons/UserContext';
import { useHistory } from 'react-router-dom';

// Services
import { sendEmailVerification } from '../../commons/Services';


const EmailVerification = () => {
    const email = getCookie('userEmail');
    const { setUserInfo } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [emailState, setEmailState] = useState('pending');
    const history = useHistory();

    const handleResend = async () => {
        setIsLoading(true);
        const res = await sendEmailVerification();
        if (res.status === 200) {
            setEmailState('sent');
        } else {
            setEmailState('error');
        }
    }

    return (
        <div className={styles.verifyEmailPage}>
            {
                emailState === 'pending' &&
                <>
                    <h1 className={styles.title}>Verificar email</h1>
                    <p className={styles.verificationText}>Enviamos un mail de verificación a <b>{email}</b></p>
                    <div className={styles.actionsDiv}>
                        <Button disabled={isLoading} onClick={handleResend}>
                            {
                                isLoading ?
                                    'Enviando email...' :
                                    'Reenviar email'
                            }
                        </Button>
                        <div className={styles.verifiedButton} onClick={() => { logOut(history, setUserInfo) }} >
                            Usar otro mail
                        </div>
                    </div>
                </>
            }
            {
                emailState === 'error' &&
                <>
                    <h1 style={{textAlign: "center"}}>Hubo un error al intentar reenviar el email</h1>
                    <p className={styles.verificationText}>Por favor contáctanos a <b>soporte@expendr.com</b></p>
                </>
            }
            {
                emailState === 'sent' &&
                <>
                    <h1 style={{textAlign: "center"}}>Email reenviado</h1>
                    <p className={styles.verificationText}>Revisa tu bandeja de entrada</p>
                </>
            }s
        </div>
    );
}

export default EmailVerification;