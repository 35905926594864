import React from 'react';
import styles from './plans.module.scss';
import plansList from './plans.json';
import Plan from './Plan';

//Components
import Layout from '../_shared/Layout';
import useGetPlans from './hooks/useGetPlans';

const Plans = () => {
	const [plans, createCheckout] = useGetPlans();

	const mockedPlans = plansList.plans;

	return (
		<Layout title={"Planes"}>
			<div className={styles.plans}>
				{
					plans && [...Array(3)].map((_plan, index) => {
						return <Plan
							key={index}
							currentPlan={plans[index]}
							plans={plans}
							index={index}
							mockedPlan={mockedPlans[index]}
							createCheckout={createCheckout}
						/>;
					})
				}
				{
					!plans && [...Array(3)].map(
						(e, i) =>
							<div className={styles.planSkeleton} key={i}>
								<div className="pulse planSkeleton"></div>
							</div>
					)
				}
			</div>
		</Layout>
	);
};

export default Plans;