import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './smallButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const useStyles = makeStyles(styles);

const Button = props => {
    const { onClick, children, icon, disabled, type } = props;

    const handleClick = () => {
        if(!disabled && onClick) {
            onClick();
        }
    };

    return (
        <div className={disabled ? styles.disabledButton : styles.button} onClick={handleClick}>
            {
                icon &&
                <FontAwesomeIcon icon={icon} className={styles.buttonIcon} />
            }
            {children}
        </div>
    );
};

export default Button;
