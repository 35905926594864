import { formatCurrency } from '../../commons/Utils.js';
  

const customizeChart = (tooltipModel, that) => {
    // Tooltip Element
    const card = document.getElementById('chart-card');
    var tooltipEl = document.getElementById('chartjs-tooltip');
    if(tooltipModel.body) {
        var label = document.getElementById('chart-label');
        var value = document.getElementById('chart-value');
        const price = formatCurrency(tooltipModel.body[0].lines[0]);
        label.innerText = tooltipModel.title[0];
        value.innerText = price;
    }
    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        card.appendChild(tooltipEl);
    }
    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }
    // Set Text
    if (tooltipModel.body) {
        var innerHtml = '<thead>';
        innerHtml += '</thead><tbody>';
        innerHtml += '</tbody>';
        var tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
    }
    // `this` will be the overall tooltip
    var position = that._chart.canvas.getBoundingClientRect();
    var circle = document.getElementById('chartjs-tooltip-circle');
    // Create element on first render
    if (!circle) {
        circle = document.createElement('div');
        circle.id = 'chartjs-tooltip-circle';
        circle.innerHTML = '<table></table>';
        card.appendChild(circle);
    }
    // Styles
    circle.style.opacity = 1;
    circle.style.width = '7px';
    circle.style.height = '7px';
    circle.style.background = '#5043dd';
    circle.style.position = 'absolute';
    circle.style.transition = '.2s';
    circle.style.borderRadius = '6px';
    circle.style.left = tooltipModel.caretX + 24 + 'px';
    circle.style.top = tooltipModel.caretY + 96 + 'px';
    // Styles
    tooltipEl.style.opacity = 0.3;
    tooltipEl.style.width = '1px';
    tooltipEl.style.height = '172px';
    tooltipEl.style.transition = '.2s';
    tooltipEl.style.background = '#5043dd';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = tooltipModel.caretX + 27 + 'px';
    tooltipEl.style.top = '104px';
    tooltipEl.style.pointerEvents = 'none';
    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        circle.style.opacity = 0;
        return;
    }
}

const  moneyMask = number => {
    return number;
}

export default customizeChart;