import React, { useState } from 'react';
import styles from './styles';
// Material ui
import { makeStyles } from '@material-ui/core/styles';

// Hooks
import useModalKeys from './customHooks/useModalKeys';

const useStyles = makeStyles(styles);


const Dialog = props => {
	const classes = useStyles();
	const { handleClose, title, children, isOpen, mainAction } = props;
	const [isMounted, setIsMounted] = useState(true);
	useModalKeys(handleClose, mainAction, isOpen);

	const closeDialog = () => {
		setIsMounted(false);
		setTimeout(() => {
			setIsMounted(true);
			handleClose();
		}, 150);
	}

	return (
		<>
			{
				isOpen &&
				<div className={isMounted ? classes.backdrop : classes.unmountedBackdrop}>
					<div className={classes.columnContainer}>
						<div className={isMounted ? classes.dialog : classes.unmountedDialog}>
							<div className={classes.dialogHeader}>
								<h2 className={classes.dialogTitle}>{title}</h2>
								<div className={classes.dialogCerrarBtn} onClick={closeDialog}>
									<p className={classes.closeIcon}>X</p>
								</div>
							</div>
							{children}
						</div>
					</div>
				</div>
			}
		</>
	);
}

export default Dialog;