import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Components
import CreateOrganization from './CreateOrganization.js';
import JoinOrganization from './JoinOrganization.js';

// Utils
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../commons/UserContext';
import { getUserInfo } from "../../commons/Services";
import { logOut } from '../../commons/Authentication';


const useStyles = makeStyles({
    app: {
        height: '90vh',
        width: '100vw',
        overflow: 'hidden',
        margin: 0
    },
    options: {
        border: '1px solid rgba(0,0,0,0.06)',
        borderRadius: 6,
        margin: '3%',
        height: 300,
        width: 'calc(43%)',
        minWidth: 200,
        display: 'inline-block',
        verticalAlign: 'middle',
        '@media (max-width: 600px)': {
            width: '90%',
            margin: 'auto',
            display: 'block',
            marginBottom: 20,
            transform: 'scale(0.9)',
            transformOrigin: 'top'
        },
        textAlign: 'center',
        cursor: 'pointer',
        transition: '.3s',
        color: '#5043dd',
        fontWeight: 500,
        '&:hover': {
            borderColor: 'white',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.02), 0 5px 42px -8px rgba(0, 0, 0, 0.15)'
        },
        animationName: '$fadein',
        animationDuration: '.5s'
    },
    '@keyframes fadein': {
		from: { 
            opacity: 0,
            transform: 'translateX(50px)'
        },
		to: { 
            opacity: 1,
            transform: 'translateX(0px)'
        }
	},
    root: {
        width: 800,
        margin: 'auto',
        maxWidth: '90%',
    },
    headline: {
        color: '#252793',
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 500,
        marginTop: '15vh',
        '@media (max-width: 800px)': {
            marginTop: 50,
            marginBottom: 50
        }
    },
    image: {
        margin: 'auto',
        height: 220,
        marginTop: 10,
        marginBottom: 0,
        display: 'block'
    },
    image2: {
        margin: 'auto',
        marginTop: 45,
        marginBottom: 35,
        height: 150,
        display: 'block'
    },
});


const Onboarding = () => {
    const { userInfo, setUserInfo } = useContext(UserContext);
    const history = useHistory();
    const classes = useStyles();
    const [step, setStep] = useState(0);

    useEffect(() => {
        (async () => {
            const userObj = await getUserInfo();

            if(userObj.status === 401) {
                logOut(history, setUserInfo);
            }
            
            setUserInfo(userObj.data);
        })();
    }, []);


    return (
        <>
            {
                step == 0 &&
                <div className={classes.app}>
                    <div className={classes.root}>
                        <p className={classes.headline}>¿Qué quieres hacer?</p>
                        <div className={classes.options} onClick={() => setStep(1)}>
                            <img className={classes.image} src="https://iili.io/dE4fjV.png" />
                            Crear una organización
                        </div>
                        <div className={classes.options} onClick={() => setStep(2)}>
                            <img className={classes.image2} src="https://iili.io/dE4qZB.png" />
                            Unirme a una existente
                        </div>
                    </div>
                </div>
            }
            {
                step == 1 &&
                <CreateOrganization handleBack={() => { setStep(0) }} />
            }
            {
                step == 2 &&
                <JoinOrganization handleBack={() => { setStep(0) }} />
            }
            {
                userInfo?.name &&
                <p className="onboardingUser">{`Estas loggeado como ${userInfo.name} - `}
                    <span onClick={() => logOut(history, setUserInfo)}>Salir</span>
                </p>
            }
        </>
    );
}

export default Onboarding;