import React from 'react';
import styles from './refunds.module.scss';

// Components:
import Avatar from '../_shared/Avatar';


const UserItem = props => {
	const {
		userExpenses,
		selectedUser,
		selectUser
	} = props;


	return (
		<div
			key={userExpenses.id}
			className={userExpenses.id == selectedUser?.id ? styles.selectedMember : styles.member}
			onClick={() => selectUser(userExpenses)}
		>
			<div className={styles.avatar}>
				<Avatar userInfo={userExpenses} />
			</div>
			<div className={styles.memberInfo}>
				<p className={styles.username}>{userExpenses.fullName}</p>
				<p className={styles.expensesAmount}>
					{
						userExpenses.expenseToBeReimbursed.length == 1 ?
							'1 gasto a reintegrar' :
							userExpenses.expenseToBeReimbursed.length + ' gastos a reintegrar'
					}
				</p>
			</div>
		</div>
	);
}

export default UserItem;