import React, { useEffect, useState } from 'react';
import styles from './snackbar.module.scss';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Snackbar = props => {
    const { visible, icon, iconColor, text, action, actionText } = props;
    return (
        <div className={visible ? styles.container : styles.containerHidden}>
            {icon && <FontAwesomeIcon style={{ color: iconColor }} icon={icon} />}
            {text}
            {
                action && actionText &&
                <button className={styles.action} onClick={action}>{actionText}</button>
            }
        </div>
    );
}

export default Snackbar;