import { useEffect } from 'react';
import { useParams } from "react-router-dom";

export const useOpenExpensesModal = setSelectedExpense => {
  const { id } = useParams();

  useEffect(() => {
    if(id) setSelectedExpense(id);
  }, []);
}
