import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import DateModal from './DateModal';
import { setCookie, getCookie } from '../../commons/CookiesHelper';

const BootstrapInput = withStyles((theme) => ({
    root: {
        marginRight: 0,
        width: 153,
        marginTop: 2,
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        border: 'none',
        fontSize: 13,
        padding: '10px 26px 10px 12px',
        fontFamily: 'Montserrat',
        '&:focus': {
            borderRadius: 4,
            outline: 'none',
            backgroundColor: 'white'
        },
    },
}))(InputBase);

const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontFamily: 'Montserrat',
        fontSize: 14,
        padding: 10
    },
}))(MenuItem);

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left"
        }}
        getContentAnchorEl={null}
        {...props}
    />
));

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        borderColor: 'white'
    },
    select: {
        borderColor: 'white'
    },
    layoutButton: {
        height: 44,
        width: 153,
        borderRadius: 6,
        position: 'relative',
        fontSize: '14px',
        border: '1px solid rgba(0,0,0,0.06)',
        background: 'white',
        display: 'inline',
        verticalAlign: 'middle',
        float: 'right',
        // marginRight: 20,
        whiteSpace: 'nowrap'
    },
}));

const DateInput = props => {
    const [value, setValue] = React.useState(getCookie('dateRange') || '30d');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const classes = useStyles();
    const { setDates } = props;
    const handleChange = (event) => {
        if (event.target.value === 'personalized') {
            setIsModalOpen(true);
            setValue(event.target.value);
            setCookie('dateRange', event.target.value);
            return;
        }
        setCookie('dateRange', event.target.value);
        setValue(event.target.value);
        setDates(event.target.value);
    };

    return (
        <div className={classes.layoutButton}>
            <FormControl variant="outlined" className={classes.formControl}>
                <Select
                    value={value}
                    onChange={handleChange}
                    className={classes.select}
                    input={<BootstrapInput />}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                >
                    <StyledMenuItem value={'30d'}>Últimos 30 días</StyledMenuItem>
                    <StyledMenuItem value={'60d'}>Últimos 60 días</StyledMenuItem>
                    <StyledMenuItem value={'thisMonth'}>Este mes</StyledMenuItem>
                    <StyledMenuItem value={'lastMonth'}>El mes pasado</StyledMenuItem>
                    <StyledMenuItem value={'personalized'}>Personalizado</StyledMenuItem>
                </Select>
            </FormControl>
            {
                isModalOpen &&
                <DateModal 
                    handleClose={() => setIsModalOpen(false)}
                    handleSelect={setDates}
                />
            }
        </div>
    );
}

export default DateInput;