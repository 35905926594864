import { useState, useEffect } from 'react';
import moment from 'moment';

// Services
import { createExpense, getFields } from '../commons/Services';

// Utils
import { getApiFriendlyDate } from "../commons/Utils";

const snackbarValues = {
    success: {
        text: "Gasto creado con éxito",
        visible: true,
        success: true
    },
    successHidden: {
        text: "Gasto creado con éxito",
        success: true,
        visible: false
    },
    fail: {
        text: "Error al subir el gasto, inténtalo de nuevo",
        visible: true
    },
    failHidden: {
        text: "Error al subir el gasto, inténtalo de nuevo",
        visible: false
    },
};

const initialValues = {
    description: '',
    amount: '',
    isOwn: true,
    image: null,
    date: new Date(),
    category: { name: 'General', id: 1 }
};

const postExpense = async (values, handleResponse, customFields) => {
    const payload = {
        description: values.description,
        amountOwn: values.isOwn ? values.amount : 0,
        amountCompany: !values.isOwn ? values.amount : 0,
        date: getApiFriendlyDate(values.date),
        attachments: values.image ? [{ url: values.image }] : null,
        invoiceTypeId: null,
        customFields
    };

    const res = await createExpense(payload);
    handleResponse(res);
};

const useExpenseState = () => {
    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [placeholder, setPlaceholder] = useState('Ingresa una descripción...');
    const [step, setStep] = useState('description');
    const [breadcrumbs, setBreadcrumbs] = useState('');
    const [values, setValues] = useState(initialValues);
    const [customFieldsValues, setCustomFieldsValues] = useState({});
    const [allowKeys, setAllowKeys] = useState(null);
    const [fields, setFields] = useState(null);

    const resetState = () => {
        setValues(initialValues);
        setPlaceholder('Ingresa una descripción...');
        setValue('');
        setStep('description');
        setBreadcrumbs('');
        setIsLoading(false);
        setTimeout(() => {
            setSnackbar(snackbarValues.successHidden);
        }, 2000);
    };

    const fetchFields = async () => {
        const res = await getFields();
        if(res?.status === 200) {
            setFields(res.data);
        }
    };

    useEffect(() => {
        fetchFields();
    }, []);

    useEffect(() => {
        document.addEventListener('keyup', handleKeyUp);
        return () => document.removeEventListener('keyup', handleKeyUp);
    }, [value, step, placeholder, values, allowKeys, isLoading, customFieldsValues]);

    const handleResponse = (res) => {
        if(res.id) {
            setSnackbar(snackbarValues.success);
            setTimeout(() => {
                resetState();
            }, 500);
        } else {
            setSnackbar(snackbarValues.fail);
            setTimeout(() => {
                setSnackbar(snackbarValues.failHidden);
            }, 2500);
            setIsLoading(false);
        }
    };

    const getCustomFieldValues = () => {
        return fields?.fields
            ?.filter(field => customFieldsValues[field.id])
            .map(field => {
                const payload = { id: field.id };
                if(field.type === 'select') {
                    payload.selectValueId = customFieldsValues[field.id];
                } else {
                    payload.selectedValue = customFieldsValues[field.id];
                }
                return payload;
            });
    };

    const handleKeyUp = (e) => {
        if(!allowKeys || isLoading) return;
        if(e.key === 'Enter') {
            if(value === '') return;
            nextStep();
        }
        if(e.key === 'Escape') {
            prevStep();
        }
        if(e.keyCode === 37) { // Arrow left
            if(step !== 'isOwn') return;
            setValue(true);
        }
        if(e.keyCode === 39) { // Arrow right
            if(step !== 'isOwn') return;
            setValue(false);
        }
        if(e.shiftKey && e.keyCode === 83) { // Shift + S
            if(step !== 'final') return;
            setIsLoading(true);
            postExpense(values, handleResponse, getCustomFieldValues());
            return false;
        }
        if(e.shiftKey && e.keyCode === 88) { // Shift + X
            resetState();
            return false;
        }
    };

    const changeState = (field, value) => {
        switch(field) {
            case 'value':
                setValue(value);
                break;

            default:
                break;
        }
    };

    const setInputValue = (key, value) => {
        setValues({ ...values, [key]: value });
    };

    const setCFValue = (key, value) => {
        setCustomFieldsValues({ ...customFieldsValues, [key]: value });
    };

    const nextStep = () => {
        setInputValue(step, value);

        switch(step) {
            case 'description':
                setPlaceholder('$0,00');
                setStep('amount');
                setBreadcrumbs('/ Monto');
                setValue('');
                break;

            case 'amount':
                setPlaceholder('Seleccionar archivo');
                setStep('isOwn');
                setBreadcrumbs('/ Pagado por');
                setValue(true);
                break;

            case 'isOwn':
                setStep('final');
                setBreadcrumbs('/ Datos opcionales');
                break;

            default:
                break;
        }

    };

    const prevStep = () => {
        switch(step) {
            case 'description':
                setValue('');
                break;

            case 'amount':
                setPlaceholder('Ingresa una descripción');
                setValue(values.description);
                setInputValue('description', '');
                setStep('description');
                setBreadcrumbs('/ Descripción');
                break;

            case 'isOwn':
                setPlaceholder('$0,00');
                setValue(values.amount);
                setInputValue('amount', '');
                setBreadcrumbs('/ Monto');
                setStep('amount');
                break;

            case 'final':
                setPlaceholder('$0,00');
                setInputValue('isOwn', '');
                setBreadcrumbs('/ Pagado por');
                setStep('isOwn');
                break;

            default:
                break;
        }

    };

    return [
        {
            value,
            values,
            fields,
            step,
            placeholder,
            snackbar,
            isLoading,
            breadcrumbs,
            customFieldsValues
        },
        {
            setInputValue,
            setAllowKeys,
            changeState,
            fetchFields,
            setCFValue,
            nextStep,
            prevStep
        }
    ];
};

export default useExpenseState;