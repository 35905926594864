import React, { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { login } from '../../commons/Services';
import { useHistory, Link } from "react-router-dom";
import { setCookie, getCookie } from '../../commons/CookiesHelper';
import CustomFieldModal from '../expenseDetail/CustomFieldModal';
import { validateEmail } from '../../commons/Utils';
import { UserContext } from '../../commons/UserContext';
import { requestPasswordChange } from '../../commons/Services';

const showErrorMessage = string => {
    const errorElement = document.getElementById("loginHeadline");
    errorElement.textContent = string;
    errorElement.style.color = "#7f51b5";
    errorElement.style.transform = "scale(0.8)";
};

const handleResponse = async (res, history, setEmail, setPassword, setIsLoading) => {
    if(res.data) {
        setCookie('accessToken', res.data.accessToken, 8);
        history.push('/dashboard');
        return;
    }
    if(res !== 200) {
        if(res.status === 403) {
            history.push('/emailVerificationNeeded');
            return;
        }
        setIsLoading(false);
        setPassword('');
        showErrorMessage("Email o contraseña incorrecta");
    }
};


const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const history = useHistory();
    const { showSnackbar } = useContext(UserContext);

    useEffect(() => {
        const cookie = getCookie('accessToken');
        if(cookie != "") {
            history.push('/dashboard');
        }
    }, []);

    const handleKeyDown = (event) => {
        if(event.key === 'Enter') {
            if(!isModalOpen)
                sendRequest();
        }
    };

    const handleRequestPasswordChange = (_type, email) => {
        setDisableButton(true);
        setTimeout(async () => {
            if(validateEmail(email)) {
                const res = await requestPasswordChange(email);
                if(res.status === 401) {
                    showSnackbar("Error al enviar mail");
                    setDisableButton(false);
                    return;
                }
                showSnackbar("Email enviado, revisa tu bandeja de correo");
                setIsModalOpen(false);
                setDisableButton(false);
                return;
            }
            setDisableButton(false);
            showSnackbar("Email inválido");
        }, 800);
    };

    const sendRequest = async () => {
        setCookie('userEmail', email, 8);
        setIsLoading(true);
        const response = await login(email, password);
        handleResponse(response, history, setEmail, setPassword, setIsLoading);
    };

    return (
        <div className="App loginApp" onKeyDown={handleKeyDown}>
            <div className="loginContainer">
                <div className="loginForm">
                    <h1 className="logoLogin">expendr</h1>
                    <h3 id="loginHeadline">Ingresar a tu cuenta</h3>
                    <TextField
                        className="loginInput"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        label="Email"
                        variant="outlined"
                    />
                    <TextField
                        className="loginInput"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        label="Password"
                        variant="outlined"
                        type="password"
                    />
                    <a onClick={() => setIsModalOpen(true)} className="loginLink">Olvidaste tu contraseña?</a>
                    <Button
                        className={isLoading ? "disabledLoginButton" : "loginButton"}
                        variant="contained"
                        color="primary"
                        onClick={() => { if(!isLoading) sendRequest(); }}
                        id="login-button"
                    >
                        {isLoading ? 'Ingresando...' : 'Ingresar'}
                    </Button>

                </div>
                <p className="loginText">
                    Todavía no tienes una cuenta?
                    <Link className="loginRegisterLink" to="/signUp"> Registrarse</Link>
                </p>
            </div>
            <CustomFieldModal
                title="Cambiar contraseña"
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                changeAndSave={handleRequestPasswordChange}
                customField={null}
                type="customText"
                label="Email"
                skipClose={true}
                disableButton={disableButton}
                buttonLabel={'Enviar'}
            />
        </div>
    );
};

export default Login;