import React, { useState, useEffect } from 'react';
import styles from './styles';

//Components
import Layout from '../_shared/Layout';
import Filters from './FiltersDiv';
import ExpensesSkeleton from '../expensesList/ExpensesSkeleton';
import MemberItem from './MemberItem';
import Button from '../generics/Button';
import InviteModal from './InviteModal';
import UserProfile from '../userProfile/UserProfile';

//Material ui
import { makeStyles } from '@material-ui/core/styles';

//Services
import { getMembers } from '../../commons/Services';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';


const useStyles = makeStyles(styles);


const Members = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [search, setSearch] = useState('');
	const [members, setMembers] = useState([]);
	const [openMember, setOpenMember] = useState(null);
	const classes = useStyles();

	const setSearchClean = str => {
		setSearch(str.replace(/\\/g, ""));
	};

	const fetchMembers = async () => {
		const m = await getMembers();
			if(m.users?.length)
				setMembers(m.users);
	}

	useEffect(() => {
		fetchMembers();
	}, []);

	const compareSearch = userObj => {
		const re = new RegExp(search.toLowerCase(), 'g');
		if(userObj.fullName.toLowerCase().match(re)) {
			return true;
		}
		if(search.length < 2) return true;
	};


	return (
		<Layout title={openMember ? "Perfil de usuario" : "Miembros"}>
			{
				openMember ?
					<UserProfile 
						currentMember={openMember} 
						members={members} 
						goBack={() => setOpenMember(null )}
						fetchMembers={fetchMembers}
					/> :
					<>
						<Filters
							search={search}
							setSearch={setSearchClean}
							isSearching={isSearching}
							setIsSearching={setIsSearching}
						>
							<Button onClick={() => setIsModalOpen(true)} icon={faUserPlus}>Invitar miembros</Button>
						</Filters>
						<div className="tabsGastos">
							<div className={classes.membersTable}>
								{
									isSearching ?
										members.filter(compareSearch).map((member, key) => {
											return <MemberItem
												key={key}
												member={member}
												setOpenMember={setOpenMember}
											/>;
										}) :
										members.map((member, key) => {
											return <MemberItem
												key={key}
												member={member}
												setOpenMember={setOpenMember}
											/>;
										})
								}
								{
									members.length > 0 && members.filter(compareSearch) == 0 &&
									<p className={classes.noResults}>Sin resultados</p>
								}
								{
									members.length == 0 && <ExpensesSkeleton />
								}
							</div>
						</div>
						<InviteModal
							isOpen={isModalOpen}
							title="Invitar miembros"
							handleClose={() => setIsModalOpen(false)}
						/>
					</>
			}
		</Layout>
	);
};

export default Members;