import React from 'react';
import Avatar from '../_shared/Avatar.js'
import { makeStyles } from '@material-ui/core/styles';
import { getStringFromDate } from '../../commons/Utils.js';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './itemStyles.js';

// Utils
import { formatCurrency } from '../../commons/Utils';


const useStyles = makeStyles(styles);


const Expense = props => {
    const classes = useStyles();
    const {
        description,
        amountOwn,
        amountCompany,
        amountState,
        user,
        date,
        state,
        id
    } = props.expense;

    const { setSelectedExpense, getStateDetails, } = props;

    const handleOpenExpense = () => {
        setSelectedExpense(id)
    }

    const { stateText, stateColor } = getStateDetails(state);


    return (
        <div className={classes.gasto} onClick={handleOpenExpense}>
            <div className={classes.column}>
                <div className={classes.avatar}>
                    <Avatar userInfo={user} />
                </div>
                <div className={classes.info}>
                    <p className={classes.user}>{description.charAt(0).toUpperCase() + description.slice(1)}</p>
                    <p className={classes.date}>{getStringFromDate(date)}</p>
                </div>
                <div className={classes.state}>
                    <div className={classes.stateIndicator} style={{background: stateColor}}></div>
                    {amountState == 'reimbursed' ? 'Reintegrado' : stateText}
                </div>
            </div>
            <div className={classes.smallColumn}>
                {/* <FontAwesomeIcon className={classes.menuIcon} icon={faEllipsisV} /> */}
                <p className={classes.amount}>{formatCurrency(parseFloat(amountCompany) + parseFloat(amountOwn))}</p>
            </div>
        </div>
    );
}

export default Expense;