import { lightgray, black } from "color-name";

export default {
	card: {
		background: 'white',
		borderRadius: 5,
		height: 25,
		display: 'inline-block',
		padding: 20,
		paddingTop: 12,
		paddingBottom: 12
	},
	linkUser: {
		color: 'gray',
		fontSize: 18,
		fontWeight: 600,
		cursor: 'pointer',
		'&:hover': {
			cursor: 'ponter !important',
			color: '#910091ff',
			textDecoration: 'underline'
		}
	},
	itemSkeleton: {
		height: 76,
		width: '100%',
		borderBottom: '1px solid rgba(0,0,0,0.05)',
		position: 'relative'
	},
	avatarSkeleton: {
		height: 40,
		width: 40,
		borderRadius: 20,
		position: 'absolute',
		top: 18,
		left: 20,
		background: 'rgba(0,0,0,0.07)'
	},
	textSkeleton: {
		position: 'absolute',
		height: 12,
		width: '60%',
		left: 78,
		top: 20,
		background: 'linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%)',
		borderRadius: 10,
		animation: '$pulse 1.2s ease-in-out infinite'
	},
	textSkeletonSmall: {
		position: 'absolute',
		height: 12,
		width: '30%',
		left: 78,
		top: 45,
		background: 'linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%)',
		borderRadius: 10,
		animation: '$pulse 1.2s ease-in-out infinite',
		'$from': '#f5f5f5',
		'$to': 'scale-color($from, $lightness: -10%)'
	},
	'@keyframes pulse': {
		from: {
			backgroundPosition: '0% 0%'
		},
		to: {
			backgroundPosition: '-135% 0%'
		}
	},
	filtersDiv: {
		height: 66,
		width: '100%',
		marginTop: 0
	},
	fixedFiltersDiv: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: 66,
		width: '100%',
		marginTop: 0
	},
	searchBar: {
		height: 20,
		padding: '12px 48px',
		width: 'calc(54% - 96px)',
		minWidth: 200,
		background: 'rgba(0,0,0,0.04)',
		borderRadius: 6,
		position: 'relative',
		fontSize: '14px',
		color: 'gray',
		border: '1px solid transparent',
		animation: '$search .2s linear',
		overflow: 'hidden',
		// verticalAlign: 'middle'
	},
	searchButton: {
		height: 20,
		width: 150,
		borderRadius: 6,
		position: 'relative',
		padding: '12px 48px',
		cursor: 'text',
		fontSize: '14px',
		border: '1px solid rgba(0,0,0,0.06)',
		background: 'white',
		display: 'inline-block',
		marginRight: 10,
		verticalAlign: 'top'
	},
	searchIcon: {
		color: 'gray',
		position: 'absolute',
		top: 14,
		left: 16,
		fontSize: '14px',
	},
	layoutButton: {
		transition: '.2s',
		borderRadius: 6,
		position: 'relative',
		fontSize: '14px',
		background: 'white',
		display: 'inline',
		verticalAlign: 'middle',
		float: 'right',
		marginRight: 0,
		whiteSpace: 'nowrap',
		padding: '13px',
		cursor: 'pointer',
		height: 22,
		width: 22,
		opacity: 0.5,
		'&:hover': {
			opacity: 1,
			background: 'rgba(0,0,0,0.03)',
		}
	},
	exportButton: {
		transition: '.2s',
		borderRadius: 6,
		fontSize: '14px',
		background: 'white',
		padding: '13px',
		paddingTop: 14,
		marginRight: -10,
		cursor: 'pointer',
		height: 21,
		width: 22,
		opacity: 0.5,
		'&:hover': {
			opacity: 1,
			background: 'rgba(0,0,0,0.03)',
		}
	},
	inlineButton: {
		float: 'right',
		marginRight: 10,
		display: 'inline',
		verticalAlign: 'middle',
		whiteSpace: 'nowrap',
		position: 'relative',
	},
	svgButton: {
		stroke: '2a2651',
		strokeWidth: '1.6',
		height: 22,
		width: 22,
		margin: 0
	},
	layoutIcon: {
		color: 'gray',
		fontSize: '20px',
		padding: '12px 19px',
		display: 'inline !important',
		cursor: 'pointer',
		// borderRight: '1px solid rgba(0,0,0,0.06)',
		'&:hover': {
			color: 'gray',
			// background: 'rgba(0,0,0,0.02)'
		}
	},
	selectedLayoutIcon: {
		color: 'lightgray',
		fontSize: '20px',
		padding: '12px 19px',
		background: 'rgba(0,0,0,0.02)'
	},
	tooltip: {
		background: '#252763',
		padding: '7px 14px',
		paddingTop: 8,
		position: 'absolute',
		height: 20,
		color: 'white',
		top: 56,
		right: 0,
		borderRadius: 6,
		zIndex: 9999999,
		animation: '$fadeIn .4s ease-in-out'
	},
	'@keyframes fadeIn': {
		'0%': {
			opacity: 0
		},
		'40%': {
			opacity: 0
		},
		'100%': {
			opacity: 1
		}
	},
	closeIcon: {
		color: 'gray',
		position: 'absolute',
		top: 0,
		right: 0,
		padding: '9px 20px',
		fontSize: '20px',
		fontFamily: 'Nunito',
		transform: 'scaleX(1.16)',
		cursor: 'pointer',
		'&:hover': {
			background: 'rgba(0,0,0,0.04)'
		}
	},
	'@keyframes search': {
		from: {
			width: '36%',
			border: '1px solid lightgray',
			opacity: 0,
		},
		to: {
			width: 'calc(54% - 96px)',
			border: '1px solid transparent',
			color: 'gray',
			opacity: 1
		}
	},
	searchInput: {
		border: 'none',
		outline: 'none !important',
		background: 'transparent',
		position: 'absolute',
		top: '0px',
		left: '0px',
		padding: '12px 48px',
		width: 'calc(100% - 160px)',
		fontSize: 14,
	},
	actionsDiv: {
		float: 'right'
	},
	userFilter: {
		maxWidth: 300,
		marginLeft: 10,
		marginTop: 3,
		overflow: 'hidden',
		paddingRight: 20,
		display: 'inline-block'
	},
	avatar: {
		height: 36,
		width: 36,
		borderRadius: 30,
		position: 'relative',
		marginTop: 0,
		marginBottom: 0,
		display: 'inline-block',
		border: '2px solid white',
		marginRight: -8,
		overflow: 'hidden',
		float: 'right',
		cursor: 'pointer',
		zIndex: 0,
		transitionProperty: 'border-color',
		'&:hover': {
			opacity: 0.9,
			zIndex: 100
		}
	},
	avatarSelected: {
		height: 36,
		width: 36,
		borderRadius: 30,
		position: 'relative',
		marginTop: 0,
		marginBottom: 0,
		display: 'inline-block',
		border: '2px solid #9246FF',
		marginRight: -8,
		overflow: 'hidden',
		float: 'right',
		cursor: 'pointer',
		zIndex: 1,
		transitionProperty: 'border-color',
		'&:hover': {
			opacity: 0.9,
			zIndex: 100
		}
	},
	modalContainer: {
		width: '90vw',
		maxWidth: 390
	},
	modalLabel: {
		paddingLeft: 28,
		fontSize: 14,
		margin: '20px 0px',
		color: 'gray'
	},
	modalActions: {
		position: 'absolute',
		bottom: 0,
		right: 8,
		padding: 28
	},
	startDatePicker: {
		marginLeft: 'calc(50% - 156px)',
		width: '310px !important',
		marginTop: 50
	},
	'@media (max-width: 1280px)': {
		searchButton: {
			width: 90,
		},
	},
	'@media (max-width: 1024px)': {
		layoutButton: {
			display: 'none'
		},
	},
	'@media (max-width: 800px)': {
		userFilter: {
			display: 'none'
		},
	},
	'@media (max-width: 500px)': {
		searchButton: {
			width: 20,
		},
	},
	dialogInput: {
		border: '1px solid lightgray',
		background: 'white',
		borderRadius: '4px',
		padding: '12px',
		width: 'calc(100% - 80px)',
		outline: 'none !important',
		marginLeft: '26px',
		marginTop: '8px',
	},
	dialogInputCurrency: {
		border: '1px solid lightgray',
		background: 'white',
		borderRadius: '4px',
		padding: '12px',
		width: 'calc(100% - 80px)',
		outline: 'none !important',
		marginLeft: '26px',
		marginTop: '8px',
		cursor: 'text',
		"& $span": {
			display: 'none'
		},
		"&:focus-within": {
			border: '1px solid #9246FF',
			"& $span": {
				display: 'inline-block',
				width: 1,
				height: 18,
				marginLeft: 0,
				marginTop: 1,
				background: 'rgba(0,0,0,0.4)',
				position: 'absolute'
			},
		},
	},
	dialogInputCurrencyHidden: {
		opacity: 0,
		pointerEvents: 'none',
		margin: 0,
		height: 1,
		width: 1,
		padding: 0,
		border: 0
	},
	dialogLabel: {
		fontSize: 13,
		color: 'gray',
		marginLeft: '26px',
		marginTop: '26px',
		marginBottom: '0px'
	},
	dialogCurrencySymbol: {
		color: 'lightgray',
		position: 'absolute',
		left: 34,
		top: 128,
		fontSize: 14
	},
	dialogError: {
		color: 'red',
		fontSize: 11,
		marginLeft: 30
	}
};