import React from 'react';
import styles from './styles';
// Material ui
import { makeStyles } from '@material-ui/core/styles';
// Components
import Avatar from '../_shared/Avatar'
//Utils
import { getStringFromDate, formatCurrency } from '../../commons/Utils.js';


const useStyles = makeStyles(styles);


const Card = props => {
    const classes = useStyles();
    const { provided, item, cardClass, setSelectedExpense } = props;
    const { user } = props.item;

    const openModal = () => {
        setSelectedExpense(item.id);
    }

    return (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={provided.draggableProps.style}
            className={cardClass}
            onClick={openModal}
        >
            <div className={classes.avatar}>
                <Avatar userInfo={user} customClass={classes.avatarElem} />
            </div>
            <div className={classes.text}>
                <p className={classes.description}>
                    {item.description.charAt(0).toUpperCase() + item.description.slice(1)}
                </p>
                <p className={classes.date}>
                    {getStringFromDate(item.date)}
                </p>
                {
                    item.amountState == 'reimbursed' &&
                    <p className={classes.reimbursedBadge}>Reintegrado</p>
                }
                <p className={classes.amount}>
                    {formatCurrency(parseFloat(item.amountCompany) + parseFloat(item.amountOwn))}
                </p>
            </div>
        </div>
    );
}

export default Card;