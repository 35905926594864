import { useState } from 'react';
import { editCustomFieldData, editExpense } from '../../../commons/Services';
import { useParams } from "react-router-dom";


const modalTypes = [
    'date',
    'expenseState',
    'customText',
    'customSelect',
    'customNumber',
    'customDate',
    'customCurrency',
    'description',
    'categories',
    'amountOwn',
    'amount'
];

const customModalTypes = [
    'customText',
    'customSelect',
    'customNumber',
    'customDate',
    'customCurrency'
];

const useEditExpenseState = (changeExpenseState, fetchExpense) => {
    const [openedModal, setOpenModal] = useState(null);
    const [editingFields, setEditingValue] = useState({});
    const [tooltipPosition, setTooltipPosition] = useState({ top: 50, right: 18 });
    const expenseId = useParams()?.id;

    const openModal = (modalName) => {
        if(openedModal) {
            setOpenModal(null);
            return;
        }
        if(modalTypes.includes(modalName)) {
            setOpenModal(modalName);
        }
    };

    const closeModal = () => {
        setOpenModal(null);
    };

    const changeAndSave = async (name, value, customFieldId, id = null, cf) => {
        if(name === 'expenseState') {
            changeExpenseState(value);
        }
        if(name === 'date') {
            editExpense({
                date: value
            }, expenseId);
        }
        if(name === 'newAttachments') {
            editExpense({
                newAttachments: value
            }, expenseId);
        }
        if(name === 'description') {
            editExpense({
                description: value
            }, expenseId);
        }
        if(name === 'category') {
            editExpense({
                categoryId: value
            }, expenseId);
        }
        if(name === 'amountOwn' && !value?.byPassEdit) {
            editExpense({
                amountOwn: value.amountOwn,
                amountCompany: parseFloat(value.amountTotal) - parseFloat(value.amountOwn)
            }, expenseId);
        }
        if(customModalTypes.includes(name) && id) {
            let cfValue = value;
            if(name === 'customSelect')
                cfValue = cf.selectValues.find(v => v.id === value);
            setEditingValue(prevState => ({ ...prevState, [id]: cfValue.value || cfValue }));
            editCustomFieldData(
                id,
                name === 'customSelect' ? null : value,
                name !== 'customSelect' ? null : value
            );
        }
        if(customModalTypes.includes(name) && !id) {
            let cfValue = value;
            if(name === 'customSelect')
                cfValue = cf.selectValues.find(v => v.id === value);
            setEditingValue(prevState => ({ ...prevState, ['cf:' + customFieldId]: cfValue.value || cfValue }));
            editExpense({
                newCustomFields: [
                    {
                        id: customFieldId,
                        selectedValue: value,
                        selectValueId: name !== 'customSelect' ? null : value
                    }
                ]
            }, expenseId);
        }
        if(name === 'amountOwn') {
            setEditingValue(prevState => ({
                ...prevState,
                amountOwn: value.amountOwn,
                amountTotal: value.amountTotal
            }));
        } else if(!customModalTypes.includes(name)) {
            setEditingValue(prevState => ({ ...prevState, [name]: value }));
        }
        setTimeout(() => {
            fetchExpense();
        }, 500)
    };

    return [
        { openedModal, editingFields, tooltipPosition },
        { openModal, closeModal, changeAndSave, setTooltipPosition }
    ];
};

export default useEditExpenseState;