import React, { useState, useEffect } from 'react';
import styles from './groups.module.scss';

// Components
import Layout from '../_shared/Layout.js';
import Button from '../generics/Button.js';
import { Link } from 'react-router-dom';
import Avatar from '../_shared/Avatar';
import Filters from './Filters';
import Modal from '../generics/Dialog';

// Icons
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ExpendrIcon from '../_shared/ExpendrIcon';

// Services
import { getUserGroups, deleteGroup } from '../../commons/Services';

const searchState = {
    search: '',
    isSearching: false,
};

const Groups = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [groups, setGroups] = useState([]);
    const [filters, setFilters] = useState(searchState);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteModalLoading, setIsDeleteModalLoading] = useState(false);

    const getGroups = async () => {
        const res = await getUserGroups();
        setGroups(res?.data?.data || []);
        setIsLoading(false);
    };

    const openDeleteModal = (id) => {
        setIsDeleteModalOpen(id);
    };

    const filterByName = (group) => {
        return group.name?.toLowerCase().includes(filters.search?.toLowerCase());
    };

    const deleteGroupRequest = async () => {
        setIsDeleteModalLoading(true);
        const res = await deleteGroup(isDeleteModalOpen);
        getGroups();
        setIsDeleteModalLoading(false);
        setIsDeleteModalOpen(false);
    };

    useEffect(() => {
        setIsLoading(true);
        getGroups();
    }, []);

    return (
        <Layout title={"Grupos"} isLoading={isLoading}>
            <div className={styles.container}>
                {
                    groups?.length > 0 &&
                    <Filters
                        search={filters.search}
                        setSearch={(search) => setFilters({ ...filters, search })}
                        isSearching={filters.isSearching}
                        setIsSearching={(isSearching) => setFilters({ ...filters, isSearching })}
                    >
                        <Link to="createGroup/new" >
                            <Button icon={faPlus}>Nuevo grupo</Button>
                        </Link>
                    </Filters>
                }
                <div className={styles.groups}>
                    {
                        groups.filter(filterByName).map(group => (
                            <div className={styles.group} key={group.id}>
                                <h2>{group?.name}</h2>
                                <div className={styles.groupSeparator}></div>
                                <p className={styles.usersAmount}>
                                    {group?.users?.length + (group?.users?.length === '1' ? ' miembro' : ' miembros')}
                                </p>
                                {
                                    group?.users?.length > 0 &&
                                    <div className={styles.userFilter}>
                                        {
                                            group?.users?.slice(0, 10).map((user, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={styles.avatar}
                                                    >
                                                        <Avatar userInfo={user} />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>

                                }
                                <ExpendrIcon
                                    onClick={() => openDeleteModal(group.id)}
                                    className={styles.deleteCategory}
                                    icon={'trash'}
                                />
                                <Link to={`/createGroup/${group.id}`}>
                                    <ExpendrIcon
                                        className={styles.editCategory}
                                        icon={'pencil'}
                                    />
                                </Link>
                            </div>
                        ))
                    }
                    {
                    groups.length === 0 && !isLoading && (
                        <div className={styles.emptyStateContainer}>
                            <h2>Todavía no hay grupos creados</h2>
                            <p>Crea grupos de usuarios para asignarles campos de información extra al subir un gasto</p>
                            <Link to="createGroup/new" >
                                <Button icon={faPlus}>Nuevo grupo</Button>
                            </Link>
                        </div>
                    )
                }
                </div>
            </div>
            <Modal
                isOpen={isDeleteModalOpen}
                handleClose={() => setIsDeleteModalOpen(false)}
                title="Eliminar grupo"
                mainAction={deleteGroupRequest}
            >
                <div className={styles.dialogContent}>
                    <p className={styles.modalText}>Seguro que deseas eliminar el grupo?</p>
                    <div className={styles.dialogButton}>
                        <Button disabled={isDeleteModalLoading} onClick={deleteGroupRequest}>
                            Confirmar
                        </Button>
                    </div>
                </div>
            </Modal>
        </Layout>
    );
};

export default Groups;
