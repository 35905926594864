import { useEffect, useState } from 'react';
import { getPlans, generateCheckoutSession } from '../../../commons/Services';
const checkoutUrl = process.env.REACT_APP_CHECKOUT_URL;

const useGetPlans = () => {
    const [plans, setPlans] = useState(null);

    const fetchPlans = async () => {
        const res = await getPlans();
        // Avoid flashes:
        setTimeout(() => {
            setPlans(res?.data?.plans);
        }, 300);
    };

    const createCheckout = async (planId) => {
        const res = await generateCheckoutSession(planId);
        const sId = res?.data?.sessionId;
        sId && redirectToCheckout(sId);
    };

    const redirectToCheckout = (sessionId) => {
        console.log(`Redirecting to ${checkoutUrl}`);
        const url = `${checkoutUrl}?sId=${sessionId}`;
        window.location.href = url;
    };

    useEffect(() => {
        fetchPlans();
    }, []);

    return [plans, createCheckout];
};

export default useGetPlans;
