import React from 'react';


const Avatar = (props) => {
    const { userInfo, customClass } = props;

    return (
        <>
            {
                userInfo.profilePhotoUrl ?
                    <div style={{ backgroundImage: `url(${userInfo.profilePhotoUrl})` }} className="userImage"></div> :
                    <div 
                        className={customClass ? customClass : "userAvatar"}
                        style={{ background: userInfo.color }}>
                        {userInfo.initials}
                    </div>
            }
        </>
    );
}

export default Avatar;