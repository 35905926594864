import { useEffect, useState, useContext } from 'react';

// Services
import { addUserGroup, updateGroup, getUserGroups, getMembers } from '../../../commons/Services';

// Utils
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../../commons/UserContext';

const initialState = {
    name: '',
    description: '',
    users: [],
};

const useCreateGroupState = () => {
    const { id } = useParams();
    const isEditing = id === 'new';
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [formValues, setFormValues] = useState(initialState);
    const [isLoading, setIsLoading] = useState(!isEditing);
    const [members, setMembers] = useState([]);
    const { name, users } = formValues;
    const { showSnackbar } = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        if(id !== 'new') fetchGroups();
        fetchMembers();
    }, []);

    useEffect(() => {
        if(name.length > 1) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [name]);

    const fetchGroups = async () => {
        const res = await getUserGroups();
        const currentGroup = res?.data?.data?.find(group => group.id == id);
        setField('name', currentGroup.name);
        setField('users', currentGroup.users);
        setIsLoading(!!members?.length);
    };

    const fetchMembers = async () => {
        const m = await getMembers();
        if(m.users?.length)
            setMembers(m.users);
        setIsLoading(!!formValues.name?.length);
    };

    const saveGroup = async () => {
        setIsLoading(true);
        if(id === 'new') {
            const payload = { ...formValues, userIds: users.map(user => user.id) };
            const response = await addUserGroup(payload);
            const id = response?.data?.id;
            setIsLoading(false);
            history.push(`/createGroup/${id}`);
            return;
        }
        await updateGroup({ ...formValues, userIds: users.map(user => user.id), id });
        setIsLoading(false);
        showSnackbar("Grupo guardado");
    };

    const setField = (name, value) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value
        }));
    };

    const returnValues = {
        name,
        isButtonDisabled,
        isLoading,
        members,
        users,
        id
    };

    return [
        returnValues,
        { setField, saveGroup }
    ];
};

export default useCreateGroupState;
