import React, { useState, useEffect } from 'react';
// Components
import Modal from '../generics/Dialog';
import Button from '../generics/Button';
import { Link } from 'react-router-dom';
// Styles
import styles from './expensesSettings.module.scss';
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrash, faPlus, faPen } from '@fortawesome/free-solid-svg-icons';
import ExpendrIcon from '../_shared/ExpendrIcon';

// Services
import { getCustomFields, deleteCustomField, toggleCustomFieldIsActive } from '../../commons/Services';
// Utils
import { capitalize } from '../../commons/Utils';
import SwitchMU from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const Switch = withStyles({
    switchBase: {
        color: 'white',
        '&$checked': {
            color: 'white',
        },
        '&$checked + $track': {
            backgroundColor: '#704eff',
            opacity: 0.8
        },
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
        '&:hover + $track': {
            opacity: 0.6
        }
    },
    checked: {
    },
    track: {
        backgroundColor: 'rgba(0,0,0,0.3)',
        transformOrigin: 'middle center',
        height: 20,
        borderRadius: 20,
        transform: 'scale(1.2) scaleX(1.1)',
        '&:hover': {
            backgroundColor: '#704eff',
            opacity: 0.7
        }
    },
    thumb: {
        marginTop: 3,
        transform: 'scale(1)'
    }
})(SwitchMU);


const getTypeName = (type) => {
    switch(type) {
        case 'text':
            return 'Texto';
        case 'number':
            return 'Numérico';
        case 'select':
            return 'Opciones';
        case 'currency':
            return 'Moneda';
        default:
            return capitalize(type);
    }
};


const CustomFields = ({ goBack }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
    const [customFields, setCustomFields] = useState([]);
    const [selected, setSelected] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchCustomFields();
    }, []);

    const fetchCustomFields = async () => {
        const response = await getCustomFields();
        setCustomFields(response.data.customFields);
    };

    const openDeleteModal = id => {
        setIsDeleteButtonDisabled(false);
        setIsDeleteModalOpen(true);
        setSelected(id);
    };

    const deleteCustomFieldRequest = async () => {
        const res = await deleteCustomField(selected);
        setIsDeleteModalOpen(false);
        setIsDeleteButtonDisabled(true);
        fetchCustomFields();
    };

    const toggleIsActive = async (cf) => {
        setIsLoading(true);
        await toggleCustomFieldIsActive(cf);
        await fetchCustomFields();
        setIsLoading(false);
        // Show toast message
    };

    return (
        <div style={isLoading ? { opacity: 0.3, pointerEvents: 'none' } : {}} >
            <FontAwesomeIcon className={styles.backArrow} icon={faArrowLeft} onClick={goBack} />
            <h2 className={styles.sectionTitle}>Campos extra</h2>
            <div className={styles.addCategoryButton}>
                <Link to="/createCustomField/new">
                    <Button icon={faPlus}>
                        Agregar nuevo
                    </Button>
                </Link>
            </div>
            <div className={styles.customFieldsContainer}>
                {
                    customFields.map(cf => {
                        return <div key={cf.id} className={styles.customField}>
                            <p className={styles.customFieldTitle}>{capitalize(cf.title)}</p>
                            <div style={{ marginLeft: -6, marginTop: -12, marginBottom: 22 }}>
                                <Switch
                                    checked={cf.active}
                                    onChange={() => { toggleIsActive(cf); }}
                                />
                            </div>
                            <p className={styles.customFieldType}>{getTypeName(cf.type)}</p>
                            <ExpendrIcon
                                onClick={() => openDeleteModal(cf.id)}
                                className={styles.deleteCategory}
                                icon={'trash'}
                            />
                            <Link to={`/createCustomField/${cf.id}`}>
                                <ExpendrIcon
                                    className={styles.editCategory}
                                    icon={'pencil'}
                                />
                            </Link>
                        </div>;
                    })
                }
            </div>
            <Modal
                isOpen={isDeleteModalOpen}
                handleClose={() => setIsDeleteModalOpen(false)}
                title="Eliminar campo extra"
                mainAction={deleteCustomFieldRequest}
            >
                <div className={styles.dialogContent}>
                    <p className={styles.modalText}>Seguro que deseas eliminar el campo extra?</p>
                    <div className={styles.dialogButton}>
                        <Button disabled={deleteButtonDisabled} onClick={deleteCustomFieldRequest}>
                            Confirmar
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CustomFields;