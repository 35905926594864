import React, { useState } from 'react';

// Components
import Layout from '../_shared/Layout.js';
import InputField from './Field.js';
import PageHeader from './PageHeader.js';
import SelectOptions from './SelectOptions.js';
import SelectGroup from './SelectGroup.js';

// Hooks
import useCustomFields from './customHooks/useCustomFieldState';


const CreateCustomField = () => {
	const [customFieldState, actions] = useCustomFields();
	const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
	const {
		title,
		type,
		accessType,
		isButtonDisabled,
		isLoading,
		options,
		pageTitle,
		selectedOption,
		roles,
		selectedGroups,
		required,
		showInUserPayment,
		showInOrganizationPayment,
	} = customFieldState;
	const { setField, saveCustomField, setSelectedOption, deleteOption, createOption, editOption, setSelectedGroups } = actions;

	return (
		<Layout title={pageTitle} isLoading={isLoading}>
			<div style={{ position: "relative" }}>
				<PageHeader
					isButtonDisabled={isButtonDisabled}
					onSave={saveCustomField}
					showAdvancedSettings={showAdvancedSettings}
					setShowAdvancedSettings={setShowAdvancedSettings}
				/>
				<div style={{ height: "75vh", paddingTop: 80, overflow: 'auto', marginRight: "-48px", paddingRight: 41 }}>
					{
						!showAdvancedSettings &&
						<>
							<InputField
								label="Título del campo"
								onChange={e => setField('title', e.target.value)}
								value={title}
								type="text"
							/>
							<InputField
								label="Tipo"
								onChange={type => setField('type', type)}
								value={type}
								type="select"
							/>
							<InputField
								label="Es campo requerido"
								onChange={_e => setField('required', !required)}
								checked={required}
								type="switch"
							/>
							<InputField
								label="Ver opciones avanzadas"
								type="link"
								onClick={() => setShowAdvancedSettings(true)}
							/>
							{
								type === 'select' &&
								<SelectOptions
									setSelectedOption={setSelectedOption}
									selectedOption={selectedOption}
									options={options || []}
									createOption={createOption}
									deleteOption={deleteOption}
									editOption={editOption}
								/>
							}
						</>
					}
					{
						showAdvancedSettings && <>
							<InputField
								label="Mostrar"
								onChange={async (values) => {
									await setField('showInOrganizationPayment', values.showInOrganizationPayment);
									setField('showInUserPayment', values.showInUserPayment);
								}}
								showInOrganizationPayment={showInOrganizationPayment}
								showInUserPayment={showInUserPayment}
								type="showWhen"
							/>
							<InputField
								label="Visible para"
								onChange={type => setField('accessType', type)}
								setRoles={roles => setField('roles', roles)}
								roles={roles}
								value={accessType}
								type="visibility"
							/>
							{
								accessType === 'group' &&
								<SelectGroup
									selectedGroups={selectedGroups}
									setSelectedGroups={setSelectedGroups}
								/>
							}
						</>
					}
				</div>
			</div>
		</Layout>
	);
};

export default CreateCustomField;
