import React from 'react';

// Styles
import styles from './expensesSettings.module.scss';
import SwitchMU from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';


const Switch = withStyles({
    switchBase: {
        color: 'white',
        '&$checked': {
            color: 'white',
        },
        '&$checked + $track': {
            backgroundColor: '#704eff',
            opacity: 0.8
        },
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
        '&:hover + $track': {
            opacity: 0.6
        }
    },
    checked: {
    },
    track: {
        backgroundColor: 'rgba(0,0,0,0.3)',
        transformOrigin: 'middle center',
        height: 20,
        borderRadius: 20,
        transform: 'scale(1.2) scaleX(1.1)',
        '&:hover': {
            backgroundColor: '#704eff',
            opacity: 0.7
        }
    },
    thumb: {
        marginTop: 3,
        transform: 'scale(1)'
    }
})(SwitchMU);


const MailSettings = ({ settings, editSettings, isLoading, setView }) => {
    return (
        <div className={isLoading ? styles.loadingSettingsPage : styles.settingsPage} >

            <div className={settings.usesCategories ? styles.setting : styles.disabledSetting} style={{ paddingBottom: 26 }}>
                <p className={styles.settingTittle}>Suscribirme a un gasto con el que interactuo</p>
                <p className={styles.settingText}>Cuando realices una acción sobre un gasto, te llegarán notificaciones relacionadas al gasto</p>
                <Switch
                    name="subscribe"
                    checked={settings.subscribeToExpenseOnActivity}
                    onChange={() => editSettings('subscribeToExpenseOnActivity', !settings.subscribeToExpenseOnActivity)}
                    className={styles.settingSwitch}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </div>

            <div className={settings.usesCategories ? styles.setting : styles.disabledSetting} style={{ paddingBottom: 26 }}>
                <p className={styles.settingTittle}>Notificarme cuando se cree un gasto</p>
                <p className={styles.settingText}>Te enviaremos una notificación por cada nuevo gasto en la organización</p>
                <Switch
                    name="newExpense"
                    checked={settings.enableNewExpenseNotification}
                    onChange={() => editSettings('enableNewExpenseNotification', !settings.enableNewExpenseNotification)}
                    className={styles.settingSwitch}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </div>

            <div className={settings.usesCategories ? styles.setting : styles.disabledSetting} style={{ paddingBottom: 26 }}>
                <p className={styles.settingTittle}>Notificarme cuando se elimine un gasto</p>
                <p className={styles.settingText}>Te enviaremos una notificación por cada gasto eliminado en la organización</p>
                <Switch
                    name="deletedExpense"
                    checked={settings.enableDeletedExpenseNotification}
                    onChange={() => editSettings('enableDeletedExpenseNotification', !settings.enableDeletedExpenseNotification)}
                    className={styles.settingSwitch}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </div>

        </div>
    );
}

export default MailSettings;