import React, { useState, useEffect } from 'react';
import styles from './refunds.module.scss';

//Components
import Layout from '../_shared/Layout';
import Modal from '../generics/Dialog';
import Avatar from '../_shared/Avatar';
import { Checkbox } from '@material-ui/core';
import Button from '../generics/Button';
import RefundsHistory from './RefundsHistory';
import UserItem from './UserItem';

//Utils
import { formatCurrency } from '../../commons/Utils.js';
import { getExpensesToBeReimbursed, refundAListOfExpenses } from '../../commons/Services';

//Icons
import { faSearch, faUserPlus, faClipboardCheck, faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Refunds = () => {
	const [expensesGroupedByUser, setExpensesGroupedByUser] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedExpenses, setSelectedExpenses] = useState([]);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [isShowingHistory, setIsShowingHistory] = useState(false);
	const [pageTitle, setPageTitle] = useState("Reintegros");
	const [isBankModalOpen, setIsBankModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fetchExpenses();
	}, []);

	const fetchExpenses = async () => {
		const res = await getExpensesToBeReimbursed();
		setExpensesGroupedByUser(res?.data?.expensesGroupedByUser);
		setIsButtonDisabled(false);
		setIsLoading(false);
	}

	const selectUser = user => {
		setSelectedUser(user);
		const expenses = user.expenseToBeReimbursed.map(expense => {
			return {
				...expense,
				checked: true
			}
		})

		setSelectedExpenses(expenses);
	}

	const toggleExpenseCheckValue = expense => {
		const newList = selectedExpenses.map(element => {
			if (element.id == expense.id) {
				return { ...element, checked: !element.checked }
			}
			return element;
		});

		setSelectedExpenses(newList);
	}

	const getTotalAmount = () => {
		return selectedExpenses.reduce((acc, current) => {
			if (current.checked) {
				return (parseFloat(acc) + parseFloat(current.amountOwn)).toFixed(2);
			}
			return acc;
		}, 0)
	}

	const setSearchClean = str => {
		setSearchText(str.replace(/\\/g, ""));
	}

	const compareSearch = userObj => {
		const re = new RegExp(searchText.toLowerCase(), 'g');
		if (userObj.fullName.toLowerCase().match(re)) {
			return true;
		}
		if (searchText.length < 2) return true;
	}

	const refundExpenses = async () => {
		const res = await refundAListOfExpenses(
			selectedExpenses?.filter(expense => expense.checked).map(expense => expense.id)
		);

		if (res.status === 200) {
			setIsModalOpen(false);
			fetchExpenses();
			setSelectedExpenses([]);
			setSelectedUser(null);
			setIsButtonDisabled(false);
		} else {
			alert("Hubo un error al intentar reintegrar");
		}
	}


	return (
		<Layout title={pageTitle}>

			{
				isShowingHistory ?
					<RefundsHistory
						goBack={() => {
							setIsShowingHistory(false);
							setPageTitle("Reintegros")
						}}
					/> :
					<div className={styles.container}>
						<div className={styles.members}>
							{
								expensesGroupedByUser?.length > 0 &&
								<div className={styles.searchBar}>
									<FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
									<input
										className={styles.searchInput}
										placeholder="Buscar miembro..."
										onChange={e => setSearchClean(e.target.value)}
										value={searchText}
									/>
								</div>
							}

							{
								expensesGroupedByUser?.filter(compareSearch).map(userExpenses => (
									<UserItem
										userExpenses={userExpenses}
										selectUser={selectUser}
										selectedUser={selectedUser}
									/>
								))
							}
						</div>
						<div className={styles.refundsContainer}>
							<div
								className={styles.historyButton}
								onClick={() => {
									setIsShowingHistory(true)
									setPageTitle("Historial")
								}}
							>
								<FontAwesomeIcon icon={faHistory} />
										Historial de reintegros
									</div>
							{
								selectedUser &&
								<div>
									<div className={styles.rightHandHeader}>
										<div onClick={() => setIsBankModalOpen(true)} style={{ cursor: 'pointer', width: '80%' }} >
											<div className={styles.avatar}>
												<Avatar userInfo={selectedUser} />
											</div>
											<div className={styles.rightHandUser}>
												<p className={styles.rightHandName}>{selectedUser.fullName}</p>
												<p className={styles.rightHandDetails}>Ver datos bancarios</p>
											</div>
										</div>
									</div>
									<div className={styles.expensesList}>
										{
											selectedExpenses.map(expense => {
												return (
													<div
														key={expense.id}
														className={styles.expenseToSelect}
														onClick={() => toggleExpenseCheckValue(expense)}
													>
														<Checkbox
															checked={expense.checked}
															style={{
																color: expense.checked ? "#5043dd" : "lightgray",
																pointerEvents: 'none',
																transform: 'scale(0.9)'
															}}
														/>
														{expense.description}
														<p className={styles.expenseAmount}>{formatCurrency(expense.amountOwn)}</p>
													</div>
												)
											})
										}
									</div>
									<div className={styles.rightHandFooter}>
										Total: {'  $' + getTotalAmount()}
										<div className={styles.refundButton}>
											<Button
												onClick={() => setIsModalOpen(true)}
												disabled={getTotalAmount() === 0}
											>
												Liquidar deuda
													</Button>
										</div>
									</div>
								</div>
							}
							{
								!isLoading && !selectedUser &&
								<div className={styles.emptyState}>
									<FontAwesomeIcon
										icon={expensesGroupedByUser?.length ? faUserPlus : faClipboardCheck}
										className={styles.userIcon}
									/>
									{
										expensesGroupedByUser?.length ?
											"Selecciona un usuario para reintegrar" :
											"No hay gastos a reintegrar"
									}
								</div>
							}
						</div>
					</div>
			}
			<Modal
				isOpen={isModalOpen}
				handleClose={() => setIsModalOpen(false)}
				title="Marcar como reintegrado"
				mainAction={refundExpenses}
			>
				<div className={styles.dialogContent}>
					Esta acción no ejecutará ningún pago, es sólo el registro en el sistema de un pago externo
                    <div className={styles.dialogButton}>
						<Button onClick={refundExpenses} disabled={isButtonDisabled}>
							Confirmar
                        </Button>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={isBankModalOpen}
				handleClose={() => setIsBankModalOpen(false)}
				title="Información bancaria"
			>
				<div className={styles.dialogContent}>
					<div className={styles.bankInfo}>
						<label>Nombre</label> {selectedUser?.fullName}
						{
							selectedUser?.bankAccounts[0]?.cbu ?
								<>
									<label>CBU</label> {selectedUser?.bankAccounts[0]?.cbu}
									<label>Cuit</label> {selectedUser?.bankAccounts[0]?.cuit}
								</> :
								<p>El usuario no actualizó sus datos bancarios</p>
						}
					</div>
				</div>
			</Modal>
		</Layout>
	);
}

export default Refunds;