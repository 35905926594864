import React, { useState } from 'react';

// Styles
import styles from './createExpense.module.scss';

// Components
import Dropzone from 'react-dropzone';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileUpload,
    faPlus
} from '@fortawesome/free-solid-svg-icons';

// Store
import { uploadFile } from '../../commons/Services';
import FilePreview from './FilePreview';


const handleChooseImage = async (image, setImage, setImageReady, keepLoading) => {
    setImageReady(false);
    const res = await uploadFile(image[0]);
    if(res?.status == 200) {
        setImage(res.data.url, () => setImageReady(true));
    }
    if(!keepLoading) setImageReady(true);
};


const FileSelector = (props) => {
    const [imageReady, setImageReady] = useState(true);
    const {
        image,
        isImageOpen,
        setIsImageOpen,
        setImage,
        keepLoading,
        smallLayout
    } = props;

    return <div className={smallLayout ? styles.fileSelectorContainerSmall : styles.fileSelectorContainer}>
        <div className={styles.filePlaceholder}>
            <Dropzone
                onDrop={chosenImage => handleChooseImage(chosenImage, setImage, setImageReady, keepLoading)}
                accept=".jpg,.jpeg,.png,.pdf"
                multiple={false}
            >
                {({ getRootProps, getInputProps }) => (
                    <section
                        className={
                            image ?
                                styles.imageSectionElement :
                                styles.dropzoneSectionElement
                        }
                    >
                        <div
                            {...getRootProps()}
                            style={{
                                outline: 'none'
                            }}
                        >
                            {
                                !image &&
                                <input
                                    {...getInputProps()}
                                />
                            }
                            <div>
                                {!imageReady ? (
                                    <>
                                        {
                                            smallLayout ?
                                                <div className={styles.btnText}>
                                                    Subiendo archivos...
                                                </div> :
                                                <div className={styles.loader}></div>
                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            image ?
                                                <FilePreview
                                                    file={image}
                                                    isImageOpen={isImageOpen}
                                                    setIsImageOpen={setIsImageOpen}
                                                /> :
                                                <>
                                                    {
                                                        smallLayout ?
                                                            <div className={styles.btnText}>
                                                                <FontAwesomeIcon className={styles.btnTextIcon} icon={faPlus} />
                                                                Agregar adjuntos
                                                            </div> :
                                                            <FontAwesomeIcon
                                                                className={styles.uploadFileIcon}
                                                                icon={faFileUpload}
                                                            />
                                                    }
                                                </>

                                        }
                                    </>
                                )}
                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>
            {
                image &&
                <div onClick={() => setImage(null)} className={styles.removeFile}>x</div>
            }
        </div>
    </div>;
};

export default FileSelector;