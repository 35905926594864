import React from 'react';
import Avatar from '../_shared/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from '@material-ui/core';


const useStyles = makeStyles({
    member: {
        paddingTop: 8,
        height: 68,
        width: '100%',
        borderBottom: '1px solid rgba(0,0,0,0.06)',
        overflow: 'hidden',
        transition: '.3s',
        '&:last-of-type': {
            borderBottom: '1px solid rgba(0,0,0,0.0)',
        },
        '&:hover': {
            background: 'rgba(0,0,0,0.03)',
            cursor: 'pointer',
        },
    },
    avatar: {
        height: 40,
        width: 40,
        borderRadius: 30,
        marginTop: -17,
        marginBottom: 0,
        marginLeft: 20,
        display: 'inline-block',
        verticalAlign: 'middle',
        '@media (max-width: 600px)': {
            marginLeft: 12
        }
    },
    user: {
        display: 'block',
        verticalAlign: 'middle',
        marginTop: 13,
        fontWeight: 600,
        fontSize: 13,
        marginLeft: 16,
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        color: 'rgba(0,0,0,0.8)',
        textTransform: 'Capitalize',
        '@media (max-width: 600px)': {
            marginLeft: 10
        }
    },
    amount: {
        color: '#5043dd',
        float: 'right',
        marginRight: 20,
        fontWeight: 600,
        fontSize: 15,
        marginTop: 20,
        '@media (max-width: 600px)': {
            marginRight: 4
        }
    },
    description: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginTop: 22,
        fontSize: 14,
        fontWeight: 400,
        marginLeft: '-2%',
        color: 'rgba(0,0,0,0.4)'
    },
    date: {
        display: 'block',
        verticalAlign: 'middle',
        marginTop: -10,
        fontSize: 12,
        marginLeft: 16,
        color: 'gray',
        '@media (max-width: 600px)': {
            marginLeft: 10
        }
    },
    column: {
        height: '100%',
        width: 'calc(90% - 10px)',
        maxWidth: 420,
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    smallColumn: {
        float: 'right',
        height: '100%',
        width: 'calc(10% + 10px)',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    checkboxColumn: {
        float: 'left',
        height: '100%',
        width: 'calc(10% + 10px)',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    checkbox: {
        borderRadius: '50%',
        display: 'inline-block',
        verticalAlign: 'middle',
        transition: '.3s',
        marginLeft: '5px',
        marginRight: '5px',
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: 'rgba(0,0,0,0.03)'
        }
    },
    info: {
        display: 'inline-block',
        width: 'calc(55% +  10px)'
    },
    menuIcon: {
        marginTop: 14,
        borderRadius: 22,
        padding: '9px 16px',
        marginRight: 16,
        fontSize: 16,
        transform: 'scaleY(1.1)',
        color: 'rgba(0,0,0,0.3)',
        float: 'right',
        '&:hover': {
            background: 'rgba(0,0,0,0.03)',
            color: 'gray'
        },
        '@media (max-width: 600px)': {
            marginRight: 6
        }
    },
    stateIndicator: {
        height: 10,
        width: 10,
        borderRadius: 20,
        background: 'rgba(255,205,0,0.6)',
        display: 'inline-block',
        marginRight: 6,
        marginTop: -2,
        verticalAlign: 'middle'
    },
    role: {
        padding: '4px 2px',
        // border: '1px solid rgba(0,0,0,0.07)',
        borderRadius: 4,
        verticalAlign: 'middle',
        marginLeft: 0,
        marginTop: 18,
        paddingLeft: 2,
        float: 'right',
        fontSize: 13,
        display: 'inline-block',
        color: '#5043dd',
        width: 110,
        textAlign: 'center'
    }
});

const roleName = role => {
    switch(role) {
        case 'admin':
            return 'Administrador';

        case 'employee':
            return 'Básico';

        case 'owner':
            return 'Propietario';

        default:
            break;
    }
};


const Member = props => {
    const classes = useStyles();
    const {
        fullName,
        organizationRole,
        email,
        profilePhotoUrl,
        color,
        initials,
        id
    } = props.member;

    const user = {
        profilePhotoUrl,
        color,
        initials
    };

    const { enableSelection, checked, toggleChecked, setOpenMember, isClickable = true } = props;

    const handleClick = () => {
        if(!isClickable) {
            return;
        }
        setOpenMember(id);
    };

    return (
        <div className={classes.member} onClick={handleClick}>
            <div className={classes.column}>
                <div className={classes.avatar}>
                    <Avatar userInfo={user} />
                </div>
                {
                    enableSelection &&
                    <div className={classes.checkboxColumn}>
                        <div className={classes.checkbox} onClick={toggleChecked}>
                            <Checkbox
                                style={{
                                    color: checked ? "#5043dd" : "lightgray",
                                    padding: 0,
                                    margin: 18,
                                    pointerEvents: 'none'
                                }}
                                checked={checked || false}
                            />
                        </div>
                    </div>
                }
                <div className={classes.info}>
                    <p className={classes.user}>{fullName}</p>
                    <p className={classes.date}>{email}</p>
                </div>
                <div className={classes.role}>
                    {roleName(organizationRole)}
                </div>
            </div>
            {
                isClickable &&
                <div className={classes.smallColumn}>
                    <FontAwesomeIcon className={classes.menuIcon} icon={faChevronRight} />
                </div>
            }
        </div>
    );
};

export default Member;