import moment from 'moment';

const monthNames = [
	'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];

export const validateEmail = (email) => {
	if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
		return (true);
	}
	return (false);
};

export const getApiFriendlyDate = dateString => {
	return moment(dateString).format('YYYY-MM-DD');
};

export const getFileStringFromDate = (date) => {
	const month = monthNames[date.getMonth()];
	const day = date.getDate();

	return month + '-' + day;
};


export const getStringFromDate = dateString => {
	const date = new Date(dateString);
	const month = monthNames[date.getMonth()];
	const day = date.getDate();
	return (`${day} de ${month}`);
};

export const getDateFromApiString = dateString => {
	const date = new Date(
		dateString.length > 10 ?
			dateString.split('T')[0] + 'T00:00:00' :
			dateString + 'T00:00:00');
	return date;
};

export const getFullStringFromDate = dateString => {
	const date = new Date(
		dateString.length > 10 ?
			dateString.split('T')[0] + 'T00:00:00' :
			dateString + 'T00:00:00');
	const month = monthNames[date.getMonth()];
	const day = date.getDate();
	const anio = date.getFullYear();
	// const hour  = [date.getHours(), date.getMinutes()].join(':');
	return (`${day} de ${month} del ${anio}`);
};


export const isMobile = () => {
	const { innerWidth: width } = window;
	if(width < 1000) return true;
	return false;
};


export const formatCurrency = n => {
	if(!n) return '$' + 0;
	return '$' + new Intl.NumberFormat("de-DE", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	}).format(n);
};


export const formatInputCurrency = n => {
	if(!n) return '$' + 0;
	let ret = '$' + new Intl.NumberFormat("de-DE", {
		maximumFractionDigits: 2
	}).format(parseFloat(n));

	if(n.substr(-1) == '.') {
		ret += ',';
	}
	if(n.substr(-2) == '.0') {
		ret += ',0';
	}
	if(n.substr(-3) == '.00') {
		ret += ',00';
	}
	if(n.substr(-1) === '0' && ret.substr(-1) !== '0')
		ret += '0';

	return ret;
};


export const maskMoney = (value = "") => {
	if(value.substr(-1) == ',')
		value = value.replace(/.$/, ".");

	const arraySplit = value.split('.');
	if(arraySplit.length === 3)
		value = value.replace(/.$/, "");
	if(arraySplit.length == 2 && arraySplit[1].length == 3)
		value = value.replace(/.$/, "");

	value = value.replace(/[^0-9.]+/, '');

	return value;
};

export const capitalize = (str) => (str.charAt(0).toUpperCase() + str.slice(1));

export const getDatesFromPeriod = (value, start, end) => {
	if(value === '30d') return {
		startDate: moment().subtract(32, 'days').format("YYYY-MM-DD"),
		endDate: moment().add(1, 'days').format("YYYY-MM-DD")
	};
	if(value === '60d') return {
		startDate: moment().subtract(62, 'days').format("YYYY-MM-DD"),
		endDate: moment().add(1, 'days').format("YYYY-MM-DD")
	};
	if(value === 'thisMonth') return {
		startDate: moment().format("YYYY-MM-01"),
		endDate: moment().add(1, 'days').format("YYYY-MM-DD")
	};
	if(value === 'lastMonth') return {
		startDate: moment().subtract(32, 'days').format("YYYY-MM-01"),
		endDate: moment().format("YYYY-MM-01")
	};
	if(value === 'personalized') return {
		startDate: start,
		endDate: end
	};
};
