export default {
    singleStat: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    singleStatValue: {
        fontSize: 18,
        display: 'inline-block',
        color: '#5043dd',
        fontWeight: '500 !important',
        width: '100%',
        marginTop: -120,
        marginLeft: 100,
        verticalAlign: 'middle',
        color: 'rgba(0,0,0,0.7) !important'
    },
    singleStatLabel: {
        fontSize: 14,
        display: 'inline-block',
        color: '#2a2651',
        fontWeight: '500 !important',
        width: '100%',
        opacity: 0.8,
        marginTop: -240,
        marginLeft: 0,
        verticalAlign: 'middle'
    },
    singleStatIcon: {
        display: 'inline-block',
        width: 30,
        height: 30,
        padding: '14px 14px',
        marginTop: 28,
        marginLeft: 20,
        background: 'rgba(0,0,0,0.03)',
        fontSize: 20,
        verticalAlign: 'middle',
        color: 'white',
        textAlign: 'center',
        transform: 'scale(0.9)',
        borderRadius: 40
    },
    chartRoot: { 
        padding: '32px', 
        paddingTop: '12px', 
        paddingLeft: '28px', 
        height: 'calc(86% - 80px)',
        position: 'relative'
    },
    chartLabel: {
        fontSize: 13,
        color: 'gray',
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        top: 46,
        left: 0,
    },
    chartValue: {
        fontSize: 22,
        fontWeight: 500,
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        top: 3,
        left: 0,
        color: 'rgba(0,0,0,0.7)'
    },
    chartTitle: {
        fontSize: 14,
        marginBottom: 116,
        marginTop: -46,
        marginLeft: -26,
        color: '#2a2651',
        fontWeight: '500',
        opacity: 0.8
    }
};