import { useState, useEffect } from 'react';

// Services
import { getOrganizationSettings } from '../../../commons/Services';

const useOrganizationSettings = () => {
    const [organizationSettings, setOrganizationSettings] = useState(null);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        const res = await getOrganizationSettings();
        setOrganizationSettings(res.data);
    }

    return organizationSettings;
}

export default useOrganizationSettings;