import React, { useEffect, useState } from 'react';
import styles from './activity.module.scss';


const getStyledText = (string, inlineStyleRanges) => {
    let newString = string;
    inlineStyleRanges.forEach(element => {
        const start = element.offset;
        const end = element.offset + element.length;
        newString =
            newString.slice(0, start) + "<b>"
            + newString.slice(start, end) + "</b>"
            + newString.slice(end, newString.length);
    });
    return newString;
}

const Comment = (comment) => {
    const [blocks, setBlocks] = useState([]);
    useEffect(() => {
        const txt = comment?.comment?.body?.commentText;
        const parsedTxt = JSON.parse(txt);
        setBlocks(parsedTxt);
    }, []);

    return <div className={styles.commentActivity}>
        {
            blocks.map((block, idx) => {
                if (block.type === 'unordered-list-item')
                    return <li key={idx} dangerouslySetInnerHTML={{ __html: getStyledText(block.text, block.inlineStyleRanges)}}></li>
                return <p key={idx} dangerouslySetInnerHTML={{ __html: getStyledText(block.text, block.inlineStyleRanges)}}></p>;
            })
        }
    </div>;
}

export default Comment;