import React from 'react';

// Styles
import styles from './expensesSettings.module.scss';
import SwitchMU from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';


const Switch = withStyles({
    switchBase: {
        color: 'white',
        '&$checked': {
            color: 'white',
        },
        '&$checked + $track': {
            backgroundColor: '#704eff',
            opacity: 0.8
        },
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
        '&:hover + $track': {
            opacity: 0.6
        }
    },
    checked: {
    },
    track: {
        backgroundColor: 'rgba(0,0,0,0.3)',
        transformOrigin: 'middle center',
        height: 20,
        borderRadius: 20,
        transform: 'scale(1.2) scaleX(1.1)',
        '&:hover': {
            backgroundColor: '#704eff',
            opacity: 0.7
        }
    },
    thumb: {
        marginTop: 3,
        transform: 'scale(1)'
    }
})(SwitchMU);


const MailSettings = ({ settings, editSettings, isLoading }) => {
    return (
        <div className={isLoading ? styles.loadingSettingsPage : styles.settingsPage} >
            <div className={settings.usesCategories ? styles.setting : styles.disabledSetting} style={{ paddingBottom: 26 }}>
                <p className={styles.settingTittle}>Enviarme notificaciones por mail</p>
                <p className={styles.settingText}>Te avisaremos por email cuando tengas notificaciones sin leer</p>
                <Switch
                    name="emails"
                    checked={settings.enableSendEmails}
                    onChange={() => editSettings('enableSendEmails', !settings.enableSendEmails)}
                    className={styles.settingSwitch}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </div>
        </div>
    );
}

export default MailSettings;