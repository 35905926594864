import React, { useState, useContext } from 'react';
import styles from './styles';
// Components
import Modal from '../generics/Modal';
// Material ui
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { generateInvitationCode } from '../../commons/Services';
import RoleOption from './roleOption';
import { RadioGroup, LinearProgress } from '@material-ui/core';
import Button from '../generics/Button';
import { getCookie } from '../../commons/CookiesHelper';
import { UserContext } from '../../commons/UserContext';

const useStyles = makeStyles(styles);

const PurpleLinearProgress = withStyles((theme) => ({
	colorPrimary: {
		backgroundColor: 'rgba(97, 74, 217, 0.1)',
		height: 3
	},
	bar: {
		backgroundColor: '#5043dd',
	},
}))(LinearProgress);


const InviteModal = props => {
	const classes = useStyles();
	const { isOpen, handleClose, title, hideText, callback, loadingText, buttonText } = props;
	const [selectedRole, setSelectedRole] = useState('');
	const [invitationCode, setInvitationCode] = useState('');
	const [copied, setIsCopied] = useState(false);
	const [step, setStep] = useState('start');
	const [isWaiting, setIsWaiting] = useState(false);
	const { showSnackbar } = useContext(UserContext);

	const handleChange = e => {
		setSelectedRole(e.target.value);
	};

	const resetStateAndClose = () => {
		setSelectedRole('');
		setStep('start');
		setIsCopied(false);
		setInvitationCode('');
		handleClose();
		setIsWaiting(false);
	};

	const handleGenerateInvitationCode = async () => {
		setIsWaiting(true);
		if(callback) {
			await callback(selectedRole);
			setIsWaiting(false);
			return;
		}
		const codeResponse = await generateInvitationCode(selectedRole);
		if(codeResponse.status != 200) {
			if(codeResponse.status === 402) {
				showSnackbar("Haz alcanzado el límite máximo de tu plan", {
					actionText: 'Ver planes',
					action: () => { alert('Planes!') },
					duration: 5000
				});
				return;
			}
			// TODO
			showSnackbar("Hubo un problema al generar la invitación");
			return;
		}
		setInvitationCode(codeResponse.data.invitationCode);
		setStep('showingCode');
	};

	const copyCodeToClipboard = () => {
		navigator.clipboard.writeText(invitationCode.code);
		setIsCopied(true);
	};

	return (
		<Modal
			title={title}
			isOpen={isOpen}
			handleClose={resetStateAndClose}
		>
			<div className={classes.modalContainer}>
				{
					step == 'start' &&
					<>
						{!hideText && <p className={classes.modalLabel}>Elige el rol que tendrá el nuevo usuario:</p>}
						<RadioGroup aria-label="role" name="role" value={selectedRole} onChange={handleChange}>
							<RoleOption
								value="admin"
								title="Administrador"
								subtitle="Puede subir gastos, ver y editar todos los gastos de la organización."
							/>
							<RoleOption
								value="employee"
								title="Básico"
								subtitle="Sólo puede subir y ver sus propios gastos."
							/>
							{
								getCookie('role') === 'owner' &&
								<RoleOption
									value="owner"
									title="Propietario"
									subtitle="Todos los permisos, incluido administrar los medios de pago y planes de la organización."
								/>
							}
						</RadioGroup>
						<div className={classes.modalActions}>
							<Button
								style={{ float: 'right' }}
								disabled={selectedRole == '' || isWaiting}
								onClick={handleGenerateInvitationCode}
							>
								{buttonText || 'Generar código de invitación'}
							</Button>
						</div>
					</>
				}
				{
					step == 'loading' &&
					<>
						<PurpleLinearProgress />
						<p className={classes.loadingText}>
							{loadingText || 'Generando código de invitación...'}
						</p>
					</>
				}
				{
					step == 'showingCode' &&
					<div className="fadeInCard">
						<p className={classes.invitationCode}>{invitationCode.code}</p>
						<p className={classes.readyText}>Listo! Tu código podrá ser usado ilimitadas veces y vence en 48hs</p>
						<>
							{
								!copied ?
									<div className={classes.copyButton}>
										<Button onClick={copyCodeToClipboard}>Copiar Código</Button>
									</div> :
									<p className={classes.copiedCodeFeedback}>Copiado!</p>
							}
						</>

					</div>
				}
			</div>
		</Modal>
	);
};

export default InviteModal;