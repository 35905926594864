import React from 'react';
import styles from './paywall.module.scss';
import { getCookie } from '../../commons/CookiesHelper';
import { useHistory } from 'react-router-dom';

//Components
import Layout from '../_shared/Layout';
import Button from '../generics/Button';

const Paywall = () => {
    const isOwner = getCookie('isOwner') === 'true';
    const history = useHistory();

    const handleButtonClick = () => {
        history.push('/plans');
    };

    return (
        <Layout title={""}>
            {
                isOwner ?
                    <div className={styles.icon}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-credit-card-off" width="84" height="84" viewBox="0 0 24 24" stroke-width="1.5" stroke="#aaaaaa" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="3" y1="3" x2="21" y2="21" />
                            <path d="M9 5h9a3 3 0 0 1 3 3v8a3 3 0 0 1 -.128 .87" />
                            <path d="M18.87 18.872a3 3 0 0 1 -.87 .128h-12a3 3 0 0 1 -3 -3v-8c0 -1.352 .894 -2.495 2.124 -2.87" />
                            <line x1="3" y1="11" x2="11" y2="11" />
                            <line x1="15" y1="11" x2="21" y2="11" />
                            <line x1="7" y1="15" x2="7.01" y2="15" />
                            <line x1="11" y1="15" x2="13" y2="15" />
                        </svg>
                    </div> :
                    <div className={styles.icon}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-off" width="84" height="84" viewBox="0 0 24 24" stroke-width="1.5" stroke="#aaaaaa" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M20.042 16.045a9 9 0 0 0 -12.087 -12.087m-2.318 1.677a9 9 0 1 0 12.725 12.73" />
                            <path d="M3 3l18 18" />
                        </svg>
                    </div>
            }
            <div className={styles.text}>
                {
                    isOwner ?
                        'Suscripción inactiva, elige un plan para activar el accesso' :
                        'Organización inactiva, contáctate con tu administrador'
                }
            </div>
            {
                isOwner &&
                <div className={styles.button}>
                    <Button onClick={handleButtonClick}>Ver Planes</Button>
                </div>
            }
        </Layout>
    );
};

export default Paywall;

