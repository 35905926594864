import { useState } from 'react';


const useExpenseState = () => {
    const [fileName, setFileName] = useState('');
    const [completed, setCompleted] = useState(false);
    const [visible, setVisible] = useState(false);

    const setDownloadState = (field, value) => {
        if(field === 'fileName') setFileName(value);
        if(field === 'completed') setCompleted(value);
        if(field === 'visible') setVisible(value);
    }

    return [
        { fileName, completed, visible },
        setDownloadState
    ];
}

export default useExpenseState;