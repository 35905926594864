import React from 'react';

// Styles
import styles from './createExpense.module.scss';

const userOptions = [
    {
        label: 'Pagué yo',
        value: true
    },
    {
        label: 'Pagó la empresa',
        value: false
    }
];

const ButtonsInput = (props) => {
    const {
        value,
        setValue,
        visible
    } = props;

    return <div className={visible ? styles.buttons : styles.hiddenButtons}>
        {
            userOptions.map(option => {
                return <div
                    className={
                        value == option.value ?
                            styles.selectedButtonOption :
                            styles.buttonOption
                    }
                    key={option.value}
                    onClick={() => setValue(option.value)}
                >
                    {option.label}
                </div>
            })
        }
    </div>
}

export default ButtonsInput;