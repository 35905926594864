import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import styles from "./styles.js";
import customizeChart from "./customizeChart.js";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(styles);


const fakeData = {
    "status": "success",
    "data": [
        { "totalAmount": 320800, "day": '10/08' },
        { "totalAmount": 170750, "day": '09/08' },
        { "totalAmount": 286000, "day": '08/08' },
        { "totalAmount": 433060, "day": '07/08' },
        { "totalAmount": 362700, "day": '06/08' },
        { "totalAmount": 372000, "day": '05/08' },
        { "totalAmount": 297500, "day": '04/08' },
        { "totalAmount": 327900, "day": '03/08' },
        { "totalAmount": 205500, "day": '02/08' },
        { "totalAmount": 203600, "day": '01/08' },
        { "totalAmount": 290560, "day": '31/07' },
        { "totalAmount": 342000, "day": '30/07' },
        { "totalAmount": 400600, "day": '29/07' }
    ].reverse()
};


const LineChart = data => {
    const [chartData, setChartData] = useState({});
    const classes = useStyles();

    const chart = () => {
        let empSal = [];
        let empAge = [];
        
        for(const dataObj of data.data.reverse()) {
            empSal.push(parseInt(dataObj.totalAmount));
            empAge.push(dataObj.day);
        }

        var ctx = document.createElement('canvas').getContext("2d");
        var gradient = ctx.createLinearGradient(50, 50, 50, 400);
        gradient.addColorStop(0, "rgba(97, 74, 217, 0.1)");   
        gradient.addColorStop(0.4, "rgba(97, 74, 217, 0)");

        setChartData({
            labels: empAge,
            datasets: [
                {
                    label: "",
                    data: empSal,
                    backgroundColor: gradient,
                    borderColor: ["rgba(97, 74, 217, 0.7)"],
                    borderWidth: 2,
                    pointBackgroundColor: "rgba(97, 74, 217, 0.7)",
                    pointRadius: 0
                }
            ]
        });
    };

    useEffect(() => {
        chart();
    }, []);


    return (
        <div id="chart-card" className={classes.chartRoot}>
            <p className={classes.chartTitle}>Total de gasto diario</p>
            <p id="chart-label" className={classes.chartLabel}>-</p>
            <p id="chart-value" className={classes.chartValue}>$0,00</p>

            <Line
                data={chartData}
                options={{
                    elements: {
                        line: {
                            cubicInterpolationMode: 'monotone',
                        }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    title: { display: false },
                    tooltips: {
                        // Disable the on-canvas tooltip
                        enabled: false,
                        mode: 'index',
                        intersect: false,
                        animationDuration: 1000,
                        custom: function(tooltipModel, that=this) {
                            customizeChart(tooltipModel, that);
                        },
                    },
                    legend: {
                        display: false
                    },
                    point: {
                        radius: 20
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    min: 0.01,
                                    autoSkip: true,
                                    maxTicksLimit: 10,
                                    beginAtZero: true,
                                    callback: function (value, index, values) {
                                        if(value == 0.01) return 0;
                                        if(value < 2000) return '$' + value;
                                        return value / 1000 + 'k';
                                    },
                                    fontColor: 'rgba(0,0,0,0.3)',
                                    fontSize: 10,
                                },
                                gridLines: {
                                    display: true,
                                    color: 'rgba(0,0,0,0.03)'
                                }
                            }
                        ],
                        xAxes: [
                            {
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    maxTicksLimit: 15,
                                    fontColor: 'rgba(0,0,0,0.3)',
                                    fontSize: 10,
                                    display: true
                                },
                            }
                        ]
                    }
                }}
            />
        </div>
    );
};

export default LineChart;