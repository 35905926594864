import { useEffect, useState } from 'react';
import { getBillingInfo } from '../../../commons/Services';

const useGetBillingInfo = () => {
    const [billingInfo, setBillingInfo] = useState(null);

    const fetchPlans = async () => {
        const res = await getBillingInfo();
        setBillingInfo(res?.data);
    };

    useEffect(() => {
        fetchPlans();
    }, []);

    return [billingInfo];
};

export default useGetBillingInfo;
