import React from 'react';
import '../../App.scss';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpendrIcon from '../_shared/ExpendrIcon';


const useStyles = makeStyles(styles);


const SingleStat = (props) => {
    const classes = useStyles();

    const { label, value, color, icon } = props;

    return (
        <div className={classes.singleStat}>
            <div className={classes.singleStatIcon}>
                <ExpendrIcon icon={icon} stroke="#5043dd" />
            </div>
            
            <span className={classes.singleStatLabel}>{label}</span>
            <span style={{color}} className={classes.singleStatValue}>
                {value}
            </span>
        </div>
    );
}

export default SingleStat;