import React, { useMemo, useState, useContext } from 'react';
import styles from './profileCard.module.scss';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Button from '../generics/Button';
import InviteModal from '../members/InviteModal';
import Modal from '../generics/Dialog';

// Services
import { changeUserRole, removeUserFromOrganization } from '../../commons/Services';

// State
import { UserContext } from '../../commons/UserContext';
import { getCookie } from '../../commons/CookiesHelper';

const roleName = role => {
	switch(role) {
		case 'admin':
			return 'Administrador';

		case 'employee':
			return 'Básico';

		case 'owner':
			return 'Propietario';

		default:
			break;
	}
};

const UserProfile = (props) => {
	const { currentMember, members, goBack, fetchMembers } = props;
	const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
	const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
	const [isRemoveModalLoading, setIsRemoveModalLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const isOwner = getCookie('role') === 'owner';

	const user = useMemo(() => {
		return members.find(m => m.id === currentMember);
	}, [members, currentMember]);

	const handleRemoveUser = async () => {
		setIsRemoveModalLoading(true);
		const res = await removeUserFromOrganization(user.id);
		setIsRemoveModalLoading(false);
		setIsRemoveModalOpen(false);
		setIsLoading(true);
		await fetchMembers();
		goBack();
	};

	const openRoleModal = () => {
		setIsInviteModalOpen(true);
	};

	const openRemoveModal = () => {
		setIsRemoveModalOpen(true);
	};

	const handleChangeRole = async (role) => {
		const res = await changeUserRole(user.id, role);
		setIsLoading(true);
		res.status = 400;
		setIsInviteModalOpen(false);
		await fetchMembers();
		setIsLoading(false);
	};

	const showButtons = () => {
		if(user?.organizationRole === 'owner') return false;
		if(!isOwner && user?.organizationRole === 'admin') return false;
		return true;
	};

	return (
		<>
			<div onClick={goBack} className={styles.goBack}>
				<FontAwesomeIcon
					className={styles.arrowBack}
					icon={faArrowLeft}
				/>
				Volver a miembros
			</div>
			<div className={isLoading ? styles.loadingProfileLeftCard : styles.profileLeftCard}>
				<div style={{
					backgroundImage: user?.profilePhotoUrl ? `url(${user?.profilePhotoUrl})` : 'linear-gradient(90deg, rgba(250, 250, 250, 1) 0%, rgba(239, 239, 239, 1) 100%)'
				}} className={styles.userImage}>
				</div>
				<p className={styles.username}>
					{user?.fullName}
				</p>
				<div className={styles.email}>
					{user?.email}
				</div>
				<div className={styles.separator}></div>
				<div className={styles.roleBadge}>
					{roleName(user?.organizationRole)}
				</div>
			</div>
			{
				showButtons() &&
				<div style={isLoading ? { opacity: 0.3 } : {}}>
					<div className={styles.actionButton}>
						<Button onClick={openRemoveModal} type="disapprove">Expulsar miembro</Button>
					</div>
					<div className={styles.actionButton}>
						<Button onClick={openRoleModal} type="disapprove">Cambiar de rol</Button>
					</div>
				</div>
			}
			<Modal
				isOpen={isRemoveModalOpen}
				handleClose={() => setIsRemoveModalOpen(false)}
				title="Eliminar miembro"
				mainAction={handleRemoveUser}
			>
				<div className={styles.dialogContent}>
					<p className={styles.modalText}>Seguro que deseas eliminar el usuario?</p>
					<div className={styles.dialogButton}>
						<Button disabled={isRemoveModalLoading} onClick={handleRemoveUser}>
							Confirmar
						</Button>
					</div>
				</div>
			</Modal>
			<InviteModal
				isOpen={isInviteModalOpen}
				title="Cambiar de rol"
				hideText={true}
				buttonText={'Cambiar rol'}
				handleClose={() => setIsInviteModalOpen(false)}
				callback={handleChangeRole}
				loadingText="Cambiando rol..."
			/>
		</>
	);
};

export default UserProfile;;