import React, { useRef, useState, useEffect } from 'react';
import componentStyles from './plans.module.scss';


const PlansSelector = (props) => {
    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    const { plans, index, setPlan } = props;
    const plan = plans[index];

    const handleClickOutside = (event) => {
        if(ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
    };

    const openDropdown = () => {
        if(!open) {
            setOpen(true);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    const setPlanIndex = (index) => {
        setOpen(false);
        setPlan({ ...plans[index], index: index });
    };

    const value = `${plan.maxUsers} Usuarios`;

    return (
        <>
            <div onClick={openDropdown} className={componentStyles.fieldValue}>
                {
                    <span className={componentStyles.noValue}>{value}</span>
                }
                <div className={componentStyles.chevronIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="16" height="16" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLiinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="6 9 12 15 18 9" />
                    </svg>
                </div>
                {
                    open && <>
                        <div ref={ref} className={componentStyles.dropdownMenu}>
                            <span className={componentStyles.dropdownOption} onClick={() => setPlanIndex(2)}>{plans[2].maxUsers} Usuarios</span>
                            <span className={componentStyles.dropdownOption} onClick={() => setPlanIndex(3)}>{plans[3].maxUsers} Usuarios</span>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default PlansSelector;