import { useEffect } from 'react';

const useModalKeys = (closeModal, mainAction, isOpen) => {
    useEffect(() => {
        if(isOpen)
            document.addEventListener('keyup', handleKeyUp);
        return () => document.removeEventListener('keyup', handleKeyUp);
    }, [mainAction, closeModal, isOpen]);
    
    const handleKeyUp = (e) => {
        if (e.key === 'Enter' && isOpen) {
            e.stopImmediatePropagation();
            if (mainAction) mainAction();
        }
        if (e.key === 'Escape') {
            e.stopImmediatePropagation();
            if(closeModal) closeModal();
        }
    }
}

export default useModalKeys;