import React, { useEffect, useRef, useContext } from 'react';
import { logOut } from '../../../commons/Authentication';

//Material ui
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';

//Components
import Avatar from "../Avatar";

// State
import { UserContext } from '../../../commons/UserContext';
import { useHistory } from 'react-router-dom';


const UserPanel = props => {
    const { setIsOpen } = props;
    const { userInfo, setUserInfo } = useContext(UserContext);
    const ref = useRef(null);
    const history = useHistory();

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, []);

    const handleClickOutside = (event) => {
        if(ref.current && !ref.current.contains(event.target)) {
            setTimeout(() => {
                setIsOpen(false);
            })
        }
    };


    return (
        <div id="userPanel" ref={ref}>
            <div className="userPanelAvatar">
                <Avatar userInfo={userInfo} />
            </div>
                <p className="panelUsername">{userInfo.name}</p>
                <p className="panelUserEmail">{userInfo.email}</p>
                <p onClick={() => { logOut(history, setUserInfo) }} className="panelLogoutButton">Cerrar sesión</p>
        </div>
    );
}

export default UserPanel;