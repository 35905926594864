import React, { useEffect, useState } from 'react';
import styles from './refunds.module.scss';

// Utils
import { getRefundsHistory } from '../../commons/Services';

//Components
import ExpensesSkeleton from '../expensesList/ExpensesSkeleton'
import RefundItem from './RefundItem';
import Filters from './FiltersDiv';

// Icons
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RefundsHistory = props => {
    const [refunds, setRefunds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState('');

    useEffect(() => {
        fetchData();
    }, [search]);

    const fetchData = async () => {
        const refundsHistory = await getRefundsHistory(search);
        setRefunds(refundsHistory?.data?.data);
        setIsLoading(false);
    }

    const goBack = () => {
        props.goBack()
    }

    return (
        <div>
            <div onClick={goBack} className={styles.goBack} >
                <FontAwesomeIcon
                    className={styles.arrowBack}
                    icon={faArrowLeft}
                />
                Volver a reintegros
            </div>
            <Filters setSearch={setSearch} />
            <div className={styles.historyTable}>
                <div className={styles.tableHeader}>
                    <div className={styles.actionsColumn}>

                    </div>
                    <div className={styles.column}>
                        Miembro
                </div>
                    <div className={styles.column}>
                        Reintegrado por
                </div>
                    <div className={styles.smallColumn}>
                        Total
                </div>
                    <div className={styles.smallColumn}>
                        Fecha
                </div>
                </div>
                <div className={styles.historyList}>
                    {
                        isLoading && <ExpensesSkeleton />
                    }
                    {
                        !isLoading && refunds.length > 0 &&
                        refunds.map((refund, key) => {
                            return <RefundItem
                                key={key}
                                refund={refund}
                            />
                        })
                    }
                    {
                        refunds.length == 0 && !isLoading &&
                        <p>Sin resultados</p>
                    }
                </div>
            </div>
        </div>
    );
}

export default RefundsHistory;