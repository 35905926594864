import React, { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { changePassword } from '../../commons/Services';
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from '../../commons/UserContext';


const handleResponse = async (res, setIsLoading, showSnackbar, setIsFinished) => {
    setIsLoading(false);
    if(res?.status === 200) {
        setIsFinished(true);
        showSnackbar("Contraseña actualizada con éxito");
        return;
    }
    showSnackbar("Error al cambiar contraseña");
};


const PasswordRecovery = () => {
    const [newPassword, setNewPassword] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const history = useHistory();
    const { showSnackbar } = useContext(UserContext);
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');

    const validatePasswords = () => {
        if(password !== newPassword) {
            showSnackbar("Las contraseñas no coinciden");
            return false;
        }
        if(password.length < 6) {
            showSnackbar("Mínimo 6 caracteres");
            return false;
        }
        return true;
    };

    const handleKeyDown = (event) => {
        if(event.key === 'Enter') {
            sendRequest();
        }
    };

    const sendRequest = async () => {
        if(!validatePasswords()) {
            return;
        }
        setIsLoading(true);
        const response = await changePassword(newPassword, token);
        handleResponse(response, setIsLoading, showSnackbar, setIsFinished);
    };

    return (
        <div className="App loginApp" onKeyDown={handleKeyDown}>
            <div className="loginContainer">
                <div className="loginForm">
                    {
                        isFinished && <div style={{ marginTop: 80 }}>
                            <h1 className="logoLogin">expendr</h1>
                            <h3 id="loginHeadline">Contraseña actualizada</h3>
                            <Button
                                className={isLoading ? "disabledLoginButton" : "loginButton"}
                                variant="contained"
                                color="primary"
                                onClick={() => { history.push('/login'); }}
                            >
                                {'Ir al login'}
                            </Button>
                        </div>
                    }
                    {
                        !isFinished && <>
                            <h1 className="logoLogin">expendr</h1>
                            <h3 id="loginHeadline">Recuperar contraseña</h3>
                            <TextField
                                className="loginInput"
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                label="Nueva contraseña"
                                variant="outlined"
                                type="password"
                            />
                            <TextField
                                className="loginInput"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                label="Repetir nueva contraseña"
                                variant="outlined"
                                type="password"
                            />
                            <Button
                                className={isLoading ? "disabledLoginButton" : "loginButton"}
                                variant="contained"
                                color="primary"
                                onClick={() => { if(!isLoading) sendRequest(); }}
                            >
                                {'Enviar'}
                            </Button>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default PasswordRecovery;