import React, { useState, useEffect, useContext } from 'react';
// Components
import Layout from '../_shared/Layout';
import ExpensesTabs from './ExpensesTabs';
import ExpenseDetail from '../expenseDetail/ExpenseDetail';
import Filters from './FiltersDiv';
import Board from '../board/Board';
// Utils
import { getDatesFromPeriod } from '../../commons/Utils';
import { getCookie, setCookie } from '../../commons/CookiesHelper';
import moment from 'moment';
// Services
import { downloadFile, getBoardStatus, getExpenses, getExpensesFromUser } from '../../commons/Services';
// Hooks
import { useOpenExpensesModal } from './customHooks/useOpenExpensesModal';
import { useHistory } from 'react-router-dom';
// State
import { UserContext } from '../../commons/UserContext';

const ENDPOINT = "https://api.cafecafe.com.ar";
var timer = null;


const getStateDetails = (state, amountState) => {
	if(amountState === 'reimbursed') {
		return {
			stateText: 'Reintegrado',
			stateColor: '#00C853',
		}
	}
	switch(state) {
		case 'pending':
			return {
				stateText: 'Pendiente',
				stateColor: 'rgba(255,205,0,1)'
			};
		case 'approved':
			return {
				stateText: 'Aprobado',
				stateColor: 'rgba(0,205,100,0.7)'
			};
		case 'disapproved':
			return {
				stateText: 'Desaprobado',
				stateColor: 'rgba(255,0,0,0.7)'
			};
	}
};


const Expenses = () => {
	const [board, setBoard] = useState(false);
	const [expenses, setExpenses] = useState([]);
	const [selectedLayout, setSelectedLayout] = useState(getCookie('expensesLayout'));
	const [selectedExpense, setSelectedExpense] = useState(null);
	const [isSearching, setIsSearching] = useState(false);
	const [isFetching, setIsFetching] = useState(true);
	const [isDragging, setIsDragging] = useState(false);
	const [state, setState] = useState(null);
	const [search, setSearch] = useState('');
	const [userFilter, setUserFilter] = useState('');
	const [startDate, setStartDate] = useState(moment().subtract(32, 'days').format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
	const { setDownloadState } = useContext(UserContext);
	const permissions = getCookie('permissions') || 'basic';
	const history = useHistory();
	useOpenExpensesModal(setSelectedExpense);

	// Effects

	useEffect(() => {
		if(permissions === 'basic') {
			setCookie('expensesLayout', 'list');
			setSelectedLayout('list');
		}
	}, [isFetching]);

	useEffect(() => {
		getBoardExpenses();
	}, [userFilter, startDate, endDate]);

	useEffect(() => {
		getExpensesList(userFilter, null);
	}, [state, userFilter, startDate, endDate, permissions]);

	// End of effects

	const setDates = (value, start, end) => {
		const { startDate, endDate } = getDatesFromPeriod(value, start, end);
		setStartDate(startDate);
		setEndDate(endDate);
	};

	const getZipFile = async () => {
		const res = await downloadExpensesFile(userFilter, null, 'zip');
	};

	const getExcelFile = async () => {
		const res = await downloadExpensesFile(userFilter, null, 'excel');
	};

	const renderBoardResponse = data => {
		clearTimeout(timer);

		if(isDragging) {
			timer = setTimeout(() => {
				renderBoardResponse(data);
			}, 400);
			return;
		}

		setBoard(data);
	};

	const getBoardExpenses = async () => {
		setIsFetching(true);
		const boardResponse = await getBoardStatus(userFilter, startDate, endDate);
		boardResponse && setBoard(boardResponse);
		setIsFetching(false);
	};

	const downloadExpensesFile = async (userFilter, value, file = null) => {
		const searchValue = value != null ? value : search;
		if(!file) return;
		setDownloadState('visible', true);
		setDownloadState(
			'fileName',
			file === 'excel' ? `Listado.xlsx` : `Archivos-Adjuntos.zip`
		);
		setDownloadState('completed', false);
		await downloadFile(
			120,
			0,
			'DESC',
			state,
			searchValue,
			userFilter.toString(),
			startDate,
			endDate,
			file === 'excel',
			file === 'zip'
		);
		setDownloadState('completed', true);
		setTimeout(() => {
			setDownloadState('visible', false);
		}, 3000);
		return;
	};

	const getExpensesList = async (userFilter, value, file = null) => {
		setIsFetching(true);
		const searchValue = value != null ? value : search;
		const fetchExpenses = permissions === 'basic' ? getExpensesFromUser : getExpenses;
		const expensesResponse = await fetchExpenses(
			120,
			0,
			'DESC',
			state,
			searchValue,
			userFilter.toString(),
			startDate,
			endDate
		);
		expensesResponse && file === null && setExpenses(expensesResponse.data);
		if(selectedExpense) {
			handleOpenModal(selectedExpense.id, expensesResponse.data);
		}
		setIsFetching(false);
	};

	const handleOpenModal = async (id) => {
		if(id || id === 0) {
			history.push(`/expenses/${id}`);
			return;
		}
		history.push(`/expenses`);
	};


	return (
		<Layout title="Lista de gastos">
			<Filters
				isSearching={isSearching}
				setIsSearching={setIsSearching}
				search={search}
				setSearch={setSearch}
				getExpensesList={getExpensesList}
				setIsFetching={setIsFetching}
				selectedLayout={selectedLayout}
				setSelectedLayout={setSelectedLayout}
				expenses={expenses}
				setUserFilter={setUserFilter}
				userFilter={userFilter}
				setDates={setDates}
				dates={{ startDate, endDate }}
				getExcelFile={getExcelFile}
				getZipFile={getZipFile}
			/>
			{
				isSearching || selectedLayout == 'list' ?
					<div className="columnContainer height180">
						<div className="list12 height500 fadeInCard">
							<ExpensesTabs
								expenses={expenses}
								isFetching={isFetching}
								setIsFetching={setIsFetching}
								getExpensesList={value => getExpensesList(userFilter, value)}
								setState={setState}
								setSelectedExpense={handleOpenModal}
								getStateDetails={getStateDetails}
							/>
						</div>
					</div> :
					<Board
						setIsDragging={setIsDragging}
						setSelectedExpense={handleOpenModal}
						expenses={board}
						fetchExpenses={getBoardExpenses}
						setIsFetching={setIsFetching}
						isFetching={isFetching}
					/>
			}
			{
				selectedExpense &&
				<ExpenseDetail
					setSelectedExpense={handleOpenModal}
					refetchExpense={handleOpenModal}
					expense={selectedExpense}
					getStateDetails={getStateDetails}
					fetchExpenses={
						selectedLayout !== 'list' ?
							getBoardExpenses :
							() => getExpensesList(userFilter)
					}
				/>
			}
		</Layout>
	);
};

export default Expenses;