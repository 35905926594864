import { useEffect, useState, useContext } from 'react';

// Services
import { addCustomField, getCustomFields, editCustomField } from '../../../commons/Services';

// Utils
import { useParams, useHistory } from 'react-router';

import { UserContext } from '../../../commons/UserContext';

const initialState = {
    title: '',
    type: 'text',
    accessType: 'public',
    active: true,
    roles: [],
    active: true,
    required: false,
    showInOrganizationPayment: true,
    showInUserPayment: true
};

const initialSelectValues = {
    values: [],
    newSelectValues: [],
    selectValuesIdsToDelete: [],
    selectedOption: null,
};

const useExpenseState = () => {
    const { id } = useParams();
    const isEditing = id !== 'new';
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [formValues, setFormValues] = useState(initialState);
    const [isLoading, setIsLoading] = useState(!isEditing);
    const [selectValues, setSelectValues] = useState(initialSelectValues);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [pageTitle, setPageTitle] = useState('Crear campo extra');
    const { showSnackbar } = useContext(UserContext);
    const {
        title,
        type,
        accessType,
        roles,
        active,
        required,
        showInOrganizationPayment,
        showInUserPayment
    } = formValues;
    const history = useHistory();

    const { selectedOption, newSelectValues, selectValuesIdsToDelete, values } = selectValues;

    useEffect(() => {
        if(id !== 'new') fetchCustomFields();
        if(isEditing) setPageTitle('Editar campo extra');
        else setIsLoading(false);
    }, []);

    useEffect(() => {
        if(title.length > 1) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [title]);

    const fetchCustomFields = async () => {
        const response = await getCustomFields();
        const customFields = response?.data?.customFields;
        const currentCustomField = customFields?.find(customField => customField.id == id);
        if(!currentCustomField) {
            history.push('/settings/customFields');
            return;
        }
        setIsLoading(false);
        setSelectValues({ ...selectValues, values: currentCustomField.selectValues });
        const groupIds = currentCustomField.groups?.map(group => group.id);
        setSelectedGroups(groupIds || []);
        setFormValues({ ...formValues, ...currentCustomField });
    };

    const saveCustomField = async () => {
        let response = null;
        setIsLoading(true);
        let requestPayload =
            formValues.type === 'select' ?
                {
                    ...formValues,
                    selectValuesIdsToDelete,
                    newSelectValues,
                    selectValues: newSelectValues,
                    id
                } : { ...formValues, id };

        requestPayload = { ...requestPayload, groupIds: selectedGroups };
        if(id !== 'new') {
            response = await editCustomField({ ...requestPayload, selectValues: undefined });
        } else {
            response = await addCustomField(requestPayload);
        }
        setIsLoading(false);
        if(response?.status === 200 || response?.status === 201) {
            showSnackbar("Campo guardado");
            if(!isEditing)
                history.push('/settings/customFields');
        }
    };

    const updateFormValue = (name, value) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value
        }));
    };

    // Refactor this
    const setField = (field, value) => {
        switch(field) {
            case 'title':
                updateFormValue(field, value);
                break;

            case 'type':
                updateFormValue(field, value);
                break;

            case 'accessType':
                updateFormValue(field, value);
                updateFormValue('accessType', value);
                break;

            default:
                updateFormValue(field, value);
                break;

        }
    };

    const filterDeletedSelectValues = () => {
        const allValues = [...values, ...newSelectValues];
        const filteredValues = allValues.filter(value => !selectValuesIdsToDelete.includes(value?.id));
        return filteredValues;
    };

    const setSelectedOption = (option) => {
        setSelectValues((prevSelectValues) => ({
            ...prevSelectValues,
            selectedOption: prevSelectValues.selectedOption === option ? null : option
        }));
    };

    const createOption = (value) => {
        setSelectValues((prevSelectValues) => ({
            ...prevSelectValues,
            newSelectValues: [...prevSelectValues.newSelectValues, {
                value
            }]
        }));
    };

    const editOption = (value) => {
        const options = filterDeletedSelectValues();
        const editingOption = options[selectValues.selectedOption];
        if((selectedOption || selectedOption === 0) && options[selectedOption]?.id) {
            setSelectValues((prevSelectValues) => ({
                ...prevSelectValues,
                values: [
                    ...prevSelectValues.values.map(option => {
                        if(option.id === options[selectedOption].id) {
                            return {
                                ...option,
                                value
                            };
                        }
                        return option;
                    }),
                ]
            }));
        } else {
            setSelectValues((prevSelectValues) => ({
                ...prevSelectValues,
                newSelectValues: [
                    ...prevSelectValues.newSelectValues.map(option => {
                        if(option === editingOption) {
                            return {
                                ...option,
                                value
                            };
                        }
                        return option;
                    })
                ],
                selectedOption: null
            }));
        }
    };

    const deleteOption = () => {
        const options = filterDeletedSelectValues();
        if((selectedOption || selectedOption === 0) && options[selectedOption]?.id) {
            setSelectValues((prevSelectValues) => ({
                ...prevSelectValues,
                values: prevSelectValues.values.filter((_value, i) => i !== selectedOption),
                selectValuesIdsToDelete: [...prevSelectValues.selectValuesIdsToDelete, prevSelectValues.values[selectedOption].id],
                selectedOption: null
            }));
        } else {
            setSelectValues((prevSelectValues) => ({
                ...prevSelectValues,
                newSelectValues: prevSelectValues.newSelectValues.filter((option) => option !== options[selectedOption]),
                selectedOption: null
            }));
        }
    };

    const returnValues = {
        title,
        type,
        accessType,
        isButtonDisabled,
        isLoading,
        options: filterDeletedSelectValues(),
        selectedOption: selectedOption,
        pageTitle,
        roles,
        selectedGroups,
        active,
        required,
        showInOrganizationPayment,
        showInUserPayment
    };

    return [
        returnValues,
        { setField, saveCustomField, setSelectedOption, deleteOption, createOption, editOption, setSelectedGroups }
    ];
};

export default useExpenseState;
