import React, { useEffect, useState, useRef } from 'react';
import styles from '../expensesList/styles';
import "date-fns";
// Components
import Modal from '../generics/Modal';

// Material ui
import { makeStyles } from '@material-ui/core/styles';
import Button from '../generics/Button';

// Utils
import { formatInputCurrency, maskMoney } from "../../commons/Utils";

const useStyles = makeStyles(styles);

const CustomFieldModal = props => {
    const classes = useStyles();
    const {
        handleClose,
        changeAndSave,
        type,
        open,
        title,
        customField,
        label,
        initialAmountOwn,
        parentChangeValue,
        initialAmountCompany,
        skipClose,
        disableButton,
        buttonLabel
    } = props;
    const [value, setValue] = useState(customField?.value || '');
    const [amountOwn, setAmountOwn] = useState(initialAmountOwn);
    const [amountTotal, setAmountTotal] =
        useState((parseFloat(initialAmountCompany) + parseFloat(initialAmountOwn)).toString());
    const [error, setError] = useState("");
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);

    useEffect(() => {
        if(customField !== null && open) {
            setValue(customField.value || '');
            return;
        }
    }, [customField, open]);

    const handleAmountChange = amount => {
        const value = amount?.target?.value;
        setValue(maskMoney(value));
    };

    const handleAmountTotalChange = amount => {
        const value = amount?.target?.value;
        setAmountTotal(maskMoney(value));
    };

    const handleAmountOwnChange = amount => {
        const value = amount?.target?.value;
        setAmountOwn(maskMoney(value));
    };

    const clearError = () => {
        setTimeout(() => {
            setError("");
        }, 3000);
    };

    const mainAction = () => {
        if(type === 'amountOwn') {
            if(parseFloat(amountTotal) <= 0 || amountTotal === '') {
                inputRef.current.focus();
                setError("El monto total debe ser mayor a 0");
                clearError();
                return;
            }
            if(parseFloat(amountOwn) > parseFloat(amountTotal)) {
                inputRef.current.focus();
                setError("El monto a reintegrar no puede ser mayor al monto total");
                clearError();
                return;
            }
            const amountOwnValue = amountOwn === '' ? 0 : parseFloat(amountOwn);
            changeAndSave(type, { amountOwn: amountOwnValue, amountTotal, byPassEdit: true });
            parentChangeValue(type, { amountOwn: amountOwnValue, amountTotal });
            handleClose();
            return;
        }
        changeAndSave(
            type, value, customField?.customField?.id, customField?.id, customField?.customField
        );
        if(!skipClose)
            handleClose();
    };

    return (
        <Modal
            title={title}
            isOpen={open}
            handleClose={handleClose}
            mainAction={mainAction}
        >
            <div className={classes.modalContainer}>
                {
                    (type === 'customText' || type === 'description') &&
                    <div>
                        <p className={classes.dialogLabel}>{label || 'Ingresa un texto'}</p>
                        <input
                            className={classes.dialogInput}
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        />
                    </div>
                }
                {
                    type === 'customNumber' &&
                    <div>
                        <p className={classes.dialogLabel}>Ingresa un número</p>
                        <input
                            type="number"
                            className={classes.dialogInput}
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        />
                    </div>
                }
                {
                    type === 'customCurrency' &&
                    <div>
                        <p className={classes.dialogLabel}>Ingresa un valor</p>
                        <>
                            <p
                                className={classes.dialogInputCurrency}
                                onClick={() => inputRef.current.focus()}
                            >
                                <input
                                    className={classes.dialogInputCurrencyHidden}
                                    value={value}
                                    onChange={handleAmountChange}
                                    ref={inputRef}
                                />
                                {formatInputCurrency(value)}
                                <span></span>
                            </p>
                        </>
                    </div>
                }
                {
                    type === 'amountOwn' &&
                    <div>
                        <p className={classes.dialogLabel}>Monto total</p>
                        <>
                            <p
                                className={classes.dialogInputCurrency}
                                onClick={() => inputRef.current.focus()}
                            >
                                <input
                                    className={classes.dialogInputCurrencyHidden}
                                    value={amountTotal}
                                    onChange={handleAmountTotalChange}
                                    ref={inputRef}
                                />
                                {formatInputCurrency(amountTotal)}
                                <span></span>
                            </p>
                        </>
                        <p className={classes.dialogLabel}>Cantidad a reintegrar</p>
                        <>
                            <p
                                className={classes.dialogInputCurrency}
                                onClick={() => inputRef2.current.focus()}
                            >
                                <input
                                    className={classes.dialogInputCurrencyHidden}
                                    value={amountOwn}
                                    onChange={handleAmountOwnChange}
                                    ref={inputRef2}
                                />
                                {formatInputCurrency(amountOwn)}
                                <span></span>
                            </p>
                        </>
                        <i className={classes.dialogError}>
                            {error}
                        </i>
                    </div>
                }
                <div className={classes.modalActions}>
                    <Button
                        style={{ float: 'right' }}
                        onClick={mainAction}
                        disabled={disableButton}
                    >
                        {buttonLabel || 'Aplicar'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CustomFieldModal;