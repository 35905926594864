import React, { useState } from 'react';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFile
} from '@fortawesome/free-solid-svg-icons';

// Components


const FilesContainer = (props) => {
    const [showPdfPreview, setShowPdfPreview] = useState(false);
    const {
        attachments,
        setIsImageOpen,
        classes
    } = props;

    const onLoad = () => {
        setTimeout(() => setShowPdfPreview(true), 5000);
    }

    return <>
        {
            attachments && attachments[0] &&
            attachments.map((file, idx) => {
                return <div key={idx} className={classes.filePlaceholder}>
                    {
                        file.url.substring(file.url.length - 4) === ".pdf" ?
                            <div className={classes.pdfPreview} onClick={() => window.open(`http://docs.google.com/gview?url=${file.url}`)}>
                                <iframe
                                    id="pdfIframe"
                                    src={`http://docs.google.com/gview?url=${file.url}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                                    style={{ width: 182, height: 170, pointerEvents: 'none', position: 'absolute' }}
                                    frameBorder="0"
                                    onLoad={onLoad}
                                >
                                </iframe>
                                <div className={classes.pdfPreview} style={!showPdfPreview ? {} : { display: 'none' }}>
                                    <FontAwesomeIcon className={classes.pdfPreviewSvg} icon={faFile} />
                                    <p className={classes.pdf}>PDF</p>
                                </div>
                            </div> :
                            <div onClick={() => setIsImageOpen(idx)} className={classes.imageContainer}>
                                {<img src={file.url} className={classes.img} />}
                            </div>
                    }
                </div>
            })
        }
    </>
}

export default FilesContainer;