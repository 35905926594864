import React from 'react';
import styles from './createCustomField.module.scss';

import Button from '../generics/Button';
import { Link } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const PageHeader = (props) => {
    const { isButtonDisabled, onSave, showAdvancedSettings, setShowAdvancedSettings } = props;

    return (
        <div className={styles.pageHeader}>
            {
                showAdvancedSettings ?
                    <>
                        <div onClick={() => setShowAdvancedSettings(false)}>
                            <FontAwesomeIcon
                                className={styles.backArrow}
                                icon={faArrowLeft}
                            />
                            <h2 className={styles.sectionTitle}>
                                Volver a editar campo personalizado
                            </h2>
                        </div>
                    </> :
                    <>
                        <Link to="/settings/customFields">
                            <FontAwesomeIcon
                                className={styles.backArrow}
                                icon={faArrowLeft}
                            // onClick={goBack}
                            />
                            <h2 className={styles.sectionTitle}>
                                Volver a configuración
                            </h2>
                        </Link>
                    </>
            }

            <div className={styles.saveButton}>
                <Button disabled={isButtonDisabled} onClick={onSave}>
                    Guardar
                </Button>
            </div>
            <hr className={styles.topSeparator} />
        </div>
    );
};

export default PageHeader;