import React, { useState, useEffect } from 'react';
// Components
import Modal from '../generics/Dialog';
import Button from '../generics/Button';
// Styles
import styles from './expensesSettings.module.scss';
import SwitchMU from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import ExpendrIcon from '../_shared/ExpendrIcon';
// Services
import { getCategories, addCategory, deleteCategory } from '../../commons/Services';


const Categories = ({ goBack }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        const categories = await getCategories();
        setCategories(categories.data?.categories);
    }

    const createCategory = async () => {
        setButtonDisabled(true);
        if(newCategory.length > 1) {
            const res = await addCategory(newCategory);
            if(res.status == 201) {
                setIsModalOpen(false);
                setNewCategory("");
                fetchCategories();
            } else {
                setButtonDisabled(true);
            }
        }        
    }

    const openDeleteModal = id => {
        setIsDeleteButtonDisabled(false);
        setIsDeleteModalOpen(true); 
        setSelected(id);
    }

    const deleteCategoryRequest = async () => {
        const res = await deleteCategory(selected);
        setIsDeleteModalOpen(false);
        setIsDeleteButtonDisabled(true);
        fetchCategories();
    }

    const updateCategory = value => {
        if(value.length > 1)
            setButtonDisabled(false);
        else
            setButtonDisabled(true);
        setNewCategory(value);
    }


    return (
        <div style={{paddingBottom: 40}}>
            <FontAwesomeIcon className={styles.backArrow} icon={faArrowLeft} onClick={goBack} />
            <h2 className={styles.sectionTitle}>Categorías</h2>
            <div className={styles.addCategoryButton}>
                <Button onClick={() => setIsModalOpen(true)} icon={faPlus}>
                    Agregar categoría
                </Button>
            </div>
            <div className={styles.settingsPaper}>
                {
                    categories.map(category => {
                        return <div className={styles.category}>
                            {category.name}
                            <ExpendrIcon 
                                onClick={() => openDeleteModal(category.id)} 
                                className={styles.deleteCategory} 
                                icon={'trash'} 
                            />
                        </div>;
                    })
                }
            </div>
            <Modal
                isOpen={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                title="Agregar categoría"
                mainAction={createCategory}
            >
                <div className={styles.dialogContent}>
                    <label className={styles.dialogLabel} >Ingresa el nombre</label>
                    <input 
                        className={styles.dialogInput} 
                        onChange={e => updateCategory(e.target.value)} 
                        value={newCategory}
                    />
                    <div className={styles.dialogButton}>
                        <Button disabled={buttonDisabled} onClick={createCategory}>
                            Agregar
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isDeleteModalOpen}
                handleClose={() => setIsDeleteModalOpen(false)}
                title="Eliminar categoría"
                mainAction={deleteCategoryRequest}
            >
                <div className={styles.dialogContent}>
                    <p className={styles.modalText}>Seguro que deseas eliminar la categoría?</p>
                    <div className={styles.dialogButton}>
                        <Button  disabled={deleteButtonDisabled} onClick={deleteCategoryRequest}>
                            Confirmar
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Categories;