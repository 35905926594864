import React, { useState } from 'react';

// Utils
import { makeStyles } from '@material-ui/core/styles';
import { getStringFromDate, formatCurrency } from '../../commons/Utils';

// Icons
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { Link } from 'react-router-dom';
import Avatar from '../_shared/Avatar';


const useStyles = makeStyles({
    member: {
        paddingTop: 8,
        minHeight: 68,
        width: '100%',
        borderBottom: '1px solid rgba(0,0,0,0.06)',
        overflow: 'hidden',
        transition: '.3s'
    },
    avatar: {
        height: 40,
        width: 40,
        borderRadius: 30,
        marginTop: -27,
        marginBottom: 0,
        marginLeft: 20,
        display: 'inline-block',
        verticalAlign: 'middle',
        '@media (max-width: 600px)': {
            marginLeft: 12
        }
    },
    user: {
        display: 'block',
        verticalAlign: 'middle',
        marginTop: 13,
        fontWeight: 500,
        fontSize: 13,
        marginLeft: 12,
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        color: 'rgba(0,0,0,0.6)',
        textTransform: 'Capitalize',
        '@media (max-width: 600px)': {
            marginLeft: 10
        }
    },
    amount: {
        color: '#5043dd',
        float: 'right',
        marginRight: 20,
        fontWeight: 600,
        fontSize: 15,
        marginTop: 20,
        '@media (max-width: 600px)': {
            marginRight: 4
        }
    },
    description: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginTop: 22,
        fontSize: 14,
        fontWeight: 400,
        marginLeft: '-2%',
        color: 'rgba(0,0,0,0.4)'
    },
    email: {
        display: 'block',
        verticalAlign: 'middle',
        marginTop: -10,
        fontSize: 12,
        marginLeft: 16,
        color: 'gray',
        '@media (max-width: 600px)': {
            marginLeft: 10
        }
    },
    column: {
        height: '100%',
        width: 'calc(90% - 10px)',
        maxWidth: 200,
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    actionsColumn: {
        height: '100%',
        width: 'calc(90% - 10px)',
        maxWidth: 60,
        display: 'inline-block',
        verticalAlign: 'middle',
        fontSize: 12
    },
    smallColumn: {
        float: 'right',
        height: '100%',
        width: 'calc(10% + 10px)',
        display: 'inline-block',
        verticalAlign: 'middle',
        minWidth: 90
    },
    amount: {
        float: 'right',
        height: '100%',
        width: 'calc(10% + 10px)',
        display: 'inline-block',
        verticalAlign: 'middle',
        color: '#5043dd',
        fontWeight: 500,
        paddingTop: 20,
        minWidth: 90
    },
    date: {
        float: 'right',
        height: '100%',
        width: 'calc(10% + 10px)',
        display: 'inline-block',
        verticalAlign: 'middle',
        color: 'gray',
        fontSize: 12,
        fontWeight: 500,
        paddingTop: 22,
        minWidth: 90
    },
    info: {
        display: 'inline-block',
        marginTop: 8,
        width: 'calc(55% +  10px)'
    },
    menuIcon: {
        marginTop: 14,
        borderRadius: 22,
        padding: '9px 16px',
        marginRight: 16,
        fontSize: 16,
        transform: 'scaleY(1.1)',
        color: 'rgba(0,0,0,0.3)',
        float: 'right',
        '&:hover': {
            background: 'rgba(0,0,0,0.03)',
            color: 'gray'
        },
        '@media (max-width: 600px)': {
            marginRight: 6
        }
    },
    stateIndicator: {
        height: 10,
        width: 10,
        borderRadius: 20,
        background: 'rgba(255,205,0,0.6)',
        display: 'inline-block',
        marginRight: 6,
        marginTop: -2,
        verticalAlign: 'middle'
    },
    role: {
        padding: '4px 12px',
        borderRadius: 16,
        verticalAlign: 'middle',
        marginLeft: 0,
        marginTop: 18,
        paddingLeft: 2,
        float: 'right',
        fontSize: 13,
        display: 'inline-block',
        color: '#5043dd'
    },
    action: {
        marginTop: 10,
        marginLeft: 20,
        padding: 10,
        paddingTop: 8,
        width: 12,
        height: 14,
        borderRadius: 20,
        color: 'gray',
        cursor: 'pointer',
        '&:hover': {
            background: 'rgba(0,0,0,0.05)',
        }
    },
    detailContainer: {
        minHeight: 20,
        width: 'calc(100% - 80px)',
        paddingLeft: 80,
        paddingTop: 20,
        paddingBottom: 10
    },
    expenseDetails: {
        display: 'block',

    },
    expenseDescription: {
        display: 'inline-block',
        fontSize: 13,
        color: 'rgba(0,0,0,0.5)',
        marginRight: 20,
        marginTop: 0,
        cursor: 'pointer',
        fontWeight: 500,
        textDecoration: 'none',
        '&:hover': {
            color: '#5043dd',
        }
    },
    expenseAmount: {
        display: 'inline-block',
        fontSize: 13,
        color: 'rgba(0,0,0,0.3)',
        marginRight: 10,
        marginTop: 2
    }
});


const RefundItem = props => {
    const classes = useStyles();
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const {
        date,
        totalAmount,
        admin,
        user,
        expenses
    } = props.refund;

    return (
        <div className={classes.member}>
            <div className={classes.actionsColumn}>
                <div
                    className={classes.action}
                    onClick={() => setIsDetailOpen(!isDetailOpen)}
                >
                    <FontAwesomeIcon
                        className="icon"
                        icon={isDetailOpen ? faChevronUp : faChevronDown}
                    />
                </div>
            </div>
            <div className={classes.column}>
                <div className={classes.avatar}>
                    <Avatar userInfo={user} />
                </div>
                <div className={classes.info}>
                    <p className={classes.user}>{user.fullName}</p>
                </div>
            </div>
            <div className={classes.column}>
                <div className={classes.avatar}>
                    <Avatar userInfo={admin} />
                </div>
                <div className={classes.info}>
                    <p className={classes.user}>{admin.fullName}</p>
                </div>
            </div>
            <div className={classes.amount}>
                {formatCurrency(totalAmount)}
            </div>
            <div className={classes.date}>
                {getStringFromDate(date)}
            </div>
            {
                isDetailOpen &&
                <div className={classes.detailContainer}>
                    {
                        expenses?.map(expense => {
                            return <div className={classes.expenseDetails}>
                                <Link
                                    target='_blank'
                                    to={`/expenses/${expense.id}`}
                                    className={classes.expenseDescription}
                                >
                                    {expense.description}
                                </Link>
                                <p className={classes.expenseAmount}>
                                    {formatCurrency(expense.totalAmount)}
                                </p>
                            </div>
                        })
                    }
                </div>
            }
        </div>
    );
}

export default RefundItem;