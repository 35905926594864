import React, { useEffect, useRef } from 'react';
import { markNotificationAsRead } from '../../../commons/Services';

// Utils
import { getStringFromDate } from '../../../commons/Utils';

//Components
import SidePanel from '../../generics/SidePanel';
import Portal from '../../_shared/Portal';
import Avatar from '../Avatar';

const NotificationsPanel = props => {
    const { setIsOpen, notifications, fetchNotifications } = props;

    useEffect(() => {
        notifications.forEach(notification => {
            if(!notification.read) {
                markNotificationAsRead(notification.id);
            }
        });
    }, []);

    const closePanel = (close) => {
        setIsOpen(close);
        fetchNotifications();
    }

    return (
        <Portal>
            <SidePanel
                title="Notificaciones"
                open={true}
                setIsOpen={closePanel}
            >
                <div className="notificationsContainer">
                    {
                        notifications.map((notification) => {
                            return <div
                                key={notification.id}
                                className={notification.read ? "notificationContainer" : "unreadNotificationContainer"}
                            >
                                <div className="notificationAvatar">
                                    <Avatar
                                        userInfo={notification.user}
                                    // className="notificationAvatar"
                                    />
                                </div>
                                <p className="notificationDate">{getStringFromDate(notification.date)}</p>
                                <p className="notificationTitle">{notification.user.fullName}</p>
                                <p className="notificationText">{notification.title}:</p>
                                <p onClick={() => window.open(`/expenses/${notification.expenseId}`)} className="notificationDescription">{notification.description}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <polyline points="9 6 15 12 9 18" />
                                    </svg>
                                </p>
                            </div>;
                        })
                    }
                </div>
            </SidePanel>
        </Portal>
    );
}

export default NotificationsPanel;