import React from 'react';


const ExportFileTooltip = props => {
    const {
        setIsOpen,
        getZipFile,
        getExcelFile
    } = props;

    const handleGetZip = async () => {
        setTimeout(() => setIsOpen(false));
        getZipFile();
    }

    const handleGetExcel = async () => {
        setTimeout(() => setIsOpen(false));
        getExcelFile();
    }

    return (
        <div className='layoutTooltip'>
            <label>Exportar resultados como:</label>
            <div onClick={handleGetExcel}>
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2a2651" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="4" y="4" width="16" height="16" rx="2" />
                    <line x1="4" y1="10" x2="20" y2="10" />
                    <line x1="10" y1="4" x2="10" y2="20" />
                </svg>
                <p>Excel</p>
            </div>
            <div onClick={handleGetZip}>
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2a2651" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 4h3l2 2h5a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                    <path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h2" />
                </svg>
                <p>Zip con archivos</p>
            </div>
        </div>
    );
}

export default ExportFileTooltip;