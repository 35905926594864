import React, { useEffect, useState } from 'react';

// Components
import SelectorModal from '../generics/SelectorModal';

// Services
import { getCategories } from '../../commons/Services';

const CategoryModal = props => {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {
        handleClose,
        handleSelect,
        open
    } = props;

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        const res = await getCategories();
        if (res.data) {
            setCategories(res.data.categories);
            setIsLoading(false);
        }
    }

    return (
        <>
            {
                open ?
                    <SelectorModal
                        placeholder="Buscar categoría..."
                        handleClose={handleClose}
                        handleSelect={handleSelect}
                        options={categories}
                        isLoading={isLoading}
                    /> : null
            }
        </>
    );
}

export default CategoryModal;