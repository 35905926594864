export default {
	// Button:
	root: {
		background: '#2a2651',
		color: 'white',
		fontWeight: '600',
		borderRadius: 40,
		padding: '10px 24px',
		display: 'inline-block',
		fontSize: 14,
		cursor: 'pointer',
		transition: '.2s',
		verticalAlign: 'middle',
		opacity: 1,
		'&:hover': {
			opacity: 0.9
		}
	},
	disabledRoot: {
		background: '#2a2651',
		color: 'white',
		fontWeight: '600',
		borderRadius: 40,
		padding: '10px 12px',
		display: 'inline-block',
		fontSize: 14,
		cursor: 'default',
		transition: '.2s',
		verticalAlign: 'middle',
		opacity: 0.4
	},
	approve: {
		background: 'transparent',
		color: '#00BB44',
		border: '1px solid rgba(0,0,0,0.1)',
		fontWeight: '500',
		borderRadius: 40,
		padding: '6px 14px',
		display: 'inline-block',
		fontSize: 14,
		cursor: 'pointer',
		transition: '.2s',
		verticalAlign: 'middle',
		opacity: 0.6,
		'&:hover': {
			opacity: 1
		}
	},
	disapprove: {
		background: 'transparent',
		color: 'red',
		border: '1px solid rgba(0,0,0,0.1)',
		fontWeight: '500',
		borderRadius: 4,
		padding: '8px 14px',
		display: 'inline-block',
		fontSize: 14,
		cursor: 'pointer',
		transition: '.2s',
		verticalAlign: 'middle',
		opacity: 0.6,
		'&:hover': {
			opacity: 1
		}
	},
	buttonIcon: {
		fontSize: 12,
		marginRight: 8,
		verticalAlign: 'middle',
		marginTop: -3
	},
	// Modal:
	backdrop: {
		position: 'fixed',
		height: '100vh',
		width: '100vw',
		background: 'rgba(0,0,0,0.1)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center middle',
		top: 0,
		left: 0,
		backdropFilter: 'blur(3px)',
		zIndex: 190,
		animationName: '$backdropAnimation',
		animationDuration: '.1s'
	},
	modal: {
		background: 'white',
		borderRadius: 4,
		minHeight: '450px',
		maxHeight: '850px',
		boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 13px 0px',
		display: 'inline-block',
		minWidth: 300,
		maxWidth: 960,
		margin: 'auto',
		marginTop: '50vh',
		position: 'relative',
		transform: 'translateY(-50%)',
		animationName: '$zoomIn',
		animationDuration: '.1s',
		overflow: 'hidden'
	},
	unmountedModal: {
		background: 'white',
		borderRadius: 4,
		minHeight: '460px',
		maxHeight: '850px',
		boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 13px 0px',
		display: 'inline-block',
		minWidth: 300,
		maxWidth: 960,
		margin: 'auto',
		marginTop: '50vh',
		position: 'relative',
		transform: 'translateY(-50%) scale(0.9)',
		transition: 'all .1s linear'
	},
	unmountedBackdrop: {
		position: 'fixed',
		height: '100vh',
		width: '100vw',
		background: 'rgba(0,0,0,0.1)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center middle',
		top: 0,
		left: 0,
		backdropFilter: 'blur(4px)',
		zIndex: 90,
		opacity: 0,
		transition: 'all .1s linear'
	},
	unmountedDialog: {
		background: 'white',
		borderRadius: 8,
		minHeight: '260px',
		maxHeight: '30vh',
		boxShadow: 'rgba(0, 0, 0, 0.3) 0px 16px 70px',
		width: '90% !important',
		minWidth: '300px',
		maxWidth: 460,
		margin: 'auto',
		marginTop: '40vh',
		position: 'relative',
		transform: 'translateY(-50%) scale(0.9)',
		padding: 20,
		transition: 'all .1s linear'
	},
	dialog: {
		background: 'white',
		borderRadius: 8,
		minHeight: '260px',
		maxHeight: '30vh',
		boxShadow: 'rgba(0, 0, 0, 0.3) 0px 16px 70px',
		width: '90% !important',
		minWidth: '300px',
		maxWidth: 460,
		margin: 'auto',
		marginTop: '40vh',
		position: 'relative',
		transform: 'translateY(-50%)',
		padding: 20,
		animationName: '$zoomIn',
		animationDuration: '.1s',
	},
	modalHeader: {
		height: 24,
		width: '100%',
		borderBottom: '1px solid rgba(0,0,0,0.03)',
		padding: '20px 28px'
	},
	dialogHeader: {
		height: 24,
		width: '100%',
		padding: '10px 28px',
		paddingLeft: 10
	},
	modalTitle: {
		fontSize: 18,
		fontWeight: 400,
		color: '#5043dd',
		margin: 1
	},
	dialogTitle: {
		fontSize: '14px !important',
		fontWeight: '500 !important',
		color: 'rgba(0, 0, 0, 0.7) !important',
		marginTop: '2px !important'
	},
	cerrarBtn: {
		color: 'red',
		position: 'absolute',
		margin: '0px !important',
		fontWeight: 500,
		top: 15,
		right: 24,
		cursor: 'pointer',
		height: 34,
		width: 35,
		borderRadius: 30,
		transition: '.4s',
		'&:hover': {
			background: 'rgba(0,0,0,0.07)'
		}
	},
	dialogCerrarBtn: {
		position: 'absolute',
		margin: '0px !important',
		fontWeight: 500,
		top: 24,
		right: 34,
		cursor: 'pointer',
		height: 32,
		width: 32,
		borderRadius: 30,
		transition: '.4s',
		'&:hover': {
			background: 'rgba(0,0,0,0.07)'
		}
	},
	closeIcon: {
		transform: 'scaleX(1.25)',
		fontSize: 16,
		color: 'rgba(0,0,0,0.6)',
		fontFamily: 'nunito',
		margin: 0,
		marginTop: 6,
		width: '99%',
		textAlign: 'center'
	},
	'@keyframes zoomIn': {
		from: { 
			transform: 'translateY(-50%) scale(0.9)'
		},
		to: {
			transform: 'translateY(-50%)'
		}
	},
	'@keyframes backdropAnimation': {
		from: { 
			opacity: 0
		},
		to: {
			opacity: 1
		}
	},
};