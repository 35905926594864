import React, { useState, useEffect } from 'react';
// Material ui
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// Components
import ExpenseItem from './ExpenseItem.js';
import ExpensesSkeleton from './ExpensesSkeleton.js';


const getTabState = tab => {
    switch(tab) {
        case 1:
            return 'pending';
        case 2:
            return 'approved';
        case 3:
            return 'disapproved';
        default:
            return null;
    }
}


const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid rgba(0,0,0,0.07)'
    },
    indicator: {
        backgroundColor: '#5043dd',
        height: 3
    },
})(Tabs);


const AntTab = withStyles(theme => ({
    root: {
        fontFamily: 'Montserrat',
        textTransform: 'none',
        minWidth: 22,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(3),
        '&:hover': {
            color: '#5043dd',
            opacity: 1,
        },
        '&$selected': {
            color: '#5043dd',
        },
        '&:focus': {
            color: '#5043dd',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);


const ExpensesTabs = props => {
    const [value, setValue] = useState(0);
    const {
        setSelectedExpense,
        expenses,
        isFetching,
        setIsFetching,
        setState,
        getStateDetails
    } = props;

    const handleChange = (_event, newValue) => {
        setState(getTabState(newValue))
        setValue(newValue);
        setIsFetching(true);
    };


    return (
        <>
            <div className="tabsGastos">
                <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                    <AntTab label="Todos" />
                    <AntTab label="Pendientes" />
                    <AntTab label="Aprobados" />
                    <AntTab label="Desaprobados" />
                </AntTabs>
                <div className="gastosList">
                    {
                        !isFetching && expenses && expenses.length === 0 &&
                        <p className="noResults">Sin resultados</p>
                    }
                    {
                        !isFetching &&
                        Array.isArray(expenses) &&
                        expenses?.map((expense, key) => {
                            return <ExpenseItem
                                setSelectedExpense={setSelectedExpense}
                                getStateDetails={getStateDetails}
                                key={key}
                                expense={expense}
                            />
                        })
                    }
                    {
                        isFetching && <ExpensesSkeleton />
                    }
                </div>
            </div>
        </>
    );
}

export default ExpensesTabs;