import React, { useRef } from 'react';
import styles from './styles';
import { makeStyles } from '@material-ui/core/styles';
import componentStyles from './ExpenseField.module.scss';


const useStyles = makeStyles(styles);


const ExpenseField = (props) => {
    const classes = useStyles();
    const ref = useRef(null);

    const { label, value, isVisible, stateStyles, stateComponent, onClick, isEditable = false } = props;

    return (
        <>
            {
                isVisible && <>
                    <label className={classes.label}>{label}</label>
                    <div ref={ref} onClick={(e) => {onClick && onClick(e, ref)}} className={isEditable ? componentStyles.fieldValue : componentStyles.lockedFieldValue} style={stateStyles}>
                        {stateComponent || value}
                        {
                            !stateComponent && !value && <span className={componentStyles.noValue}>Sin seleccionar</span>
                        }
                    </div>
                </>
            }
        </>
    );
};

export default ExpenseField;