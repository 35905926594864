import React, { useEffect, useRef, useState } from 'react';
import styles from './sidePanel.module.scss';


const SidePanel = props => {
    const { setIsOpen, open, title, children } = props;
    const [isHidden, setIsHidden] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, []);

    const handleClickOutside = (event) => {
        if(ref.current && !ref.current.contains(event.target)) {
            hide(false);
        }
    };

    const hide = () => {
        setIsHidden(true);
        setTimeout(() => {
            setIsOpen(false);
            setIsHidden(false);
        }, 400);
    }

    return (
        <>
            {
                open &&
                <div className={!isHidden ? styles.backdrop : styles.hiddenBackdrop}>
                    <div className={!isHidden ? styles.container : styles.hiddenContainer} ref={ref}>
                        <div className={styles.header}>
                            <svg onClick={hide} className={styles.arrowBack} width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                            <h3 className={styles.title}>{title}</h3>
                        </div>
                            {
                                children
                            }
                    </div>
                </div>
            }
        </>
    );
}

export default SidePanel;