import React from 'react';

// Icons
import { faDollarSign, faReceipt, faUser } from '@fortawesome/free-solid-svg-icons';

const DashboardSkeleton = () => {

    return (
        <>
            <div className="dashboardContainerSkeleton height120">
                <div className="dashboardCard card4">
                    <div className="SingleStatIconSkeleton pulse"></div>
                    <div className="statSkeleton">
                        <div className="statValueSkeleton pulse"></div>
                    </div>
                </div>
                <div className="dashboardCard card4">
                    <div className="SingleStatIconSkeleton pulse"></div>
                    <div className="statSkeleton">
                        <div style={{ width: 60 }} className="statValueSkeleton pulse"></div>
                    </div>
                </div>
                <div className="dashboardCard card4">
                    <div className="SingleStatIconSkeleton pulse"></div>
                    <div className="statSkeleton">
                        <div style={{ width: 60 }} className="statValueSkeleton pulse"></div>
                    </div>
                </div>
            </div>
            <div className="dashboardContainer height200">
                <div style={{ paddingLeft: 4 }} className="dashboardCard card8">
                    <div style={{ opacity: 0.5 }} className="chartSkeleton pulse"></div>
                </div>
            </div>
        </>
    );
}

export default DashboardSkeleton;