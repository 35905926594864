import React, { useState } from 'react';
import styles from './styles';
import { makeStyles } from '@material-ui/core/styles';
import CustomFieldModal from './CustomFieldModal';

// Utils
import { getFullStringFromDate, formatCurrency, getDateFromApiString } from '../../commons/Utils.js';
import moment from 'moment';

// Components
import ExpenseField from './ExpenseField';
import DateModal from '../createExpense/DateModal';
import StateSelector from './dropdownInputs/StateSelector';

// Hooks
import useEditExpenseState from './customHooks/useEditExpenseState';

const useStyles = makeStyles(styles);


const RightPanel = (props) => {
    const classes = useStyles();
    const {
        isRightContainerOpen,
        expense,
        isLoadingState,
        getStateTextAndColor,
        changeExpenseState,
        parentChangeValue,
        fetchExpense,
        categories
    } = props;
    const [
        { openedModal, editingFields, tooltipPosition },
        { openModal, closeModal, changeAndSave, setTooltipPosition }
    ] = useEditExpenseState(changeExpenseState, fetchExpense);
    const [selectedCustomField, setSelectedCustomField] = useState(null);


    const { stateColor, stateText } = getStateTextAndColor(
        editingFields['expenseState'] || null
    );

    const getFieldValue = (name, id, cf = null) => {
        if(name === 'date') {
            return editingFields['date'] ?
                getFullStringFromDate(editingFields['date']) :
                getFullStringFromDate(expense.date);
        }
        if(name === 'custom') {
            return editingFields[id] ?
                editingFields[id] :
                getCustomFieldValue(cf);
        }
        if(name === 'category') {
            return editingFields['category'] ?
                categories?.find(cat => cat.id === editingFields['category'])?.name :
                expense.category?.name;
        }
        if(name === 'amountOwn') {
            return editingFields['amountOwn'] || editingFields['amountOwn'] == 0 ?
                formatCurrency(editingFields['amountOwn']) :
                formatCurrency(expense.amountOwn);
        }
    };

    const changeTooltipPosition = (refElement) => {
        const offset = refElement.current.getBoundingClientRect();
        const top = offset.top + 1;
        const right = window.innerWidth - offset.left;
        if(offset.top > ((window.innerHeight / 2) + 100)) {
            setTooltipPosition({
                bottom: window.innerHeight - (offset.top + 34),
                right: right,
                top: 'auto',
            });
            return;
        }
        setTooltipPosition(
            {
                top,
                right,
                bottom: 'auto',
            }
        );
    };

    const getCustomFieldValue = (cf) => {
        if(editingFields[cf.id] || editingFields['cf:' + cf?.customField?.id]) {
            if(cf.id === null) {
                return editingFields['cf:' + cf?.customField?.id];
            }
            return editingFields[cf.id] || editingFields['cf:' + cf?.customField?.id];
        }
        if(cf.customField.type === 'select') {
            return cf.selectValue?.value;
        }
        if(cf.customField.type === 'currency') {
            return formatCurrency(cf.value);
        }
        return cf.value;
    };

    const getModalNameFromFieldName = (name) => {
        if(name === 'text') {
            return 'customText';
        }
        if(name === 'select') {
            return 'customSelect';
        }
        if(name === 'number') {
            return 'customNumber';
        }
        if(name === 'date') {
            return 'customDate';
        }
        if(name === 'currency') {
            return 'customCurrency';
        }
        return null;
    };

    const getModalTitle = (name) => {
        if(name === 'amount') {
            return 'Editar monto';
        }
        if(name === 'amountOwn') {
            return 'Editar monto';
        }
        return selectedCustomField?.customField?.title;
    };

    const compareFunction = (a, b) => {
        return a.customField.id - b.customField.id;
    };

    const selectOptions = [
        'categories',
        'expenseState',
        'customSelect'
    ];

    const modalOptions = [
        'customText',
        'customNumber',
        'customDate',
        'customCurrency',
        'amountOwn',
        'amount'
    ];


    return (
        <>
            <div className={isRightContainerOpen ? classes.rightContainerOpen : classes.rightContainer}>
                <div className={classes.infoContent}>
                    <ExpenseField
                        label="Fecha"
                        value={getFieldValue("date")}
                        isVisible={true}
                        isEditable={true}
                        onClick={() => openModal('date')}
                    />
                    <ExpenseField
                        label={expense.amountState == 'reimbursed' ? 'Total reintegrado' : 'Total a reintegrar'}
                        value={getFieldValue("amountOwn")}
                        isVisible={true}
                        isEditable={expense.amountState !== 'reimbursed'}
                        onClick={() => openModal('amountOwn')}
                    />
                    <ExpenseField
                        label='Categoría'
                        value={getFieldValue("category")}
                        isVisible={expense.category}
                        isEditable={true}
                        onClick={(_e, refElement) => {
                            changeTooltipPosition(refElement);
                            openModal('categories');
                        }}
                    />
                    <ExpenseField
                        label='Estado'
                        stateStyle={isLoadingState ? { opacity: 0.3 } : {}}
                        stateComponent={<>
                            <div className={classes.stateIndicator} style={{ background: stateColor }}></div>
                            {stateText}
                        </>}
                        isEditable={openedModal !== 'expenseState' && expense.amountState !== 'reimbursed'}
                        isVisible={true}
                        onClick={(_e, refElement) => {
                            if(expense.amountState === 'reimbursed') return;
                            changeTooltipPosition(refElement);
                            openModal('expenseState');
                        }}
                    />
                    {
                        expense.customFields && expense.customFields.length > 0 &&
                        expense.customFields.sort(compareFunction).map((field) => {
                            return field.customField && (
                                <ExpenseField
                                    key={field.customField.id}
                                    label={field.customField.title}
                                    value={getCustomFieldValue(field)}
                                    isVisible={true}
                                    isEditable={true}
                                    onClick={(_e, refElement) => {
                                        setSelectedCustomField(field);
                                        if(field.customField.type === 'select') {
                                            changeTooltipPosition(refElement);
                                            openModal(getModalNameFromFieldName(field.customField.type));
                                            return;
                                        } else {
                                            openModal(getModalNameFromFieldName(field.customField.type));
                                        }
                                    }}
                                />
                            );
                        })
                    }
                </div>
            </div>
            <DateModal
                handleClose={() => closeModal()}
                handleSelect={val => changeAndSave('date', moment(val).format('YYYY-MM-DD'))}
                dateValue={getDateFromApiString(expense.date)}
                open={openedModal === 'date'}
            />
            <StateSelector
                style={tooltipPosition}
                open={selectOptions.includes(openedModal)}
                setIsOpen={() => {
                    closeModal();
                }}
                handleSelect={val => changeAndSave('expenseState', val)}
                selectedCustomField={selectedCustomField}
                isCustomSelect={openedModal === 'customSelect'}
                changeAndSave={changeAndSave}
                categories={categories}
                isCategory={openedModal === 'categories'}
            />
            <CustomFieldModal
                handleClose={closeModal}
                changeAndSave={changeAndSave}
                open={modalOptions.includes(openedModal)}
                type={openedModal}
                title={getModalTitle(openedModal)}
                customField={selectedCustomField}
                initialAmountOwn={(expense?.amountOwn || 0).toString()}
                initialAmountCompany={(expense?.amountCompany || 0).toString()}
                parentChangeValue={parentChangeValue}
            />
        </>
    );
};

export default RightPanel;