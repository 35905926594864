import React from 'react';

// Styles
import styles from './createExpense.module.scss';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPen
} from '@fortawesome/free-solid-svg-icons';


const ExpenseDataItem = (props) => {
    const {
        label,
        value,
        children,
        editable,
        visible = true,
        onClick
    } = props;

    return <>
        {
            visible ?
                <div
                    className={styles.item}
                    onClick={onClick}
                >
                    <div className={editable ? styles.editableDescription : styles.description}>
                        <label>{label}</label>
                        {value || children || <>Sin seleccionar</>}
                        {
                            editable &&
                            <FontAwesomeIcon icon={faPen} />
                        }
                    </div>

                </div> : null
        }
    </>;
};

export default ExpenseDataItem;