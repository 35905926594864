import React, { useState } from 'react';
import Tooltip from '../../generics/Tooltip';

import styles from './stateSelector.module.scss';

const StateSelector = props => {
    const {
        open,
        setIsOpen,
        style,
        handleSelect,
        isCustomSelect,
        selectedCustomField,
        changeAndSave,
        categories,
        isCategory
    } = props;

    const [search, setSearch] = useState("");

    const compareSearch = elem => {
        const re = new RegExp(search.toLowerCase(), 'g');
        if(elem.value?.toLowerCase().match(re)) {
            return true;
        }
    };

    const selectState = (state) => {
        setIsOpen(false);
        handleSelect(state);
    };

    const selectCustom = (id) => {
        setIsOpen(false);
        changeAndSave(
            'customSelect',
            id,
            selectedCustomField?.customField?.id,
            selectedCustomField?.id,
            selectedCustomField?.customField
        );
    };

    const selectCategory = (id) => {
        setIsOpen(false);
        changeAndSave(
            'category',
            id,
        );
    };

    const shouldFilterOptions = selectedCustomField?.customField?.selectValues?.length > 6;

    const filteredOptions =
        search !== "" && shouldFilterOptions ?
            (selectedCustomField?.customField?.selectValues || []).filter(compareSearch) :
            (selectedCustomField?.customField?.selectValues || []);

    return (
        <Tooltip open={open} setIsOpen={setIsOpen} style={style}>
            <div className={styles.layoutTooltip}>
                {
                    isCustomSelect &&
                    <>
                        {
                            shouldFilterOptions ?
                                <input
                                    placeholder={`Buscar en ${selectedCustomField?.customField?.title}...`}
                                    type="text"
                                    className={styles.searchInput}
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                /> : <label>{selectedCustomField?.customField?.title}</label>
                        }
                        <div className={styles.optionsDiv}>
                            {
                                filteredOptions.map((selectValue, i) => {
                                    return <div key={i} className={styles.optionDiv} onClick={() => selectCustom(selectValue.id)}>
                                        <p>{selectValue.value}</p>
                                    </div>;
                                })
                            }
                        </div>
                    </>
                }
                {
                    isCategory && <>
                        <label>Categoría:</label>
                        {
                            categories?.map((category, i) => {
                                return <div key={i} className={styles.optionDiv} onClick={() => selectCategory(category?.id)}>
                                    <p>{category?.name}</p>
                                </div>;
                            })
                        }
                    </>
                }
                {
                    !isCustomSelect && !isCategory &&
                    <>
                        <label>Estado:</label>
                        <div className={styles.optionDiv} onClick={() => selectState('pending')}>
                            <div className={styles.stateIndicator} style={{ borderColor: 'rgba(255,205,150,0.7)' }}></div>
                            <p>Pendiente</p>
                        </div>
                        <div className={styles.optionDiv} onClick={() => selectState('approved')}>
                            <div className={styles.stateIndicator} style={{ borderColor: '#00C853BB' }}></div>
                            <p>Aprobado</p>
                        </div>
                        <div className={styles.optionDiv} onClick={() => selectState('disapproved')}>
                            <div className={styles.stateIndicator} style={{ borderColor: 'rgba(255,100,100,0.5)' }}></div>
                            <p>Desaprobado</p>
                        </div>
                    </>
                }

            </div>
        </Tooltip>
    );
};

export default StateSelector;