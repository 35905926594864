import React, { useState } from 'react';
import styles from './createCustomField.module.scss';

// Components
import SmallButton from "../generics/SmallButton.js";

// Material
import { Checkbox } from '@material-ui/core';
import Modal from '../generics/Dialog';
import Button from '../generics/Button.js';

const capitalize = (s) => {
    if(typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const SelectOptions = (props) => {
    const { options, deleteOption, editOption, createOption, selectedOption, setSelectedOption } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [optionName, setOptionName] = useState("");
    const [isEditMode, setIsEditMode] = useState(false);

    const openEditModal = () => {
        setIsModalOpen(true);
        setIsEditMode(true);
        setOptionName(options[selectedOption]?.value);
    }

    const openCreateModal = () => {
        setIsModalOpen(true);
        setIsEditMode(false);
        setOptionName("");
    }

    const submit = () => {
        if(isEditMode) {
            editOption(optionName, selectedOption);
        } else {
            createOption(optionName, selectedOption);
        }
        setIsModalOpen(false);
    }


    return (
        <div style={{ marginTop: 60, minHeight: 400, paddingBottom: 60 }}>
            <hr className={styles.topSeparator} />
            <label className={styles.label}>Opciones de selección</label>
            <SmallButton onClick={openCreateModal}>Crear opción</SmallButton>
            <SmallButton onClick={openEditModal} disabled={!selectedOption && selectedOption !== 0}>Editar opción</SmallButton>
            <SmallButton onClick={deleteOption} disabled={!selectedOption && selectedOption !== 0}>Eliminar</SmallButton>
            <div className={styles.options}>
                {
                    options.length > 0 && options.map((option, index) => {
                        const checked = selectedOption === index;
                        return (
                            <div onClick={() => setSelectedOption(index)} className={styles.option} key={index}>
                                <Checkbox
                                    checked={checked}
                                    style={{
                                        color: checked ? "#5043dd" : "lightgray",
                                        pointerEvents: 'none',
                                        transform: 'scale(0.9)',
                                        marginRight: 12,
                                        // marginRight: 10,
                                        padding: 0,
                                    }}
                                />
                                <p>{capitalize(option?.value)}</p>
                            </div>
                        );
                    })
                }
                {
                    options.length === 0 &&
                    <div className={styles.noOptions}>
                        <p>No hay opciones creadas</p>
                        Tu campo no será visible hasta que no hayas creado al menos dos opciones.
                    </div>
                }
            </div>
            <Modal
                isOpen={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                title={isEditMode ? "Editar opción" : "Crear opción"}
                mainAction={submit}
            >
                <div className={styles.dialogContent}>
                    <label className={styles.dialogLabel} >Ingresa el nombre</label>
                    <input 
                        className={styles.dialogInput} 
                        onChange={e => setOptionName(e.target.value)} 
                        value={optionName}
                    />
                    <div className={styles.dialogButton}>
                        <Button disabled={optionName.length < 1} onClick={submit}>
                            {isEditMode ? 'Editar' : 'Agregar'}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default SelectOptions;