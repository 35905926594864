import React, { useState } from 'react';
import styles from './styles';
// Material ui
import { makeStyles } from '@material-ui/core/styles';

// Hooks
import useModalKeys from './customHooks/useModalKeys';

const useStyles = makeStyles(styles);


const Modal = props => {
	const classes = useStyles();
	const { handleClose, title, children, isOpen, mainAction, hideCloseButton } = props;
	const [isMounted, setIsMounted] = useState(true);
	useModalKeys(handleClose, mainAction, isOpen);

	const closeModal = () => {
		setIsMounted(false);
		setTimeout(() => {
			setIsMounted(true);
			handleClose();
		}, 150);
	}

	return (
		<>
			{
				isOpen &&
				<div className={isMounted ? classes.backdrop : classes.unmountedBackdrop}>
					<div className={classes.columnContainer}>
						<div className={isMounted ? classes.modal : classes.unmountedModal}>
							<div className={classes.modalHeader}>
								<h2 className={classes.modalTitle}>{title}</h2>
								{
									!hideCloseButton &&
									<div className={classes.cerrarBtn} onClick={closeModal}>
										<p className={classes.closeIcon}>X</p>
									</div>
								}
							</div>
							{children}
						</div>
					</div>
				</div>
			}
		</>
	);
}

export default Modal;