import React, { useState, useEffect } from 'react';
import membersStyles from '../members/styles';

// Components
import Layout from '../_shared/Layout.js';
import PageHeader from './PageHeader.js';
import MemberItem from '../members/MemberItem.js';
import ExpensesSkeleton from '../expensesList/ExpensesSkeleton';

//Material ui
import { makeStyles } from '@material-ui/core/styles';

// Hooks
import useCreateGroupState from './customHooks/useCreateGroupState';
import styles from './groups.module.scss';


const useStyles = makeStyles(membersStyles);

const CreateGroup = () => {
	const [groupState, actions] = useCreateGroupState();
	const [isSearching, setIsSearching] = useState(false);
	const [search, setSearch] = useState('');
	const {
		name,
		isButtonDisabled,
		isLoading,
		members,
		users,
		id
	} = groupState;
	const { setField, saveGroup } = actions;
	const classes = useStyles();

	const compareSearch = userObj => {
		if(!isSearching) return true;
		const re = new RegExp(search.toLowerCase(), 'g');
		if(userObj.fullName.toLowerCase().match(re)) {
			return true;
		}
		if(search.length < 2) return true;
	};

	return (
		<Layout title={id === 'new' ? 'Crear grupo' : 'Editar grupo'} isLoading={isLoading}>
			<div style={{ position: "relative" }}>
				<PageHeader
					isButtonDisabled={isButtonDisabled}
					onSave={saveGroup}
				/>
				<div style={{ height: "75vh", paddingTop: 80, overflow: 'auto', marginRight: "-48px", paddingRight: 41 }}>
					<label className={styles.label}>Nombre del grupo</label>
					<input
						label="Ingresa un nombre..."
						onChange={e => setField('name', e.target.value)}
						value={name}
						type="text"
						className={styles.textInput}
					/>
					<label className={styles.label}>Miembros</label>
					<div className="tabsGastos">
						<div className={classes.membersTable}>
							{
								members.filter(compareSearch).map((member, key) => {
									return <MemberItem
										enableSelection={true}
										key={key}
										member={member}
										checked={users.find(user => member.id === user.id)}
										isClickable={false}
										toggleChecked={() => {
											const newUsers = users.filter(user => user.id !== member.id);
											if(!users.find(user => member.id === user.id)) {
												newUsers.push(member);
											}
											setField('users', newUsers);
										}}
									/>;
								})
							}
							{
								members.length > 0 && members.filter(compareSearch) == 0 &&
								<p className={classes.noResults}>Sin resultados</p>
							}
							{
								members.length == 0 && <ExpensesSkeleton />
							}
						</div>
						<div className={styles.separator}></div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default CreateGroup;