import React, { useState, useRef, useEffect } from 'react';
import styles from './styles';

//Material ui
import { makeStyles } from '@material-ui/core/styles';

//Icons
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Components
import LayoutToggleButton from './LayoutToggleButton';
import ExportFileTooltip from './ExportFileTooltip';
import DateInput from './DateInput';
import Avatar from '../_shared/Avatar.js';
import Tooltip from '../generics/Tooltip';

// Utils
import { getMembers } from '../../commons/Services';
import { getCookie } from '../../commons/CookiesHelper';


const useStyles = makeStyles(styles)

const getUserListFromExpenses = async () => {
    const users = await getMembers();
    return users.users;
}


const Filters = props => {
    const [timeOut, setTimeOut] = useState(null);
    const [tooltip, setTooltip] = useState(null);
    const {
        isSearching,
        setIsSearching,
        search,
        setSearch,
        getExpensesList,
        setIsFetching,
        selectedLayout,
        setSelectedLayout,
        children,
        userFilter,
        setUserFilter,
        setDates,
        getZipFile,
        getExcelFile
    } = props;
    const classes = useStyles();
    const inputRef = useRef(null);
    const permissions = getCookie('permissions') || 'basic';
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        if (isSearching) {
            inputRef.current.focus();
        }
    }, [isSearching]);

    const handleInputChange = value => {
        setSearch(value);
        clearTimeout(timeOut);
        setTimeOut(setTimeout(function () {
            getExpensesList(userFilter, value);
            setIsFetching(true);
        }, 500));
    }

    const fetchUsers = async () => {
        const res = await getUserListFromExpenses();
        setUsers(res);
    }

    return (
        <div className={classes.filtersDiv}>
            {
                isSearching ?
                    <div className={classes.searchBar}>
                        <FontAwesomeIcon icon={faSearch} className={classes.searchIcon} />
                        <input
                            className={classes.searchInput}
                            value={search}
                            onChange={e => handleInputChange(e.currentTarget.value)}
                            placeholder="Buscar por descripción, miembro, precio...."
                            ref={inputRef}
                        />
                        <span className={classes.closeIcon} onClick={() => { setIsSearching(false); }}>X</span>
                    </div> :
                    <>
                        <div className={classes.searchButton} onClick={() => { setIsSearching(true); }}>
                            <FontAwesomeIcon icon={faSearch} className={classes.searchIcon} />
                            {search === '' ? 'Buscar...' : search}
                        </div>
                        <div className={classes.userFilter}>
                            {
                                users?.slice(0, 6).map((user, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                userFilter === user.id ?
                                                    classes.avatarSelected :
                                                    classes.avatar
                                            }
                                            onClick={() => setUserFilter(
                                                userFilter === user.id ?
                                                    '' :
                                                    user.id
                                            )}
                                        >
                                            <Avatar userInfo={user} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={classes.actionsDiv}>
                            {children}
                        </div>
                        <DateInput setDates={setDates} />
                        <div className={classes.inlineButton} onClick={() => setTooltip('layout')} style={permissions !== 'admin' ? { display: 'none' } : {}}>
                            <div className={classes.layoutButton}>
                                <svg className={classes.svgButton} xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2a2651" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <line x1="4" y1="6" x2="20" y2="6" />
                                    <line x1="4" y1="12" x2="20" y2="12" />
                                    <line x1="4" y1="18" x2="16" y2="18" />
                                </svg>
                            </div>
                            <Tooltip open={tooltip === 'layout'} setIsOpen={setTooltip} top={50} right={1}>
                                <LayoutToggleButton
                                    setIsOpen={setTooltip}
                                    selectedLayout={selectedLayout}
                                    setSelectedLayout={setSelectedLayout}
                                    setTooltip={setTooltip}
                                />
                            </Tooltip>
                        </div>
                        <div className={classes.inlineButton} onClick={() => setTooltip('export')} style={permissions !== 'admin' ? { display: 'none' } : {}}>
                            <div className={classes.exportButton}>
                                <svg className={classes.svgButton} style={{ height: 20 }} xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2a2651" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                    <path d="M11.5 21h-4.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v5m-5 6h7m-3 -3l3 3l-3 3" />
                                </svg>
                            </div>
                            <Tooltip open={tooltip === 'export'} setIsOpen={setTooltip} top={50} right={-10}>
                                <ExportFileTooltip
                                    setIsOpen={setTooltip}
                                    getExcelFile={getExcelFile}
                                    getZipFile={getZipFile}
                                />
                            </Tooltip>
                        </div>
                    </>
            }
        </div>
    );
}

export default Filters;