import React from 'react';

// Styles
import styles from './expensesSettings.module.scss';
import SwitchMU from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';


const Switch = withStyles({
    switchBase: {
        color: 'white',
        '&$checked': {
            color: 'white',
        },
        '&$checked + $track': {
            backgroundColor: '#704eff',
            opacity: 0.8
        },
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
        '&:hover + $track': {
            opacity: 0.6
        }
    },
    checked: {
    },
    track: {
        backgroundColor: 'rgba(0,0,0,0.3)',
        transformOrigin: 'middle center',
        height: 20,
        borderRadius: 20,
        transform: 'scale(1.2) scaleX(1.1)',
        '&:hover': {
            backgroundColor: '#704eff',
            opacity: 0.7
        }
    },
    thumb: {
        marginTop: 3,
        transform: 'scale(1)'
    }
})(SwitchMU);


const ExpenseSettings = ({ settings, editSettings, isLoading, setView }) => {
    return (
        <div className={isLoading ? styles.loadingSettingsPage : styles.settingsPage}>
            <div className={settings.usesCategories ? styles.setting : styles.disabledSetting}>
                <p className={styles.settingTittle}>Categorizar gastos</p>
                <p className={styles.settingText}>Si activas ésta opción se pedirá el campo categoría al subir el gasto. También podrás ver métricas según categorías</p>
                <Switch
                    name="categories"
                    checked={settings.usesCategories}
                    onChange={() => editSettings('usesCategories', !settings.usesCategories)}
                    className={styles.settingSwitch}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <div className={styles.settingFooter}>
                    <p className={styles.settingAction} onClick={() => setView('categories')}>
                        Elige que categorías se muestran
					<FontAwesomeIcon className={styles.actionIcon} icon={faChevronRight} />
                    </p>
                </div>
            </div>
            <div className={styles.setting}>
                <p className={styles.settingTittle}>Campos extra</p>
                <p className={styles.settingText}>Crear o editar campos extra que aparecen en el gasto, como "ciudad", "factura" o "moneda".</p>
                <div className={styles.settingFooter}>
                    <p className={styles.settingAction} onClick={() => setView('customFields')}>
                        Ir a campos extra
					    <FontAwesomeIcon className={styles.actionIcon} icon={faChevronRight} />
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ExpenseSettings;