import React, { useEffect, useRef } from 'react';

//Material ui
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';

//Components
import SidePanel from '../../generics/SidePanel';
import Portal from '../../_shared/Portal';

const SettingsPanel = props => {
    const { setIsOpen } = props;
    const ref = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const handleClickOutside = (event) => {
        if(ref.current && !ref.current.contains(event.target)) {
            setTimeout(() => {
                setIsOpen(false);
            });
        }
    };


    return (
        <Portal>
            <SidePanel
                title="Configuración"
                open={true}
                setIsOpen={setIsOpen}
            >
                <Link to="/settings/expense" className="settingsLink" onClick={() => setIsOpen(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2" />
                        <path stroke="#4a2681" d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 0" />
                    </svg>
                    <div className="settingsText">
                        Gastos
                        <p>Configura los datos que contiene un gasto</p>
                    </div>
                </Link>
                <Link to="/settings/notification" className="settingsLink" onClick={() => setIsOpen(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path stroke="#4a2681" d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                        <path stroke="#4a2681" d="M9 17v1a3 3 0 0 0 6 0v-1" />
                        <path strokeWidth="1.3" d="M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727" />
                        <path strokeWidth="1.3" d="M3 6.727a11.05 11.05 0 0 1 2.792 -3.727" />
                    </svg>
                    <div className="settingsText">
                        Notificaciones
                        <p>Elige que acciones se te notificarán</p>
                    </div>
                </Link>
                <Link to="/settings/mail" className="settingsLink" onClick={() => setIsOpen(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect stroke="#4a2681" x="3" y="5" width="18" height="14" rx="2" />
                        <polyline points="3 7 12 13 21 7" />
                    </svg>
                    <div className="settingsText">
                        Mails
                        <p>Elige que mails te enviamos</p>
                    </div>
                </Link>
                <Link to="/groups" className="settingsLink" onClick={() => setIsOpen(false)} style={{ height: 56, paddingTop: 10 }}>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect stroke="#4a2681" x="3" y="5" width="18" height="14" rx="2" />
                        <polyline points="3 7 12 13 21 7" />
                    </svg> */}
                    <svg style={{ marginTop: 10 }} width="44" height="42" viewBox="0 0 24 24" strokeWidth="1" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle stroke="#4a2681" cx="9" cy="7" r="4" />
                        <path stroke="#4a2681" d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                        <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                    </svg>
                    <div className="settingsText">
                        Grupos
                        <p>Crea grupos de usuarios para personalizar sus gastos</p>
                    </div>
                </Link>
            </SidePanel>
        </Portal>
    );
};

export default SettingsPanel;