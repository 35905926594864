import React from 'react';
import styles from './styles';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(styles);


const ModalSkeleton = () => {
    const classes = useStyles();

    return (
        <div className={classes.expensesSkeleton}>
            {/* <div className="attachmentsSkeleton pulse"></div> */}
            {
                [...Array(5)].map(
                    (e, i) => 
                    <div className={classes.itemSkeleton} key={i}>
                        <div className="textSkeleton pulse"></div>
                        <div className="textSkeletonSmall pulse"></div>
                    </div>
                )
            }
        </div>
    );
}

export default ModalSkeleton;