import React from 'react';
import styles from './groups.module.scss';

import Button from '../generics/Button';
import { Link } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const PageHeader = (props) => {
    const { isButtonDisabled, onSave } = props;

    return (
        <div className={styles.pageHeader}>
            <Link to="/groups">
                <FontAwesomeIcon
                    className={styles.backArrow}
                    icon={faArrowLeft}
                // onClick={goBack}
                />
                <h2 className={styles.sectionTitle}>Volver a grupos</h2>
            </Link>
            <div className={styles.saveButton}>
                <Button disabled={isButtonDisabled} onClick={onSave}>
                    Guardar
                </Button>
            </div>
            <hr className={styles.topSeparator} />
        </div>
    );
};

export default PageHeader;